import React from "react";
import { Box } from "@mui/material";
import RankingToggle from "./RankingToggle";
import GlobalRanking from "./GlobalRanking";
import GroupRanking from "./GroupRanking";
import LoadingSpinner from "./LoadingSpinner";

const RankingTab = ({
  loading,
  translation,
  rankingView,
  handleRankingViewChange,
  availableGroups,
  selectedRankingGroup,
  setSelectedRankingGroup,
  eventId,
  rankingsData
}) => {
  if (loading || !rankingsData) {
    return <LoadingSpinner translation={translation} />;
  }

  // If no data or participants, show loading
  if (!rankingsData.participants) {
    return <LoadingSpinner translation={translation} />;
  }

  // Group participants by group number
  const groupedParticipants = rankingsData.participants.reduce((acc, participant) => {
    const groupNumber = participant.group_number;
    if (!acc[groupNumber]) {
      acc[groupNumber] = [];
    }
    acc[groupNumber].push(participant);
    return acc;
  }, {});

  // Sort participants within each group by final_event_points
  Object.keys(groupedParticipants).forEach(groupNumber => {
    groupedParticipants[groupNumber].sort((a, b) => a.final_event_points - b.final_event_points);
  });

  // For global ranking, sort all participants by final_event_points
  const sortedParticipants = [...rankingsData.participants].sort(
    (a, b) => a.final_event_points - b.final_event_points
  );

  return (
    <Box>
      <RankingToggle
        translation={translation}
        rankingView={rankingView}
        handleRankingViewChange={handleRankingViewChange}
        availableGroups={availableGroups}
        selectedRankingGroup={selectedRankingGroup}
        setSelectedRankingGroup={setSelectedRankingGroup}
      />

      {rankingView === "global" ? (
        <GlobalRanking
          translation={translation}
          globalRanking={sortedParticipants}
        />
      ) : (
        <GroupRanking
          translation={translation}
          groupRanking={groupedParticipants}
          selectedRankingGroup={selectedRankingGroup}
          setSelectedRankingGroup={setSelectedRankingGroup}
          showGroupSelector={false}
        />
      )}
    </Box>
  );
};

export default RankingTab;
