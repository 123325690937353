const DropdownEventTypes = ({ name, label, options, selectedOption, onSelect }) => {
  return (
    <div className="form-group">
      <label htmlFor={name}>{label}</label>
      <select className="form-control" id={name} name={name} value={selectedOption} onChange={onSelect}>
        <option value="">Select an event type</option>
        {Array.isArray(options) &&
          options.length > 0 &&
          options.map((type) => (
            <option key={type.event_type} value={type.event_type}>
              {type.event_type}
            </option>
          ))}
      </select>
    </div>
  )
}

export default DropdownEventTypes

