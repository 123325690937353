import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { postEvent } from "../actions/eventsState"
import {
  load_validators,
  load_scoring_types,
  load_countries,
  load_cities_by_country,
  load_fishing_spots_by_city,
} from "../actions/utilsState"
import Input from "./../components/Input"
import Textarea from "../components/Textarea"
import DateTimePicker from "../components/DateTimePicker"
import { eventForm } from "../constants/forms"
import MultiDropdown from "../components/MultiDropdown"
import { useNavigate } from "react-router-dom"
import DropdownFishValidators from "../components/DropdownFishValidators"
import { toast } from "react-toastify"
import DropdownFishingSpots from "../components/DropdownFishingSpots"
import DropdownCity from "../components/DropdownCity"
import DropdownCountry from "../components/DropdownCountry"
import DropdownEventTypes from "../components/DropdownEventTypes";
import Checkbox from "../components/Checkbox"
import DropdownScoringTypes from "../components/DropdownScoringTypes"
import { Container, Paper, Typography, Stepper, Step, StepLabel, Grid, Button, CircularProgress } from "@mui/material"
import { styled } from "@mui/material/styles"

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginTop: theme.spacing(3),
}))

const StyledForm = styled("form")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(3),
}))

const initialStateForm = {
  event_name: "",
  event_logo: "",
  details: "",
  event_location: "",
  event_location_details: "",
  event_participation_type: "I",
  event_type: "",
  start_date: new Date(),
  event_duration_hours: "",
  event_location_country: "",
  event_location_city: "",
  participation_tax: "10.00",
  participation_tax_currency: 1,
  principal_sponsors: [],
  other_sponsors: [],
  validator: "",
  scoring_type: "",
  is_national_event: false,
  allow_gallery_photos: false,
  is_team_event: false,
  has_bonus_points: false,
}

const CreateEventStepOne = ({
  step,
  translation,
  postEvent,
  sponsors,
  validators,
  createLoading,
  load_validators,
  load_scoring_types,
  load_countries,
  load_cities_by_country,
  load_fishing_spots_by_city,
  fishingSpots = {},
  countries = [],
  cities = {},
  scoringTypes,
  eventTypes = []
}) => {
  const navigate = useNavigate()
  const [dirtyForm, setDirtyForm] = useState(false)
  const [stepOneData, setstepOneData] = useState(initialStateForm)
  const [isLoadingCities, setIsLoadingCities] = useState(false)
  const [isLoadingFishingSpots, setIsLoadingFishingSpots] = useState(false)
  const [isInitialized, setIsInitialized] = useState(false)

  // Load initial data on mount
  useEffect(() => {
    const initializeData = async () => {
      if (!isInitialized) {
        await Promise.all([load_validators(), load_scoring_types(), load_countries()])
        setIsInitialized(true)
      }
    }
    initializeData()
  }, [load_validators, load_scoring_types, load_countries, isInitialized])


  useEffect(() => {
    if (step === 2) {
      navigate("/create-fishscoring")
    }
  }, [step, navigate])

  const handleEventTypeChange = (e) => {
  const eventType = e.target.value
  setstepOneData((prev) => ({
    ...prev,
    event_type: eventType,
    scoring_type: "",
  }))
  setDirtyForm(true)
}

  // Handle country change and load corresponding cities
  const handleCountryChange = async (e) => {
    const countryId = e.target.value
    setstepOneData((prev) => ({
      ...prev,
      event_location_country: countryId,
      event_location_city: "",
      event_location: "",
    }))
    setDirtyForm(true)

    if (countryId) {
      setIsLoadingCities(true)
      try {
        await load_cities_by_country(countryId)
      } catch (error) {
        console.error("Failed to load cities:", error)
        toast.error(translation?.failed_to_load_cities || "Failed to load cities")
      } finally {
        setIsLoadingCities(false)
      }
    }
  }

  const handleCityChange = async (e) => {
    const cityId = e.target.value
    setstepOneData((prev) => ({
      ...prev,
      event_location_city: cityId,
      event_location: "", // Reset fishing spot when city changes
    }))
    setDirtyForm(true)

    if (cityId) {
      setIsLoadingFishingSpots(true)
      try {
        await load_fishing_spots_by_city(cityId)
      } catch (error) {
        console.error("Failed to load fishing spots:", error)
        toast.error(translation?.failed_to_load_fishing_spots || "Failed to load fishing spots")
      } finally {
        setIsLoadingFishingSpots(false)
      }
    }
  }

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target
    setstepOneData({
      ...stepOneData,
      [name]: type === "checkbox" ? checked : value,
    })
    setDirtyForm(true)
  }

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target
    setstepOneData({
      ...stepOneData,
      [name]: checked,
    })
    setDirtyForm(true)
  }

  const handleSponsorChange = (e) => {
    const { name, value } = e.target
    let __sponsor = [...stepOneData[name]]

    if (__sponsor.includes(value)) {
      __sponsor = __sponsor.filter((item) => item !== value)
      setstepOneData({
        ...stepOneData,
        [name]: __sponsor,
      })
    } else {
      __sponsor.push(value)
      setstepOneData({
        ...stepOneData,
        [name]: __sponsor,
      })
    }
    setDirtyForm(true)
  }

  const handleDateChange = (date) => {
    setstepOneData({
      ...stepOneData,
      start_date: new Date(date),
    })
    setDirtyForm(true)
  }

  const handleBannerUpload = (event) => {
    setstepOneData({
      ...stepOneData,
      event_logo: event.target.files[0],
    })
    setDirtyForm(true)
  }

  const handleValidatorChange = (event) => {
    setstepOneData({
      ...stepOneData,
      validator: event.target.value,
    })
    setDirtyForm(true)
  }

  const nextStep = (e) => {
    e.preventDefault()
    if (validateMandatory(stepOneData)) {
      const formData = new FormData()
      const formDataKeys = Object.keys(stepOneData)

      for (let i = 0; i < formDataKeys.length; i++) {
        formData.append(formDataKeys[i], stepOneData[formDataKeys[i]])
      }
      formData.append("start_date", new Date(stepOneData.start_date).toISOString())
      console.log("Form data being sent:", Object.fromEntries(formData))

      postEvent(formData)
    } else {
      toast.error("Please complete all mandatory fields")
    }
  }

  const validateMandatory = (data) => {
    let validData = true
    const arrayObjectKeys = Object.keys(initialStateForm)

    for (let k = 0; k < arrayObjectKeys.length; k++) {
      if (
        !data[arrayObjectKeys[k]] &&
        arrayObjectKeys[k] !== "tournament" &&
        arrayObjectKeys[k] !== "is_national_event" &&
        arrayObjectKeys[k] !== "allow_gallery_photos" &&
        arrayObjectKeys[k] !== "is_team_event" &&
        arrayObjectKeys[k] !== "has_bonus_points"
      ) {
        validData = false
      }
    }

    return validData
  }

  const renderForm = () => {
    const formOrder = [
      "event_type",
      "scoring_type",
      "is_national_event",
      "allow_gallery_photos",
      "is_team_event",
      "has_bonus_points",
      "event_name",
      "event_logo",
      "details",
      "event_location_country",
      "event_location_city",
      "event_location",
      "event_location_details",
      "start_date",
      "event_duration_hours",
      "participation_tax",
      "principal_sponsors",
      "other_sponsors",
      "validator",
    ]
    return formOrder.map((key, index) => {
      const field = eventForm[key]
      switch (field.type) {
        case "input":
          return (
            <Grid item xs={12} sm={6} key={key + index}>
              <Input
                type="text"
                className="form-control"
                name={field.label}
                label={translation[field.label]}
                placeholder={translation[field.placeholder]}
                value={stepOneData[key] || ""}
                onChange={handleInputChange}
                formAttr={key}
              />
            </Grid>
          )
        case "number":
          return (
            <Grid item xs={12} sm={6} key={key + index}>
              <Input
                type="number"
                className="form-control"
                name={field.label}
                label={translation[field.label]}
                placeholder={translation[field.placeholder]}
                value={stepOneData[key] || ""}
                onChange={handleInputChange}
                formAttr={key}
              />
            </Grid>
          )
        case "textarea":
          return (
            <Grid item xs={12} key={key + index}>
              <Textarea
                type="text"
                className="form-control"
                name={field.label}
                label={translation[field.label]}
                value={stepOneData[key] || ""}
                onChange={handleInputChange}
                formAttr={key}
              />
            </Grid>
          )
        case "datetime":
          return (
            <Grid item xs={12} sm={6} key={key + index}>
              <DateTimePicker
                type="text"
                className="form-control"
                name={field.label}
                label={translation[field.label]}
                value={stepOneData[key] || ""}
                onChange={handleDateChange}
                formAttr={key}
              />
            </Grid>
          )
        case "dropdown_sponsor":
          return (
            <Grid item xs={12} sm={6} key={key + index}>
              <MultiDropdown
                type="text"
                className="form-control"
                name={field.label}
                label={translation[field.label]}
                options={sponsors}
                selectedOptions={stepOneData[key]}
                onSelect={handleSponsorChange}
                formAttr={key}
              />
            </Grid>
          )
        case "upload_banner":
          return (
            <Grid item xs={12} key={key + index}>
              <Input
                type="file"
                className="form-control"
                name={field.label}
                label={translation[field.label]}
                onChange={handleBannerUpload}
                formAttr={key}
              />
            </Grid>
          )
        case "dropdown_validator":
          return (
            <Grid item xs={12} sm={6} key={key + index}>
              <DropdownFishValidators
                type="text"
                className="form-control"
                name={field.label}
                label={translation[field.label]}
                options={validators || []}
                selectedOptions={stepOneData[key]}
                onSelect={handleValidatorChange}
                formAttr={key}
              />
            </Grid>
          )

        case "dropdown_country":
          return (
            <Grid item xs={12} sm={6} key={key + index}>
              <DropdownCountry
                label={translation[field.label]}
                options={countries}
                selectedOptions={stepOneData.event_location_country}
                onSelect={handleCountryChange}
                formAttr="event_location_country"
              />
            </Grid>
          )

        case "dropdown_city":
          return (
            <Grid item xs={12} sm={6} key={key + index}>
              <DropdownCity
                label={translation[field.label]}
                options={cities[stepOneData.event_location_country] || []}
                selectedOptions={stepOneData.event_location_city}
                onSelect={handleCityChange}
                formAttr="event_location_city"
                disabled={!stepOneData.event_location_country || isLoadingCities}
              />
            </Grid>
          )

        case "dropdown_fishingspots":
          return (
            <Grid item xs={12} sm={6} key={key + index}>
              <DropdownFishingSpots
                label={translation[field.label]}
                options={fishingSpots[stepOneData.event_location_city] || []}
                selectedOptions={stepOneData.event_location}
                onSelect={handleInputChange}
                formAttr="event_location"
                disabled={!stepOneData.event_location_city || isLoadingFishingSpots}
              />
            </Grid>
          )

        case "dropdown_event_type":
          return (
              <Grid item xs={12} sm={6} key={key + index}>
                <DropdownEventTypes
                  name={key}
                  label={translation[field.label]}
                  options={eventTypes}
                  selectedOption={stepOneData[key] || ""}
                  onSelect={handleEventTypeChange}
                />
              </Grid>
                )
        case "dropdown_scoring_type":
            const selectedEventType = eventTypes.find((et) => et.event_type === stepOneData.event_type)
            const availableScoringTypes = selectedEventType ? selectedEventType.scoring_types : []
          return (
              <Grid item xs={12} sm={6} key={key + index}>
                <DropdownScoringTypes
                  name={field.label}
                  label={translation[field.label]}
                  options={availableScoringTypes}
                  selectedOption={stepOneData[key] || ""}
                  onSelect={handleInputChange}
                  disabled={!stepOneData.event_type}
                />
            </Grid>
              )
        case "checkbox":
          return (
            <Grid item xs={12} key={key + index}>
              <Checkbox
                name={field.label}
                label={translation[field.label]}
                checked={stepOneData[key] || false}
                onChange={handleCheckboxChange}
                className="form-check-input"
              />
            </Grid>
          )
        default:
          return null
      }
    })
  }

  return (
    <Container maxWidth="md">
      <StyledPaper elevation={3}>
        <Typography variant="h4" gutterBottom>
          {translation.create_event}
        </Typography>
        <Stepper activeStep={0} alternativeLabel sx={{ marginBottom: 3 }}>
          <Step>
            <StepLabel>{translation.general}</StepLabel>
          </Step>
          <Step>
            <StepLabel>{translation.scoring}</StepLabel>
          </Step>
          <Step>
            <StepLabel>{translation.prizes}</StepLabel>
          </Step>
        </Stepper>
        <StyledForm onSubmit={nextStep}>
          <Grid container spacing={3}>
            {renderForm()}
          </Grid>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={createLoading}
            sx={{ alignSelf: "flex-end", marginTop: 2 }}
          >
            {createLoading ? <CircularProgress size={24} /> : translation.next_step}
          </Button>
        </StyledForm>
      </StyledPaper>
    </Container>
  )
}

const mapStateToProps = (state) => ({
  step: state.eventsState.step,
  translation: state.appState.translation,
  sponsors: state.utilsState.sponsors || [],
  validators: state.utilsState.validators || [],
  fishingSpots: state.utilsState.fishingSpots || {},
  countries: state.utilsState.countries || [],
  cities: state.utilsState.cities || {},
  fish_types: state.utilsState.fish_types || [],
  submitedEvent: state.eventsState.submitedEvent,
  createLoading: state.eventsState.createLoading,
  scoringTypes: state.utilsState.scoringTypes || [],
  eventTypes: state.utilsState.eventTypes || [],
})

const mapDispatchToProps = {
  postEvent,
  load_validators,
  load_scoring_types,
  load_countries,
  load_cities_by_country,
  load_fishing_spots_by_city,
}

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(CreateEventStepOne))

