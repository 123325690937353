import React, { useCallback, useState } from "react"
import {connect, useSelector} from "react-redux"
import getAxiosConfig from "../utils/axiosConfig"
import axios from "axios"
import GeneralEditForm from "./GeneralEditForm"
import FishScoringEditForm from "./FishScoringEditForm"
import { toast } from "react-toastify"
import { useNavigate } from "react-router-dom"
import { Spinner } from "react-bootstrap"
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap"
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from "@mui/material"
import EditPrizesForm from "./PrizesEditForm";

const enrollButton = {
  pending: {
    className: "btn-light-blue",
    disabled: true,
  },
  approved: {
    className: "btn-light-green",
    disabled: true,
  },
  rejected: {
    className: "btn-light-red",
    disabled: true,
  },
  "not enrolled": {
    className: "btn-light-blue",
    disabled: false,
  },
}

const EventActions = ({
  selectedTab,
  translation,
  eventId,
  is_active,
  refreshEvent,
  is_owner,
  enrollment_status,
  is_ongoing,
  is_validator,
  event,
  handleGenerateLineup,
  isGeneratingLineup,
  handleGenerateMatches,
  isGeneratingMatches,
  lineupExists,
  matchExists,
  handleDownloadLineup,
  handleDownloadTAScoreboard,
  handleDownloadTAStatistics, handleEventSoftDelete,
      //  TSF-related
  handleGenerateTSFGeneralLineup,
  isGeneratingTSFGeneralLineup,
  handleDownloadTSFLineup,
  handleExportTSFRanking,
  handleDownloadTSFSummary,
  lineupMatchExists,
  navigateToEventOngoing,
}) => {
  const navigate = useNavigate()
  const [open, setOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingStart, setIsLoadingStart] = useState(false)
  const [isLoadingPhotos, setIsLoadingPhotos] = useState(false)
  const [isLoadingScoreboard, setIsLoadingScoreboard] = useState(false)
  const [isLoadingSummary, setIsLoadingSummary] = useState(false)
  const [isLoadingTAStatistics, setIsLoadingTAStatistics] = useState(false)
  const [isLoadingTAScoreboard, setIsLoadingTAScoreboard] = useState(false)
  const [title] = useState([translation.edit_event, translation.edit_fishscoring, translation.edit_prizes])
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
  const [confirmAction, setConfirmAction] = useState(null)
  const [isLoadingSoftDelete, setIsLoadingSoftDelete] = useState(false)
  const [tsfReadiness, setTsfReadiness] = useState({ lineup_ready: false, positions_ready: false })

    // Get loading states from Redux
  const tsfState = useSelector((state) => state.tsf || {})
  const isExportingTSFLineup = tsfState.exportLineupLoading
  const isExportingTSFRanking = tsfState.exportRankingLoading
  const isDownloadingTSFSummary = tsfState.downloadSummaryLoading

    // Check if the event is of Type_3
  const isType3Event = event?.scoring_type === "Type_3"
  const isType2Event = event?.scoring_type === "Type_2"
  console.log("Is type 3:", isType3Event, "Is type 2:", isType2Event)
  const handleSoftDelete = useCallback(async () => {
    setConfirmAction("softDelete")
    setConfirmDialogOpen(true)
  }, [])

  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState)

  const publishEvent = useCallback(async () => {
    const config = getAxiosConfig()

    try {
      setIsLoading(true)
      await axios.post(`${process.env.REACT_APP_API_URL}/v2/event-publish/${eventId}/active/`, {}, config)
      toast.success("Event published")
      refreshEvent()
      setIsLoading(false)
    } catch (err) {
      const errMessage = err.response.data.detail
      toast.error(errMessage)
      setIsLoading(false)
    }
  }, [eventId, refreshEvent])

  const handleEnrollClick = useCallback(async () => {
    try {
      setIsLoading(true)
      await axios.post(
        `${process.env.REACT_APP_API_URL}/v2/event/enrolments/enroll/`,
        { event: eventId },
        getAxiosConfig(),
      )
      toast.success(`Event enrolled`)
      refreshEvent()
      setIsLoading(false)
    } catch (err) {
      const errMessage = err.response.data.message
      toast.error(`${errMessage}`)
      setIsLoading(false)
    }
  }, [eventId, refreshEvent])

  const triggerStartStopEvent = useCallback(() => {
    setConfirmAction(is_ongoing ? "stop" : "start")
    setConfirmDialogOpen(true)
  }, [is_ongoing])

  const handleConfirmedStartStop = useCallback(async () => {
    const trigger = confirmAction

    try {
      if (trigger === "softDelete") {
        setIsLoadingSoftDelete(true)
        await handleEventSoftDelete()
        toast.success(translation.event_soft_deleted_success)
      } else {
        setIsLoadingStart(true)
        await axios.post(
          `${process.env.REACT_APP_API_URL}/v2/event-status/${eventId}/${trigger}/`,
          {},
          getAxiosConfig(),
        )
        toast.success(trigger === "stop" ? `Event stopped` : `Event started`)
        refreshEvent()
      }
    } catch (err) {
      const errMessage = err.response?.data?.detail || translation.action_failed
      toast.error(`${errMessage}`)
    } finally {
      setIsLoadingStart(false)
      setIsLoadingSoftDelete(false)
      setConfirmDialogOpen(false)
    }
  }, [confirmAction, eventId, refreshEvent, handleEventSoftDelete, translation])

  const handleDownloadOrganizerPhotos = useCallback(async () => {
    try {
      setIsLoadingPhotos(true)
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/v2/download-organizer-event-photos/${eventId}/`,
        {
          ...getAxiosConfig(),
          responseType: "blob",
        },
      )
      const blob = new Blob([response.data], { type: response.headers["content-type"] })
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement("a")
      link.href = url
      link.download = `event_${eventId}_photos.zip`
      document.body.appendChild(link)
      link.click()
      link.remove()
      window.URL.revokeObjectURL(url)

      toast.success(translation.photos_downloaded)
    } catch (error) {
      console.error("Error downloading photos:", error)
      toast.error(translation.download_failed)
    } finally {
      setIsLoadingPhotos(false)
    }
  }, [eventId, translation])

  const handleExportScoreboard = useCallback(async () => {
    try {
      setIsLoadingScoreboard(true)
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/v2/export-event-scoreboard/${eventId}/`, {
        ...getAxiosConfig(),
        responseType: "blob",
      })
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement("a")
      link.href = url
      link.setAttribute("download", `event_${eventId}_scoreboard.csv`)
      document.body.appendChild(link)
      link.click()
      link.remove()
      toast.success(translation.scoreboard_exported)
    } catch (error) {
      console.error("Error exporting scoreboard:", error)
      toast.error(translation.export_failed)
    } finally {
      setIsLoadingScoreboard(false)
    }
  }, [eventId, translation])

  const handleExportSummaryCSV = useCallback(async () => {
    try {
      setIsLoadingSummary(true)
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/v3/event/${eventId}/summary/csv/`, {
        ...getAxiosConfig(),
        responseType: "blob",
      })
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement("a")
      link.href = url
      link.setAttribute("download", `event_${eventId}_summary.csv`)
      document.body.appendChild(link)
      link.click()
      link.remove()
      toast.success(translation.summary_exported)
    } catch (error) {
      console.error("Error exporting summary CSV:", error)
      toast.error(translation.export_failed)
    } finally {
      setIsLoadingSummary(false)
    }
  }, [eventId, translation])

  const renderTabAction = useCallback(() => {
    if (event.is_ended) return null
    switch (selectedTab) {
      case 0:
        return <DropdownItem onClick={() => setOpen(true)}>{translation.edit_event}</DropdownItem>
      case 1:
        return <DropdownItem onClick={() => setOpen(true)}>{translation.edit_fishscoring}</DropdownItem>
      case 2:
        return <DropdownItem onClick={() => setOpen(true)}>{translation.edit_prizes}</DropdownItem>
      default:
        return null
    }
  }, [event, selectedTab, translation])

    const handleEventOngoingNavigation = useCallback(() => {
    if (navigateToEventOngoing) {
      navigateToEventOngoing()
    } else {
      // Fallback navigation logic
      if (isType3Event) {
        navigate(`/tsfeventongoing/${eventId}`)
      } else if (isType2Event) {
        navigate(`/taeventongoing/${eventId}`)
      } else {
        navigate(`/eventongoing/${eventId}`)
      }
    }
  }, [navigate, isType3Event, isType2Event, eventId, navigateToEventOngoing])

    const renderOwnerValidatorActions = () => {
      if (!is_owner && !is_validator) return null

      const isEventStopped = !is_ongoing && event.is_ended
      const isType2Event = event.scoring_type === "Type_2"
      const isType3Event = event.scoring_type === "Type_3"
      const hasActions =
        is_owner || is_validator || !event?.is_ended || isType2Event || isType3Event || (is_owner && isEventStopped)

      return (
      <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown} className="d-inline-block">
        <DropdownToggle caret color="galben text-white" className="btn green text-white submit-button me-2 mb-2">
          {translation.actions}
        </DropdownToggle>
        <DropdownMenu>
          {hasActions ? (
            <>
              {is_owner && (
                <DropdownItem onClick={publishEvent} disabled={isLoadingStart || is_active}>
                  {is_active ? translation.published : translation.publish}
                </DropdownItem>
              )}
              {!event?.is_ended && (is_owner || is_validator) && (
                <DropdownItem onClick={triggerStartStopEvent} disabled={isLoadingStart}>
                  {is_ongoing ? translation.stop_event : translation.start_event}
                </DropdownItem>
              )}
              {is_owner && !event.is_ongoing && (
                <DropdownItem onClick={handleSoftDelete} disabled={isLoadingSoftDelete}>
                  {isLoadingSoftDelete ? (
                    <>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        className="me-2"
                      />
                      {translation.soft_deleting_event}
                    </>
                  ) : (
                    translation.soft_delete_event
                  )}
                </DropdownItem>
              )}
              {isType2Event && (is_owner || is_validator) && (
                <>
                  <DropdownItem
                    onClick={handleGenerateLineup}
                    disabled={isGeneratingLineup || event.is_ongoing || event.is_ended}
                  >
                    {isGeneratingLineup ? (
                      <>
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          className="me-2"
                        />
                        {translation.generating_lineup}
                      </>
                    ) : (
                      <>
                        {translation.generate_lineup}
                        {lineupExists && <span className="ms-1 badge bg-success">{translation.exists}</span>}
                      </>
                    )}
                  </DropdownItem>
                  <DropdownItem
                    onClick={handleGenerateMatches}
                    disabled={isGeneratingMatches || !lineupExists || event.is_ongoing || event.is_ended}
                  >
                    {isGeneratingMatches ? (
                      <>
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          className="me-2"
                        />
                        {translation.generating_matches}
                      </>
                    ) : (
                      <>
                        {translation.generate_matches}
                        {matchExists && <span className="ms-1 badge bg-success">{translation.exists}</span>}
                      </>
                    )}
                  </DropdownItem>
                  {lineupExists && (
                    <DropdownItem onClick={handleDownloadLineup}>{translation.download_lineup}</DropdownItem>
                  )}
                </>
              )}
              {/* Add TSF-specific actions */}
              {isType3Event && (is_owner || is_validator) && (
                <>
                  <DropdownItem
                    onClick={handleGenerateTSFGeneralLineup}
                    disabled={isGeneratingTSFGeneralLineup || event?.is_ongoing || event?.is_ended}
                  >
                    {isGeneratingTSFGeneralLineup ? (
                      <>
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          className="me-2"
                        />
                        {translation.generating_tsf_general_lineup || "Generating TSF General Lineup..."}
                      </>
                    ) : (
                      <>
                        {translation.generate_tsf_general_lineup || "Generate TSF General Lineup"}
                        {lineupMatchExists?.tsf_general_lineup && (
                          <span className="ms-1 badge bg-success">{translation.exists || "Exists"}</span>
                        )}
                      </>
                    )}
                  </DropdownItem>
                  {lineupMatchExists?.tsf_general_lineup && (
                    <DropdownItem onClick={handleDownloadTSFLineup}>
                      {translation.export_tsf_lineup || "Export TSF Lineup"}
                    </DropdownItem>
                  )}
                </>
              )}
              {is_owner && isEventStopped && (
                <>
                  <DropdownItem divider />
                  {isType2Event ? (
                    <>
                      <DropdownItem onClick={handleDownloadTAStatistics} disabled={isLoadingTAStatistics}>
                        {isLoadingTAStatistics ? (
                          <>
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                              className="me-2"
                            />
                            {translation.downloading_ta_statistics}
                          </>
                        ) : (
                          translation.download_ta_statistics
                        )}
                      </DropdownItem>
                      <DropdownItem onClick={handleDownloadTAScoreboard} disabled={isLoadingTAScoreboard}>
                        {isLoadingTAScoreboard ? (
                          <>
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                              className="me-2"
                            />
                            {translation.downloading_ta_scoreboard}
                          </>
                        ) : (
                          translation.download_ta_scoreboard
                        )}
                      </DropdownItem>
                    </>
                  ) : isType3Event ? (
                    <>
                      <DropdownItem onClick={handleExportTSFRanking}>
                        {translation.export_tsf_ranking || "Export TSF Ranking"}
                      </DropdownItem>
                      <DropdownItem onClick={handleDownloadTSFSummary}>
                        {translation.download_tsf_summary || "Download TSF Summary"}
                      </DropdownItem>
                    </>
                  ) : (
                    <>
                      <DropdownItem onClick={handleDownloadOrganizerPhotos} disabled={isLoadingPhotos}>
                        {isLoadingPhotos ? (
                          <>
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                              className="me-2"
                            />
                            {translation.downloading_photos}
                          </>
                        ) : (
                          translation.download_organizer_photos
                        )}
                      </DropdownItem>
                      <DropdownItem onClick={handleExportScoreboard} disabled={isLoadingScoreboard}>
                        {isLoadingScoreboard ? (
                          <>
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                              className="me-2"
                            />
                            {translation.exporting_scoreboard}
                          </>
                        ) : (
                          translation.export_scoreboard
                        )}
                      </DropdownItem>
                      <DropdownItem onClick={handleExportSummaryCSV} disabled={isLoadingSummary}>
                        {isLoadingSummary ? (
                          <>
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                              className="me-2"
                            />
                            {translation.exporting_summary}
                          </>
                        ) : (
                          translation.export_summary_csv
                        )}
                      </DropdownItem>
                    </>
                  )}
                </>
              )}
              {is_owner && renderTabAction()}
            </>
          ) : (
            <DropdownItem disabled>{translation.no_actions}</DropdownItem>
          )}
        </DropdownMenu>
      </Dropdown>
    )
  }


  return (
    <div className="d-flex flex-wrap align-items-center">
      {enrollment_status && !is_validator && !event?.is_ended && (
        <button
          className={`btn ${enrollButton[enrollment_status].className} me-2 mb-2`}
          onClick={handleEnrollClick}
          disabled={enrollButton[enrollment_status].disabled}
        >
          {translation[enrollment_status]}
        </button>
      )}
      {is_ongoing && enrollment_status === "approved" && (
        <button
          type="submit"
          className="btn green text-white submit-button me-2 mb-2"
          onClick={() => {
            if (navigateToEventOngoing) {
              navigateToEventOngoing()
            } else {
              if (event.scoring_type === "Type_3") {
                navigate(`/tsfeventongoing/${event.id}`)
              } else if (event.scoring_type === "Type_2") {
                navigate(`/taeventongoing/${event.id}`)
              } else {
                navigate(`/eventongoing/${event.id}`)
              }
            }
          }}
        >
          {translation.dash_go_to_upload}
        </button>
      )}
      {renderOwnerValidatorActions()}
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>{title[selectedTab]}</DialogTitle>
        <DialogContent>
          {selectedTab === 0 && open ? (
            <GeneralEditForm onClose={() => setOpen(false)} refreshEvent={refreshEvent} />
          ) : selectedTab === 1 && open ? (
            <FishScoringEditForm onClose={() => setOpen(false)} refreshEvent={refreshEvent} />
          ) : selectedTab === 2 && open ? (
            <EditPrizesForm onClose={() => setOpen(false)} refreshEvent={refreshEvent} />
          ) : null}
        </DialogContent>
      </Dialog>
      <Dialog
        open={confirmDialogOpen}
        onClose={() => setConfirmDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">
          {confirmAction === "softDelete"
            ? translation.confirm_delete_event
            : translation[`confirm_${confirmAction}_event`]}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {confirmAction === "softDelete"
              ? translation.confirm_delete_event_message
              : translation[`confirm_${confirmAction}_event_message`]}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmDialogOpen(false)} color="primary">
            {translation.cancel}
          </Button>
          <Button onClick={handleConfirmedStartStop} color="primary" variant="contained" autoFocus>
            {translation.confirm}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

const mapStateToProps = (state) => ({
  translation: state.appState.translation,
  event: state.eventState.event,
  user: state.auth.user,
})

export default connect(mapStateToProps, null)(React.memo(EventActions))

