import { Box, Card, CardHeader, CardContent, Typography, Divider, Chip } from "@mui/material";
import { User } from "lucide-react";

const UserAssignment = ({ userAssignment, currentLeg, translation }) => {
  if (!userAssignment) {
    return (
      <Card sx={{ mb: 2 }}>
        <CardContent>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
            <User size={20} style={{ marginRight: 8 }} />
            <Typography variant="h6">
              {translation.no_assignment || "No Assignment"}
            </Typography>
          </Box>
          <Typography variant="body2" color="text.secondary">
            {translation.no_assignment_found || "No assignment found for your user account."}
          </Typography>
        </CardContent>
      </Card>
    );
  }

  const currentLegData = userAssignment.legs.find((leg) => leg.leg_number === currentLeg);
  if (!currentLegData) return null;

  return (
    <Card sx={{ mb: 2 }}>
      <CardHeader
        title={
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <User size={20} style={{ marginRight: 8 }} />
            {translation.your_assignment || "Your Assignment"}
          </Box>
        }
      />
      <CardContent>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Box>
            <Typography variant="subtitle2" color="text.secondary" sx={{ mb: 0.5 }}>
              {translation.user_info || "User Info"}
            </Typography>
            <Typography variant="body2">
              <strong>{translation.name || "Name"}:</strong> {userAssignment.name}
            </Typography>
            <Typography variant="body2">
              <strong>{translation.draw_number || "Draw Number"}:</strong> {userAssignment.draw_number}
            </Typography>
            <Typography variant="body2">
              <strong>{translation.total_catches || "Total Fish"}:</strong> {userAssignment.total_fish}
            </Typography>
            <Typography variant="body2">
              <strong>{translation.total_points || "Total Points"}:</strong> {userAssignment.total_points}
            </Typography>
            <Typography variant="body2">
              <strong>{translation.final_group_points || "Final Group Points"}:</strong> {userAssignment.final_group_points}
            </Typography>
          </Box>

          <Divider />

          <Box>
            <Typography variant="subtitle2" color="text.secondary" sx={{ mb: 0.5 }}>
              {translation.current_leg || "Current Leg"} {currentLeg}
            </Typography>
            <Typography variant="body2">
              <strong>{translation.position || "Position"}:</strong> {currentLegData.position_value}
            </Typography>
            {/*<Typography variant="body2">*/}
            {/*  <strong>{translation.seat_index || "Seat"}:</strong> {currentLegData.seat_index}*/}
            {/*</Typography>*/}
            <Typography variant="body2">
              <strong>{translation.fish_caught || "Fish Caught"}:</strong> {currentLegData.fish_caught}
            </Typography>
            <Typography variant="body2">
              <strong>{translation.leg_points || "Leg Points"}:</strong> {currentLegData.leg_points}
            </Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default UserAssignment;
