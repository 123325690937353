import { useState } from "react"
import { useDispatch } from "react-redux"
import FishLoader from "./FishLoader"
import { toast } from "react-toastify"
import getAxiosConfig from "../utils/axiosConfig"
import axios from "axios"
import { Box, Button, IconButton, Modal, styled } from "@mui/material"
import { Close, CloudUpload, CameraAlt } from "@mui/icons-material"

const Input = styled("input")({
  display: "none",
})

const EditEventBanner = ({ eventId, onUploadDone }) => {
  const [selectedFile, setSelectedFile] = useState(null)
  const [previewURL, setPreviewURL] = useState(null)
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)

  const handleImageChange = (e) => {
    const file = e.target.files[0]
    const isImage = file && file["type"].split("/")[0] === "image"
    if (!isImage) {
      console.error(`File is not an image. ${file.type}`)
      return
    }
    setSelectedFile(file)
    setPreviewURL(URL.createObjectURL(file))
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (selectedFile) {
      setIsLoading(true)
      const config = getAxiosConfig()
      config.headers["Content-Type"] = "multipart/form-data"

      const formData = new FormData()
      formData.append("event_logo", selectedFile)

      try {
        const res = await axios.patch(
          `${process.env.REACT_APP_API_URL}/v2/events/${eventId}/update-logo/`,
          formData,
          config,
        )
        toast.success("Photo updated, please refresh for changes.")
        setIsLoading(false)
        onUploadDone()
      } catch (err) {
        toast.error("Error while uploading photo")
        setIsLoading(false)
        onUploadDone()
      }
    }
  }

  return (
    <Modal
      open={true}
      onClose={onUploadDone}
      aria-labelledby="edit-event-banner-modal"
      aria-describedby="modal-to-edit-event-banner"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 300,
          maxHeight: "80%",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          outline: "none",
        }}
      >
        <IconButton
          aria-label="close"
          onClick={onUploadDone}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>

        {previewURL ? (
          <Box sx={{ textAlign: "center", mt: 2 }}>
            <Box sx={{ position: "relative", display: "inline-block" }}>
              <Box
                component="img"
                src={previewURL}
                alt="Event banner preview"
                sx={{
                  width: "100%",
                  maxHeight: 200,
                  objectFit: "cover",
                  borderRadius: 1,
                  filter: isLoading ? "blur(8px)" : "none",
                }}
              />
              {isLoading && (
                <Box
                  sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <FishLoader />
                </Box>
              )}
            </Box>
            <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={isLoading}
                startIcon={<CloudUpload />}
              >
                Save Photo
              </Button>
            </Box>
          </Box>
        ) : (
          <Box sx={{ textAlign: "center", mt: 2 }}>
            <Input accept="image/*" id="contained-button-file" type="file" onChange={handleImageChange} />
            <label htmlFor="contained-button-file">
              <Button variant="contained" color="primary" component="span" startIcon={<CameraAlt />}>
                Choose Photo
              </Button>
            </label>
          </Box>
        )}
      </Box>
    </Modal>
  )
}

export default EditEventBanner

