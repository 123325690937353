import { Button, Typography, Box } from "@mui/material"
import { ArrowBack, ArrowForward } from "@mui/icons-material"

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: 2,
        my: 4,
      }}
    >
      <Button
        variant="outlined"
        startIcon={<ArrowBack />}
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
      >
        Previous
      </Button>
      <Typography variant="body1">
        Page {currentPage} of {totalPages}
      </Typography>
      <Button
        variant="outlined"
        endIcon={<ArrowForward />}
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
      >
        Next
      </Button>
    </Box>
  )
}

export default Pagination

