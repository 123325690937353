import { useDispatch } from "react-redux"
import { Card, CardContent, Typography, Button, Box } from "@mui/material"
import { MailOutline, Mail, AccessTime } from "@mui/icons-material"
import { formatDate } from "../helpers/utils"
import { acceptInvitation } from "../actions/clubActions"

const NotificationItem = ({ notification, onMarkAsRead }) => {
  const dispatch = useDispatch()

  const handleMouseEnter = () => {
    if (!notification.is_read) {
      onMarkAsRead(notification.id)
    }
  }

  const handleAcceptInvitation = () => {
    dispatch(acceptInvitation(notification.notification_value))
  }

  return (
    <Card
      onMouseEnter={handleMouseEnter}
      sx={{
        mb: 2,
        backgroundColor: notification.is_read ? "background.paper" : "action.hover",
      }}
    >
      <CardContent>
        <Box display="flex" alignItems="center" mb={1}>
          {notification.is_read ? (
            <MailOutline fontSize="small" sx={{ mr: 1 }} />
          ) : (
            <Mail fontSize="small" sx={{ mr: 1 }} />
          )}
          <Typography variant="body1">{notification.message || "No message available."}</Typography>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="body2" color="text.secondary" sx={{ display: "flex", alignItems: "center" }}>
            <AccessTime fontSize="small" sx={{ mr: 0.5 }} />
            {formatDate(notification.notify_date)}
          </Typography>
          {notification.notification_type === "NOTIFY_JOIN_CLUB_INVITE" && (
            <Button
              variant="contained"
              color="primary"
              onClick={handleAcceptInvitation}
              disabled={!notification.has_action}
            >
              Accept Invitation
            </Button>
          )}
        </Box>
      </CardContent>
    </Card>
  )
}

export default NotificationItem

