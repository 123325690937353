import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { sendClubInvitation } from "../actions/clubActions"
import Loader from "../components/Loader"
import { toast } from "react-toastify"
import { Box, Button, Container, TextField, Typography, Paper } from "@mui/material"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"

const SendClubInvitation = () => {
  const [email, setEmail] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const club = useSelector((state) => state.myClubStateReducer.club)

  useEffect(() => {
    if (!club) navigate("/")
  }, [club, navigate])

  const handleInvite = async (e) => {
    e.preventDefault()
    setIsLoading(true)

    dispatch(sendClubInvitation(club.id, email))
      .then((successMessage) => {
        toast.success(successMessage)
        navigate("/club")
      })
      .catch((errorMessage) => {
        toast.error(errorMessage)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  if (isLoading) {
    return <Loader />
  }

  return (
    <Container maxWidth="sm">
      <Paper elevation={3} sx={{ mt: 4, p: 4 }}>
        <Typography variant="h5" align="center" gutterBottom>
          Invite New Member to {club?.club_name}
        </Typography>
        <Box display="flex" justifyContent="flex-end" mb={2}>
          <Button variant="outlined" startIcon={<ArrowBackIcon />} onClick={() => navigate("/club")}>
            Back to Club
          </Button>
        </Box>
        <form onSubmit={handleInvite}>
          <TextField
            fullWidth
            id="memberEmail"
            label="Member's Email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter member's email"
            required
            margin="normal"
          />
          <Button type="submit" variant="contained" color="primary" fullWidth disabled={isLoading} sx={{ mt: 2 }}>
            Send Invitation
          </Button>
        </form>
      </Paper>
    </Container>
  )
}

export default SendClubInvitation

