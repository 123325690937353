import { axiosInstance } from "./auth"
import { toast } from "react-toastify"

import {
  RESET_PASSWORD_ADMIN_SUCCESS,
  RESET_PASSWORD_ADMIN_FAIL,
  TOGGLE_USER_STATUS_SUCCESS,
  TOGGLE_USER_STATUS_FAIL,
  SEARCH_USERS_SUCCESS,
  SEARCH_USERS_FAIL,
} from "./types"

export const searchUsers = (searchTerm) => async (dispatch) => {
  try {
    const res = await axiosInstance.get(`${process.env.REACT_APP_API_URL}/v0/admin/user-search/`, {
      params: { search: searchTerm },
    })
    dispatch({
      type: SEARCH_USERS_SUCCESS,
      payload: res.data,
    })
  } catch (err) {
    const errorMessage = err.response?.data?.detail || "Failed to search users"
    dispatch({
      type: SEARCH_USERS_FAIL,
      payload: errorMessage,
    })
    toast.error(errorMessage)
  }
}

export const resetUserPasswordAdmin = (userId, newPassword) => async (dispatch) => {
  try {
    const res = await axiosInstance.post(`${process.env.REACT_APP_API_URL}/v0/admin/reset-password/`, {
      user_id: userId,
      new_password: newPassword,
    })
    dispatch({
      type: RESET_PASSWORD_ADMIN_SUCCESS,
      payload: res.data,
    })
    toast.success("Password reset successfully")
  } catch (err) {
    const errorMessage = err.response?.data?.detail || "Failed to reset password"
    dispatch({
      type: RESET_PASSWORD_ADMIN_FAIL,
      payload: errorMessage,
    })
    toast.error(errorMessage)
  }
}

export const toggleUserStatus = (userId, newStatus) => async (dispatch) => {
  try {
    const action = newStatus ? "activate" : "inactivate"
    const res = await axiosInstance.post(`${process.env.REACT_APP_API_URL}/v0/admin/user-status/`, { user_id: userId, action })
    dispatch({
      type: TOGGLE_USER_STATUS_SUCCESS,
      payload: { userId, newStatus },
    })
    toast.success(res.data.detail)
  } catch (err) {
    const errorMessage = err.response?.data?.detail || "Failed to update user status"
    dispatch({
      type: TOGGLE_USER_STATUS_FAIL,
      payload: errorMessage,
    })
    toast.error(errorMessage)
  }
}

