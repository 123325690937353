import { useState, useEffect, useRef } from "react"
import { Link, useNavigate } from "react-router-dom"
import { connect } from "react-redux"
import { login } from "../actions/auth"
import TermsModal from "../components/TermsModal"
import { requestUserPermission } from "../utils/firebase"
import ReCAPTCHA from "react-google-recaptcha"
import { toast } from "react-toastify"
import { Visibility, VisibilityOff } from "@mui/icons-material"
import {
  Container,
  Box,
  Typography,
  TextField,
  InputAdornment,
  IconButton,
  Button,
  Link as MuiLink,
} from "@mui/material"
import { ThemeProvider, createTheme } from "@mui/material/styles"

const theme = createTheme({
  palette: {
    primary: {
      main: "#2a9d8f", // green
    },
    secondary: {
      main: "#264653", // blue
    },
  },
})

const Login = ({ login, isAuthenticated, failedAuth, translation }) => {
  const navigate = useNavigate()
  const [showTerms, setShowTerms] = useState(false)
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [seePassword, setSeePassword] = useState(false)
  const [captchaValue, setCaptchaValue] = useState(null)
  const recaptchaRef = useRef(null)

  useEffect(() => {
    if (isAuthenticated) {
      requestUserPermission()
      navigate("/")
    }
  }, [isAuthenticated, navigate])

  useEffect(() => {
    if (failedAuth) {
      recaptchaRef.current.reset()
      setCaptchaValue(null)
    }
  }, [failedAuth])

  const handleLogin = (event) => {
    event.preventDefault()
    if (captchaValue) {
      login(email, password, captchaValue)
    } else {
      toast.warn("Please verify that you are a human!")
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="sm" sx={{ mt: 4, display: "flex", justifyContent: "center", p: 0 }}>
        <Box className="login-section" sx={{ p: 4, width: "100%" }}>
          <Box sx={{ display: "flex", justifyContent: "center", mb: 5 }}>
            <img
              style={{ maxWidth: "100%", height: "auto" }}
              src="https://streetfishing.fra1.cdn.digitaloceanspaces.com/media/assets/logo_orange_yellow_transparent.png"
              alt="logo"
            />
          </Box>

          <Typography variant="h4" align="center">
            {translation.login_title}
          </Typography>
          <Typography variant="body1" align="center" sx={{ mb: 3 }}>
            {translation.login_subtitle}
          </Typography>

          <Box component="form" onSubmit={handleLogin}>
            <TextField
              fullWidth
              className="login-input"
              type="email"
              label={translation.email}
              variant="outlined"
              autoComplete="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              sx={{ mb: 2 }}
            />
            <TextField
              fullWidth
              className="login-input"
              type={seePassword ? "text" : "password"}
              label={translation.password}
              variant="outlined"
              autoComplete="current-password"
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              inputProps={{ minLength: 6 }}
              required
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setSeePassword(!seePassword)}
                      edge="end"
                    >
                      {seePassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 1 }}>
              <MuiLink component={Link} to="/reset-password" sx={{ textDecoration: "none" }}>
                {translation.password_forgotpassword}
              </MuiLink>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}
                onChange={setCaptchaValue}
                className="recaptcha-container"
                ref={recaptchaRef}
              />
            </Box>
            <Button type="submit" fullWidth variant="contained" color="primary" sx={{ mt: 3, py: 1 }}>
              {translation.login}
            </Button>
          </Box>

          <Typography variant="body2" align="center" sx={{ mt: 3 }}>
            {translation.by_logging_in}{" "}
            <MuiLink component="span" onClick={() => setShowTerms(true)} sx={{ cursor: "pointer", color: "blue" }}>
              {translation.terms_and_conditions}
            </MuiLink>
            .
          </Typography>
          <TermsModal show={showTerms} onHide={() => setShowTerms(false)} />

          <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
            <Button
              component={Link}
              to="/signup"
              variant="contained"
              color="secondary"
              sx={{ textDecoration: "none", color: "white" }}
            >
              {translation.register_cta}
            </Button>
            <MuiLink component={Link} to="/contactus" sx={{ textDecoration: "none", alignSelf: "center" }}>
              {translation.contact_us_title}
            </MuiLink>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  )
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  failedAuth: state.auth.failedAuth,
  translation: state.appState.translation,
})

const mapDispatchToProps = (dispatch) => ({
  login: (email, password, captchaValue) => dispatch(login(email, password, captchaValue)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Login)

