import { useEffect, useState } from "react"
import { Dropdown } from "react-bootstrap"

const DropdownCountry = ({ label, options, onSelect, selectedOptions, formAttr }) => {
  const [selected, setSelected] = useState(label)

  useEffect(() => {
    if (Array.isArray(options) && options.length > 0 && selectedOptions) {
      const selectedCountry = options.find((option) => option.id.toString() === selectedOptions)
      setSelected(selectedCountry ? selectedCountry.country_name : label)
    } else {
      setSelected(label)
    }
  }, [label, options, selectedOptions])

  const handleSelect = (option) => {
    onSelect({ target: { name: formAttr, value: option } })
  }

  return (
    <>
      <label htmlFor={label} className="form-label">
        {label}:
      </label>
      <Dropdown className="d-flex mb-3" onSelect={handleSelect}>
        <Dropdown.Toggle variant="light" id="countrySelectDropdown" className="flex-grow-1">
          {selected}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {Array.isArray(options) &&
            options.map((option) => (
              <Dropdown.Item key={option.id} eventKey={option.id} active={selectedOptions === option.id.toString()}>
                {option.country_name}
              </Dropdown.Item>
            ))}
        </Dropdown.Menu>
      </Dropdown>
    </>
  )
}

export default DropdownCountry

