import React from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Grid,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Trophy, Users, Fish, Star, Hash } from "lucide-react";

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  height: "100%",
}));

const YellowTableCell = styled(TableCell)(() => ({
  backgroundColor: "#ffc107",
  color: "white",
  fontWeight: "bold",
}));

const TSFStatistics = ({ statistics, translation }) => {
  if (!statistics) {
    return (
      <Container sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "50vh" }}>
        <CircularProgress />
      </Container>
    );
  }

  const statItems = [
    {
      title: translation.best_group_points || "Best Group (Points)",
      value: `Group ${statistics.best_group_points} (${statistics.best_group_points_value} pts)`,
      icon: <Trophy />,
      color: "#ffc107"
    },
    {
      title: translation.best_group_fish || "Best Group (Fish)",
      value: `Group ${statistics.best_group_fish} (${statistics.best_group_fish_value})`,
      icon: <Fish />,
      color: "#ffc107"
    },
    {
      title: translation.best_participant_points || "Best Participant (Points)",
      value: statistics.best_participant_points?.name || "N/A",
      subvalue: `${statistics.best_participant_points?.total_points || 0} pts`,
      icon: <Star />,
      color: "#ffc107"
    },
    {
      title: translation.best_participant_fish || "Best Participant (Fish)",
      value: statistics.best_participant_fish?.name || "N/A",
      subvalue: `${statistics.best_participant_fish?.total_fish || 0} fish`,
      icon: <Fish />,
      color: "#ffc107"
    },
    {
      title: translation.total_fish || "Total Fish Caught",
      value: statistics.total_fish || 0,
      icon: <Hash />,
      color: "#ffc107"
    }
  ];

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <div className="galben text-white p-2 mb-3">
        {translation.event_statistics || "Event Statistics"}
      </div>

      <Grid container spacing={3}>
        {/* Top Statistics */}
        <Grid item xs={12}>
          <StyledPaper>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <YellowTableCell>{translation.statistic_type || "Statistic"}</YellowTableCell>
                    <YellowTableCell align="right">{translation.value || "Value"}</YellowTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {statItems.map((stat, index) => (
                    <TableRow key={index}>
                      <TableCell
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 1
                        }}
                      >
                        <Box sx={{ color: stat.color, display: 'flex' }}>
                          {stat.icon}
                        </Box>
                        {stat.title}
                      </TableCell>
                      <TableCell align="right">
                        {stat.value}
                        {stat.subvalue && (
                          <Typography variant="caption" display="block" color="text.secondary">
                            {stat.subvalue}
                          </Typography>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </StyledPaper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default TSFStatistics;
