import { Box, Card, CardHeader, CardContent, Typography } from "@mui/material";
import { Info } from "lucide-react";

const GroupInfo = ({ assignments, event, translation }) => {
  if (!assignments) return null;

  return (
    <Card sx={{ mb: 2 }}>
      <CardHeader
        title={
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Info size={20} style={{ marginRight: 8 }} />
            {translation.group_info || "Group Info"}
          </Box>
        }
      />
      <CardContent>
        <Typography variant="body2">
          <strong>{translation.event || "Event"}:</strong> {event?.event_name}
        </Typography>
        <Typography variant="body2">
          <strong>{translation.group_number || "Group Number"}:</strong> {assignments.group_number}
        </Typography>
        <Typography variant="body2">
          <strong>{translation.sector_number || "Sector Number"}:</strong> {assignments.sector_number}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default GroupInfo;
