import React, { useState, useEffect } from "react"
import {
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
  Box,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material"
import { styled } from "@mui/material/styles"
import { CheckCircle } from "@mui/icons-material"
import { toast } from "react-toastify"

const ColorButton = styled(Button)(({ theme, color }) => ({
  backgroundColor: `var(--${color})`,
  color: "var(--white)",
  "&:hover": {
    backgroundColor: `var(--${color})`,
    opacity: 0.9,
  },
}))

const NameTypography = styled(Typography)({
  wordBreak: "break-word",
  hyphens: "auto",
  minHeight: "2.5em",
  display: "-webkit-box",
  WebkitLineClamp: 2,
  WebkitBoxOrient: "vertical",
  overflow: "hidden",
})

const StatsBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: "4px",
  whiteSpace: "nowrap",
  "& .label": {
    flexShrink: 0,
  },
  "& .value": {
    minWidth: 0,
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
})

const MatchCard = ({ match, eventId, onUpdate, translation, updateLoading, refreshCard, currentLeg }) => {
  const [catches, setCatches] = useState(match.my_catches)
  const [openDialog, setOpenDialog] = useState(false)

  useEffect(() => {
    setCatches(match.my_catches)
  }, [match])

  const handleValidateOpponent = async () => {
    const result = await onUpdate(eventId, match.match_id, { validate_opponent: true })
    if (result.success) {
      toast.success(translation.validate_success || "Opponent catches validated successfully")
      if (typeof refreshCard === "function") {
        refreshCard()
      }
      return
    }

    if (result.error === "Match locked (both validated).") {
      toast.info(translation.match_locked || "Match is already validated by both players")
    } else {
      toast.error(translation.validate_error || "Failed to validate opponent catches")
    }
  }

  const handleUpdateCatches = async () => {
    if (catches === null) return
    setOpenDialog(true)
  }

  const handleConfirmCatches = async () => {
    setOpenDialog(false)
    const result = await onUpdate(eventId, match.match_id, { catches })
    if (result.success) {
      toast.success(translation.update_success || "Catches updated successfully")
      if (typeof refreshCard === "function") {
        refreshCard()
      }
    } else {
      if (result.error === "Match locked (both validated).") {
        toast.info(translation.match_locked || "Match is already validated by both players")
      } else {
        toast.error(translation.update_error || "Failed to update catches")
      }
    }
  }

  const catchesSubmitted = match.my_catches !== null || match.my_valid

  return (
    <Card
      sx={{
        backgroundColor: "var(--pagebg)",
        boxShadow: match.leg_number === currentLeg ? "0px 0px 15px var(--maro1)" : "0px 4px 10px var(--shadow1)",
        borderRadius: "4px",
        overflow: "hidden",
        mb: 2,
      }}
    >
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box
              sx={{
                backgroundColor: match.leg_number === currentLeg ? "var(--maro1)" : "transparent",
                padding: "8px",
                borderRadius: "4px 4px 0 0",
                marginBottom: "8px",
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  color: match.leg_number === currentLeg ? "var(--white)" : "var(--maro1)",
                  fontWeight: "bold",
                }}
              >
                {translation.leg || "Leg"} {match.leg_number}
                {match.leg_number === currentLeg && (
                  <Typography component="span" sx={{ ml: 1, fontSize: "0.8em" }}>
                    ({translation.current_leg || "Current Leg"})
                  </Typography>
                )}
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={6}>
            <NameTypography variant="subtitle1" fontWeight="bold" sx={{ color: "var(--green1)" }}>
              {match.my_name}
            </NameTypography>
            <Typography variant="body2" sx={{ color: "var(--shadow1)" }}>
              {translation.seat || "Box"} {match.my_seat_no}
            </Typography>
          </Grid>

          <Grid item xs={6}>
            <NameTypography variant="subtitle1" fontWeight="bold" sx={{ color: "var(--red1)" }}>
              {match.opponent_name}
            </NameTypography>
            <Typography variant="body2" sx={{ color: "var(--shadow1)" }}>
              {translation.seat || "Box"} {match.opponent_seat_no}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Box display="flex" flexDirection="column" gap={2}>
              <TextField
                type="number"
                value={catches ?? ""}
                onChange={(e) => setCatches(e.target.value ? Number(e.target.value) : null)}
                label={translation.catches || "My Catches"}
                disabled={catchesSubmitted}
                size="small"
                fullWidth
                sx={{ "& .MuiOutlinedInput-root": { "& fieldset": { borderColor: "var(--blue1)" } } }}
              />
              {!catchesSubmitted && (
                <ColorButton color="green1" onClick={handleUpdateCatches} disabled={updateLoading || catches === null}>
                  {updateLoading ? translation.updating || "Updating..." : translation.update || "Add My Catches"}
                </ColorButton>
              )}

              {match.validate_opponent_catches && (
                <ColorButton color="blue1" onClick={handleValidateOpponent} disabled={updateLoading}>
                  {translation.validate_opponent || "Validate Opponent"}
                </ColorButton>
              )}
            </Box>
          </Grid>

          <Grid item xs={6}>
            <StatsBox>
              <Typography className="label" sx={{ color: "var(--blue1)" }}>
                {translation.my_catches || "My catches"}:
              </Typography>
              <Typography className="value" sx={{ color: "var(--green1)" }}>
                {match.my_catches ?? "-"}
                {match.my_valid && <CheckCircle sx={{ ml: 0.5, fontSize: "1rem", verticalAlign: "middle" }} />}
              </Typography>
            </StatsBox>
            <StatsBox>
              <Typography className="label" sx={{ color: "var(--blue1)" }}>
                {translation.my_points || "My points"}:
              </Typography>
              <Typography className="value" sx={{ color: "var(--green1)" }}>
                {match.my_points ?? "-"}
              </Typography>
            </StatsBox>
          </Grid>

          <Grid item xs={6}>
            <StatsBox>
              <Typography className="label" sx={{ color: "var(--blue1)" }}>
                {translation.opponent_catches || "Opp. catches"}:
              </Typography>
              <Typography className="value" sx={{ color: "var(--green1)" }}>
                {match.opponent_catches ?? "-"}
                {match.opponent_valid && <CheckCircle sx={{ ml: 0.5, fontSize: "1rem", verticalAlign: "middle" }} />}
              </Typography>
            </StatsBox>
            <StatsBox>
              <Typography className="label" sx={{ color: "var(--blue1)" }}>
                {translation.opponent_points || "Opp. points"}:
              </Typography>
              <Typography className="value" sx={{ color: "var(--green1)" }}>
                {match.opponent_points ?? "-"}
              </Typography>
            </StatsBox>
          </Grid>
        </Grid>
      </CardContent>

      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{translation.confirm_catches_title || "Confirm Catches"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {translation.confirm_catches_text} <strong>{catches}</strong>. {translation.confirm_catches_undone}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} sx={{ color: "var(--maro1)" , marginRight: "auto"}}>
            {translation.cancel || "Cancel"}
          </Button>
          <Button
            onClick={handleConfirmCatches}
            sx={{
              backgroundColor: "var(--green1)",
              color: "white",
              "&:hover": { backgroundColor: "var(--green1)", opacity: 0.9 , marginLeft: "auto"},
            }}
            autoFocus
          >
            {translation.confirm || "Confirm"}
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  )
}

export default MatchCard

