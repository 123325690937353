import React from "react";
import { Box, Typography, Autocomplete, TextField } from "@mui/material";
import { ToggleButtonGroup, ToggleButton } from "@mui/material";
import { Public, Group } from "@mui/icons-material";

const RankingToggle = ({
  translation,
  rankingView,
  handleRankingViewChange,
  availableGroups,
  selectedRankingGroup,
  setSelectedRankingGroup
}) => {
  return (
    <Box
      sx={{
        mb: 3,
        p: 3,
        borderRadius: "lg",
        backgroundColor: "background.paper",
        boxShadow: "sm",
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <ToggleButtonGroup
          value={rankingView}
          exclusive
          onChange={handleRankingViewChange}
          aria-label="ranking view"
          size="small"
        >
          <ToggleButton value="global" aria-label="global ranking">
            <Public sx={{ mr: 1 }} />
            {translation.global_ranking || "Global Ranking"}
          </ToggleButton>
          <ToggleButton value="group" aria-label="group ranking">
            <Group sx={{ mr: 1 }} />
            {translation.group_ranking || "Group Ranking"}
          </ToggleButton>
        </ToggleButtonGroup>

        {rankingView === "group" && (
          <Autocomplete
            options={availableGroups}
            getOptionLabel={(option) => `${translation.group || "Group"} ${option}`}
            renderInput={(params) => (
              <TextField {...params} label={translation.select_group || "Select Group"} size="small" />
            )}
            onChange={(_, value) => setSelectedRankingGroup(value)}
            value={selectedRankingGroup}
            sx={{ width: { xs: "100%", sm: 200 } }}
          />
        )}
      </Box>
    </Box>
  );
};

export default RankingToggle;
