import axios from "axios"
import { toast } from "react-toastify"
import { refreshTokenSetup } from "../utils/refreshToken"
import { getMessaging, getToken } from "firebase/messaging"
import { getOs } from "../utils/firebase"

import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  USER_LOADED_SUCCESS,
  USER_LOADED_FAIL,
  AUTHENTICATED_SUCCESS,
  AUTHENTICATED_FAIL,
  PASSWORD_RESET_SUCCESS,
  PASSWORD_RESET_FAIL,
  PASSWORD_RESET_CONFIRM_SUCCESS,
  PASSWORD_RESET_CONFIRM_FAIL,
  SIGNUP_SUCCESS,
  SIGNUP_FAIL,
  ACTIVATION_SUCCESS,
  ACTIVATION_FAIL,
  GOOGLE_AUTH_SUCCESS,
  GOOGLE_AUTH_FAIL,
  FACEBOOK_AUTH_SUCCESS,
  FACEBOOK_AUTH_FAIL,
  LOGOUT,
  LOGOUT_FINISHED,
  SOCKET_MESSAGE,
  LOGIN_RESET,
  CLEAR_USER_PROFILE,
} from "./types"

// Initialize axios with the refresh token setup
export const axiosInstance = axios.create()
refreshTokenSetup(axiosInstance)

// Function to set up the Authorization header
const setupAxiosAuth = () => {
  const token = localStorage.getItem("access")
  if (token) {
    axiosInstance.defaults.headers.common["Authorization"] = `JWT ${token}`
  } else {
    delete axiosInstance.defaults.headers.common["Authorization"]
  }
}

// Call setupAxiosAuth immediately to set the token if it exists
setupAxiosAuth()

const fcmToken = (access) => {
  const messaging = getMessaging()
  getToken(messaging, { vapidKey: process.env.REACT_APP_VAPIDKEY })
    .then((currentToken) => {
      if (currentToken) {
        console.log("Firebase messaging token:", currentToken)
        const fcmTokenData = {
          token: currentToken,
          device: "Web Browser",
          os: getOs(),
        }
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${access}`,
            Accept: "application/json",
          },
        }
        axiosInstance
          .post(`${process.env.REACT_APP_API_URL}/v1/fcm-token`, fcmTokenData, config)
          .then(() => console.log("FCM token sent to server successfully."))
          .catch((err) => console.error("Error sending FCM token to server:", err))
      } else {
        console.log("No Firebase messaging token available. Request permissions to generate one.")
      }
    })
    .catch((err) => {
      console.error("An error occurred while retrieving the Firebase messaging token.", err)
    })
}

export const loadUser = () => async (dispatch) => {
  if (localStorage.getItem("access")) {
    setupAxiosAuth() // Ensure the token is set before making the request
    try {
      const res = await axiosInstance.get(`${process.env.REACT_APP_API_URL}/v1/profiles/userprofile/`)
      dispatch({
        type: USER_LOADED_SUCCESS,
        payload: res.data,
      })
    } catch (err) {
      dispatch({
        type: USER_LOADED_FAIL,
      })
    }
  } else {
    dispatch({
      type: USER_LOADED_FAIL,
    })
  }
}

export const googleAuthenticate = (state, code) => async (dispatch) => {
  if (state && code && !localStorage.getItem("access")) {
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    }

    const details = {
      state: state,
      code: code,
    }

    const formBody = Object.keys(details)
      .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(details[key]))
      .join("&")

    try {
      const res = await axiosInstance.post(`${process.env.REACT_APP_API_URL}/auth/o/google-oauth2/?${formBody}`, config)

      dispatch({
        type: GOOGLE_AUTH_SUCCESS,
        payload: res.data,
      })

      dispatch(loadUser())
    } catch (err) {
      dispatch({
        type: GOOGLE_AUTH_FAIL,
      })
    }
  }
}

export const facebookAuthenticate = (state, code) => async (dispatch) => {
  if (state && code && !localStorage.getItem("access")) {
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    }

    const details = {
      state: state,
      code: code,
    }

    const formBody = Object.keys(details)
      .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(details[key]))
      .join("&")

    try {
      const res = await axiosInstance.post(`${process.env.REACT_APP_API_URL}/auth/o/facebook/?${formBody}`, config)

      dispatch({
        type: FACEBOOK_AUTH_SUCCESS,
        payload: res.data,
      })

      dispatch(loadUser())
    } catch (err) {
      dispatch({
        type: FACEBOOK_AUTH_FAIL,
      })
    }
  }
}

export const checkAuthenticated = () => async (dispatch) => {
  if (localStorage.getItem("access")) {
    setupAxiosAuth() // Ensure the token is set before making the request
    try {
      const res = await axiosInstance.post(`${process.env.REACT_APP_API_URL}/auth/jwt/verify/`, {
        token: localStorage.getItem("access"),
      })

      if (res.data.code !== "token_not_valid") {
        dispatch({
          type: AUTHENTICATED_SUCCESS,
        })
        dispatch(loadUser()) // Load user data if authenticated
      } else {
        dispatch({
          type: AUTHENTICATED_FAIL,
        })
      }
    } catch (err) {
      dispatch({
        type: AUTHENTICATED_FAIL,
      })
    }
  } else {
    dispatch({
      type: AUTHENTICATED_FAIL,
    })
  }
}

export const login = (email, password, recaptcha) => async (dispatch) => {
  dispatch({
    type: LOGIN_RESET,
  })

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  }
  const body = JSON.stringify({ email, password, recaptcha })

  try {
    const res = await axios.post(`${process.env.REACT_APP_API_URL}/auth/jwt/create/`, body, config)

    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data,
    })

    localStorage.setItem("access", res.data.access)
    localStorage.setItem("refresh", res.data.refresh)

    setupAxiosAuth() // Set up the Authorization header after successful login

    await dispatch(loadUser())
    fcmToken(res.data.access)
  } catch (err) {
    toast.error(err?.response?.data?.detail || "Failed to login. Please try again.")
    dispatch({
      type: LOGIN_FAIL,
      payload: err?.response?.data?.detail,
    })
  }
}

export const signup = (first_name, last_name, email, password, re_password) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  }

  const body = JSON.stringify({ first_name, last_name, email, password, re_password })

  try {
    const res = await axiosInstance.post(`${process.env.REACT_APP_API_URL}/auth/users/`, body, config)

    dispatch({
      type: SIGNUP_SUCCESS,
      payload: res.data,
    })

    return Promise.resolve(res.data)
  } catch (err) {
    dispatch({
      type: SIGNUP_FAIL,
      payload: err.response?.data || { error: "Your account couldn't be created." },
    })

    return Promise.reject(err.response?.data || { error: "Your account couldn't be created." })
  }
}

export const verify = (uid, token) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  }

  const body = JSON.stringify({ uid, token })

  try {
    await axiosInstance.post(`${process.env.REACT_APP_API_URL}/auth/users/activation/`, body, config)

    dispatch({
      type: ACTIVATION_SUCCESS,
    })
    toast.success("Your account has been activated.")
  } catch (err) {
    dispatch({
      type: ACTIVATION_FAIL,
    })
    toast.error("Your activation credentials are wrong.")
  }
}

export const reset_password = (email, recaptcha) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  }

  const body = JSON.stringify({ email, recaptcha })

  try {
    await axiosInstance.post(`${process.env.REACT_APP_API_URL}/auth/users/reset_password/`, body, config)

    dispatch({
      type: PASSWORD_RESET_SUCCESS,
    })
    toast.success("Password reset email sent successfully")
  } catch (err) {
    dispatch({
      type: PASSWORD_RESET_FAIL,
    })
    toast.error("Error sending password reset email")
  }
}

export const reset_password_confirm = (uid, token, new_password, re_new_password) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  }

  const body = JSON.stringify({ uid, token, new_password, re_new_password })

  try {
    await axiosInstance.post(`${process.env.REACT_APP_API_URL}/auth/users/reset_password_confirm/`, body, config)

    dispatch({
      type: PASSWORD_RESET_CONFIRM_SUCCESS,
    })
  } catch (err) {
    dispatch({
      type: PASSWORD_RESET_CONFIRM_FAIL,
    })
  }
}

export const loadSocket = () => async (dispatch) => {
  if (localStorage.getItem("access")) {
    const authToken = localStorage.getItem("access")
    const socketUrl = authToken
      ? `${process.env.REACT_APP_WEBSOCKET_PREFIX}${process.env.REACT_APP_WEBSOCKET_APP_URL}/ws/notifications/?token=${authToken}`
      : null

    try {
      const websocket = new WebSocket(socketUrl)

      websocket.onopen = (event) => {
        console.warn("socket connected")
      }

      websocket.onmessage = (event) => {
        dispatch({
          type: SOCKET_MESSAGE,
          payload: JSON.parse(event.data),
        })
      }
    } catch (err) {
      console.error("WebSocket connection error:", err)
    }
  } else {
    dispatch({
      type: AUTHENTICATED_FAIL,
    })
  }
}

export const logout = () => (dispatch) => {
  localStorage.removeItem("access")
  localStorage.removeItem("refresh")
  setupAxiosAuth() // Remove the Authorization header
  dispatch({ type: CLEAR_USER_PROFILE })
  dispatch({ type: LOGOUT })
}

export const refreshUser = () => (dispatch) => {
  dispatch({
    type: LOGOUT_FINISHED,
  })
}

export const invalidateAndTestRefresh = () => async (dispatch) => {
  setupAxiosAuth() // Ensure the token is set before making the request
  try {
    await axiosInstance.post(`${process.env.REACT_APP_API_URL}/auth/invalidate-token/`, {})
    await dispatch(loadUser())
    toast.success("Token refresh successful!")
  } catch (error) {
    console.error("Refresh test failed:", error)
    toast.error("Token refresh failed: " + (error.response?.data?.detail || error.message))
    if (error.response?.status === 401) {
      dispatch(logout())
      toast.error("Session expired. Please log in again.")
    }
  }
}

