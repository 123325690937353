import { useState, useEffect } from "react"
import { Link, useNavigate } from "react-router-dom"
import { connect } from "react-redux"
import { signup } from "../actions/auth"
import TermsModal from "../components/TermsModal"
import GDPRModal from "../components/GDPRModal"
import {
  Container,
  Box,
  Typography,
  TextField,
  Button,
  Paper,
  Checkbox,
  FormControlLabel,
  Alert,
  Link as MuiLink,
} from "@mui/material"
import { styled } from "@mui/material/styles"
import { toast } from "react-toastify"

const StyledLink = styled(MuiLink)({
  cursor: "pointer",
  color: "primary.main",
  "&:hover": {
    textDecoration: "underline",
  },
})

const StyledTextField = styled(TextField)({
  "& .MuiInputBase-input:-webkit-autofill": {
    "-webkit-box-shadow": "0 0 0 30px white inset !important",
    "-webkit-text-fill-color": "inherit !important",
    "border-color": "inherit !important",
  },
  "& .MuiInputBase-input:-webkit-autofill:hover": {
    "-webkit-box-shadow": "0 0 0 30px white inset !important",
  },
  "& .MuiInputBase-input:-webkit-autofill:focus": {
    "-webkit-box-shadow": "0 0 0 30px white inset !important",
  },
  "& .MuiInputBase-input:-webkit-autofill:active": {
    "-webkit-box-shadow": "0 0 0 30px white inset !important",
  },
})

const Signup = ({ signup, isAuthenticated, translation }) => {
  const [accountCreated, setAccountCreated] = useState(false)
  const navigate = useNavigate()
  const [agreeToTerms, setAgreeToTerms] = useState(false)
  const [showTerms, setShowTerms] = useState(false)
  const [showGDPR, setShowGDPR] = useState(false)
  const [errors, setErrors] = useState({})
  const [isSubmitting, setIsSubmitting] = useState(false)

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/")
    } else if (accountCreated) {
      navigate("/")
    }
  }, [isAuthenticated, accountCreated, navigate])

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    re_password: "",
  })

  const { first_name, last_name, email, password, re_password } = formData

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
    setErrors({ ...errors, [e.target.name]: [] })
  }

  const validateForm = () => {
    const newErrors = {}
    if (!first_name.trim()) newErrors.first_name = ["First name is required"]
    if (!last_name.trim()) newErrors.last_name = ["Last name is required"]
    if (!email.trim()) newErrors.email = ["Email is required"]
    else if (!/\S+@\S+\.\S+/.test(email)) newErrors.email = ["Email is invalid"]
    if (!password) newErrors.password = ["Password is required"]
    else if (password.length < 8) newErrors.password = ["Password must be at least 8 characters"]
    if (password !== re_password) newErrors.re_password = ["Passwords do not match"]
    if (!agreeToTerms) newErrors.terms = ["You must agree to the terms and conditions"]
    return newErrors
  }

  const onSubmit = async (e) => {
    e.preventDefault()
    setIsSubmitting(true)
    setErrors({})

    const formErrors = validateForm()
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors)
      setIsSubmitting(false)
      return
    }

    try {
      await signup(first_name, last_name, email, password, re_password)
      setAccountCreated(true)
      toast.success("Your account has been created. We have sent you an email for account activation.")
      setFormData({
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        re_password: "",
      })
      setAgreeToTerms(false)
    } catch (err) {
      const errorData = err
      const newErrors = {}

      Object.keys(errorData).forEach((key) => {
        if (Array.isArray(errorData[key])) {
          newErrors[key] = errorData[key]
          errorData[key].forEach((error) => toast.error(error))
        } else if (typeof errorData[key] === "string") {
          newErrors[key] = [errorData[key]]
          toast.error(errorData[key])
        }
      })

      setErrors(newErrors)
      setAccountCreated(false)
    } finally {
      setIsSubmitting(false)
    }
  }

  const handleAgreeToTerms = (e) => {
    setAgreeToTerms(e.target.checked)
    setErrors({ ...errors, terms: [] })
  }

  return (
    <Container maxWidth="sm">
      <Box sx={{ mt: 4, mb: 4 }}>
        <Paper elevation={3} sx={{ p: 4, borderRadius: 2 }}>
          <Box sx={{ display: "flex", justifyContent: "center", mb: 3 }}>
            <img
              src="https://streetfishing.fra1.cdn.digitaloceanspaces.com/media/assets/logo_orange_yellow_transparent.png"
              alt="logo"
              style={{ width: "100%", maxWidth: "300px" }}
            />
          </Box>

          <Typography
            variant="h4"
            component="h1"
            gutterBottom
            align="center"
            sx={{ fontWeight: "bold", color: "primary.main" }}
          >
            {translation.signup_title}
          </Typography>
          <Typography variant="subtitle1" gutterBottom align="center" sx={{ mb: 3 }}>
            {translation.signup_subtitle}
          </Typography>

          <Box component="form" onSubmit={onSubmit} noValidate sx={{ mt: 1 }}>
            <StyledTextField
              margin="normal"
              required
              fullWidth
              id="first_name"
              label={translation.first_name}
              name="first_name"
              autoComplete="given-name"
              autoFocus
              value={first_name}
              onChange={onChange}
              error={!!errors.first_name}
              helperText={errors.first_name && errors.first_name.join(", ")}
            />
            <StyledTextField
              margin="normal"
              required
              fullWidth
              id="last_name"
              label={translation.last_name}
              name="last_name"
              autoComplete="family-name"
              value={last_name}
              onChange={onChange}
              error={!!errors.last_name}
              helperText={errors.last_name && errors.last_name.join(", ")}
            />
            <StyledTextField
              margin="normal"
              required
              fullWidth
              id="email"
              label={translation.email}
              name="email"
              autoComplete="email"
              value={email}
              onChange={onChange}
              error={!!errors.email}
              helperText={errors.email && errors.email.join(", ")}
            />
            <StyledTextField
              margin="normal"
              required
              fullWidth
              name="password"
              label={translation.password}
              type="password"
              id="password"
              autoComplete="new-password"
              value={password}
              onChange={onChange}
              error={!!errors.password}
              helperText={errors.password && errors.password.join(", ")}
            />
            <StyledTextField
              margin="normal"
              required
              fullWidth
              name="re_password"
              label={translation.confirm_password}
              type="password"
              id="re_password"
              autoComplete="new-password"
              value={re_password}
              onChange={onChange}
              error={!!errors.re_password}
              helperText={errors.re_password && errors.re_password.join(", ")}
            />
            <FormControlLabel
              control={<Checkbox checked={agreeToTerms} onChange={handleAgreeToTerms} color="primary" required />}
              label={
                <Typography variant="body2">
                  {translation.agree_terms}
                  <StyledLink onClick={() => setShowTerms(true)}> {translation.terms_and_conditions} </StyledLink>,
                  <StyledLink onClick={() => setShowGDPR(true)}> GDPR Policy </StyledLink>
                </Typography>
              }
            />
            {errors.terms && (
              <Typography color="error" variant="caption">
                {errors.terms.join(", ")}
              </Typography>
            )}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              disabled={isSubmitting}
              sx={{
                mt: 3,
                mb: 2,
                py: 1.5,
                fontSize: "1.1rem",
                fontWeight: "bold",
                backgroundColor: "green",
                "&:hover": {
                  backgroundColor: "darkgreen",
                },
              }}
            >
              {isSubmitting ? "Signing Up..." : translation.register_cta}
            </Button>
          </Box>

          <TermsModal show={showTerms} onHide={() => setShowTerms(false)} />
          <GDPRModal show={showGDPR} onHide={() => setShowGDPR(false)} />

          {errors.non_field_errors && (
            <Alert severity="error" sx={{ mt: 2 }}>
              {errors.non_field_errors.join(", ")}
            </Alert>
          )}

          <Typography variant="body2" align="center" sx={{ mt: 2 }}>
            {translation.already_account}
            <Link to="/login" style={{ marginLeft: "5px", color: "primary.main" }}>
              {translation.sign_in}
            </Link>
          </Typography>
          <Typography variant="body2" align="center" sx={{ mt: 2 }}>
            {translation.troubles_register}
            <Link to="/contactus" style={{ marginLeft: "5px", color: "primary.main" }}>
              {translation.contact_us_title}
            </Link>
          </Typography>
        </Paper>
      </Box>
    </Container>
  )
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  translation: state.appState.translation,
})

export default connect(mapStateToProps, { signup })(Signup)

