import { useState, useEffect, useRef } from "react"
import { useParams } from "react-router-dom"
import axios from "axios"
import { motion } from "framer-motion"
import getAxiosConfig from "../utils/axiosConfig"
import Loader from "./Loader"
import { toast } from "react-toastify"
import { Typography, Box, Paper } from "@mui/material"
import { ArrowCircleDownOutlined, ArrowCircleUpOutlined, InfoOutlined, StopCircleOutlined } from "@mui/icons-material"

const RankingMovement = () => {
  const { eventId } = useParams()
  const [rankings, setRankings] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const prevRankingsRef = useRef([])

  useEffect(() => {
    const fetchRankings = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_API_URL}/v1/events/${eventId}/rankings/movements/`,
          getAxiosConfig(),
        )
        const newRankings = res.data

        const prevRankings = prevRankingsRef.current

        const updatedRankings = newRankings.map((user) => {
          const identifier = user.user || user.team
          const prevUser = prevRankings.find((u) => (u.user || u.team) === identifier)
          const rankChanged = prevUser && prevUser.current_rank !== user.current_rank
          const pointsChanged = prevUser && prevUser.total_points !== user.total_points
          return {
            ...user,
            rankChanged,
            pointsChanged,
          }
        })

        setRankings(updatedRankings)
        prevRankingsRef.current = newRankings
        setLoading(false)
      } catch (err) {
        if (err.response && err.response.status === 429) {
          toast.error(err.response.data.detail)
        } else {
          toast.error("Failed to fetch rankings.")
        }
        setLoading(false)
      }
    }

    fetchRankings()
    const interval = setInterval(() => {
      fetchRankings()
    }, 30000)

    return () => clearInterval(interval)
  }, [eventId])

  const getRank = (event) => {
    if (event.current_rank < event.previous_rank) {
      return <ArrowCircleUpOutlined sx={{ color: "var(--green1)" }} />
    }
    if (event.current_rank > event.previous_rank) {
      return <ArrowCircleDownOutlined sx={{ color: "var(--red1)" }} />
    }
    if (event.current_rank === event.previous_rank) {
      return <StopCircleOutlined sx={{ color: "var(--blue1)" }} />
    }
  }

  if (loading) return <Loader />
  if (error) return <p>{error}</p>

  return (
    <Box sx={{ padding: "16px", maxWidth: "800px", margin: "0 auto" }}>
      <Paper
        elevation={0}
        sx={{
          p: 2,
          mb: 2,
          backgroundColor: "var(--warning-light)",
          border: "1px solid var(--warning)",
          borderRadius: "8px",
          display: "flex",
          alignItems: "center",
          gap: 1,
        }}
      >
        <InfoOutlined sx={{ color: "var(--warning)" }} />
        <Typography variant="subtitle2" sx={{ color: "var(--warning)" }}>
          Scorul va fi actualizat automat la fiecare 30 de secunde.
        </Typography>
      </Paper>

      {rankings.map((user) => (
        <motion.div
          key={user.user || user.team}
          layout
          animate={{
            backgroundColor:
              user.rankChanged || user.pointsChanged
                ? ["var(--pagebg)", "var(--highlight)", "var(--pagebg)"]
                : "var(--pagebg)",
          }}
          transition={{
            backgroundColor: user.rankChanged || user.pointsChanged ? { duration: 1 } : { duration: 0 },
            layout: {
              duration: 0.5,
              type: "spring",
              stiffness: 300,
              damping: 30,
            },
          }}
        >
          <Paper
            elevation={1}
            sx={{
              p: 2,
              mb: 1,
              display: "flex",
              alignItems: "center",
              gap: 2,
              borderRadius: "8px",
              "&:hover": {
                backgroundColor: "var(--hover-bg)",
              },
            }}
          >
            <Typography
              variant="h6"
              sx={{
                minWidth: "30px",
                color: "var(--text-secondary)",
              }}
            >
              {user.current_rank}.
            </Typography>

            <Typography
              variant="subtitle1"
              sx={{
                flex: 1,
                fontWeight: 500,
                color: "var(--text-primary)",
              }}
            >
              {user.user || user.team}
            </Typography>

            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
              {getRank(user)}
              <Typography
                variant="h6"
                sx={{
                  minWidth: "50px",
                  textAlign: "right",
                  fontWeight: 600,
                  color: "var(--text-primary)",
                }}
              >
                {user.total_points}
              </Typography>
            </Box>
          </Paper>
        </motion.div>
      ))}
    </Box>
  )
}

export default RankingMovement

