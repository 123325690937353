import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { loadUser, refreshUser, loadSocket } from '../actions/auth';
import { load_fish_types, load_sponsors , load_location, load_ongoing, load_validators,
         load_performance, load_topanglers,
 } from '../actions/utilsState';

const Auth = ({ children, isAuthenticated, loadUser, failedAuth, logout, refreshUser, load_sponsors, load_fish_types, load_location, load_ongoing, load_performance, load_topanglers }) => {
  const navigate = useNavigate();

  useEffect(() => {
    if(!isAuthenticated) {
      loadUser();
    }
    if(isAuthenticated) {
      load_sponsors();
      load_fish_types();
      load_ongoing();
      load_validators();
      load_performance();
      load_topanglers();
    }
  }, [isAuthenticated, loadUser, load_sponsors, load_fish_types, load_location, load_ongoing]);

  useEffect(() => {
    if(failedAuth || logout) {
      navigate('/login');
      refreshUser();
    }
  }, [failedAuth, logout, navigate, refreshUser]);

  // useEffect(() => {
  //   if(isAuthenticated) {
  //     loadSocket();
  //   }
  // }, [isAuthenticated, loadSocket])

  return (
      <div>
          {isAuthenticated && children}
      </div>
  );
};

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    failedAuth: state.auth.failedAuth,
    logout: state.auth.logout,
});

const mapDispatchToProps = (dispatch) => {
  return {
    loadUser: () => {
      dispatch(loadUser())
    },
    loadSocket: () => {
      dispatch(loadSocket())
    },
    refreshUser: () => {
      dispatch(refreshUser())
    },
    load_sponsors: () => dispatch(load_sponsors()),
    load_fish_types: () => dispatch(load_fish_types()),
    load_location: () => dispatch(load_location()),
    load_ongoing: () => dispatch(load_ongoing()),
    load_performance: () => dispatch(load_performance()),
    load_topanglers: () => dispatch(load_topanglers()),
    // load_scoring_types: () => dispatch(load_scoring_types()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Auth);
