import { useEffect, useState } from "react"
import { connect } from "react-redux"
import { useParams } from "react-router-dom"
import axios from "axios"
import { toast } from "react-toastify"
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  TextField,
  Box,
  Typography,
  Autocomplete,
} from "@mui/material"
import { Visibility, CheckCircle, Cancel, Check } from "@mui/icons-material"
import getAxiosConfig from "../utils/axiosConfig"
import DropdownFishTypes from "./DropdownFishTypes"

const RevalidateTable = ({ enrollments, event, translation, fish_types, refreshList }) => {
  const { eventId } = useParams()
  const [userQuery, setUserQuery] = useState(null)
  const [mapData, setMapData] = useState(null)
  const [captureModal, setCaptureModal] = useState(null)
  const [selectedFish, setSelectedFish] = useState(null)
  const [selectedFishType, setSelectedFishType] = useState(null)
  const [selectedFishLength, setSelectedFishLength] = useState(null)
  const [searchTerm, setSearchTerm] = useState("")

  useEffect(() => {
    if (event) {
      const fishScoring = event.event_fish_scoring
      setSelectedFish(fishScoring.map((item) => item.fish))
    }
  }, [event])

  useEffect(() => {
    if (userQuery) {
      const fetchData = async () => {
        const config = getAxiosConfig()
        try {
          const res = await axios.get(
            `${process.env.REACT_APP_API_URL}/v2/event/user-captures/${eventId}/${userQuery.id}/`,
            config,
          )
          setMapData(res.data)
        } catch (err) {
          console.error(err)
          toast.error("Failed to fetch user captures")
        }
      }
      fetchData()
    }
  }, [userQuery, eventId])

  const updateCapture = async (item, status) => {
    try {
      const config = getAxiosConfig()
      await axios.patch(
        `${process.env.REACT_APP_API_URL}/v2/event/captures-validate/${item.id}/`,
        {
          fish_length: selectedFishLength,
          is_approved: status,
          fish: selectedFishType,
        },
        config,
      )
      toast.success("Fish updated successfully!")
      refreshList()
      setCaptureModal(null)
    } catch (err) {
      toast.error("Error while updating fish!")
      setCaptureModal(null)
    }
  }

  const filteredEnrollments = enrollments?.filter((enrollment) =>
    enrollment.user_full_name.toLowerCase().includes(searchTerm.toLowerCase()),
  )

  return (
    <Box sx={{ width: "100%", mt: 4 }}>
      <Autocomplete
        options={enrollments || []}
        getOptionLabel={(option) => option.user_full_name}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Search and select user"
            variant="outlined"
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        )}
        onChange={(event, newValue) => {
          setUserQuery(newValue ? { id: newValue.user, value: newValue.user_full_name } : null)
        }}
        sx={{ mb: 2 }}
      />
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="revalidate table">
          <TableHead>
            <TableRow>
              <TableCell>Fish</TableCell>
              <TableCell>Length</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {mapData?.map((item, key) => (
              <TableRow key={key}>
                <TableCell>
                  {fish_types.find((el) => el.id === item.fish)?.name}
                  <Typography variant="caption" display="block">
                    {item.upload_date ? new Date(item.upload_date).toLocaleTimeString() : ""}
                  </Typography>
                </TableCell>
                <TableCell>{item.fish_length}</TableCell>
                <TableCell>
                  {item.is_approved ? (
                    <CheckCircle color="success" />
                  ) : item.is_rejected ? (
                    <Cancel color="error" />
                  ) : (
                    <Check color="warning" />
                  )}
                </TableCell>
                <TableCell>
                  <IconButton
                    onClick={() => {
                      setCaptureModal(item)
                      setSelectedFishLength(item.fish_length)
                      setSelectedFishType(item.fish)
                    }}
                  >
                    <Visibility />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog
        open={captureModal !== null}
        onClose={() => setCaptureModal(null)}
        maxWidth="lg"
        fullWidth
        PaperProps={{
          sx: {
            width: "100%",
            maxWidth: "lg",
            height: "95vh",
          },
        }}
      >
        <DialogTitle sx={{ pb: 0 }}>Captura</DialogTitle>
        <DialogContent>
          {captureModal && (
            <Box sx={{ display: "flex", flexDirection: "column", gap: 2, mt: 2 }}>
              <TextField
                label={translation.user_name}
                value={captureModal.user_name}
                disabled
                fullWidth
                sx={{ mt: 2 }}
              />
              <DropdownFishTypes
                type="text"
                className="form-control text-start"
                name={translation.fish_type}
                label={translation.fish_type}
                options={fish_types?.filter((item) => selectedFish?.includes(item.id))}
                selectedOptions={selectedFishType}
                onSelect={(e) => setSelectedFishType(e.target.value)}
              />
              <TextField
                type="number"
                label={translation.ong_fish_length}
                placeholder={translation.ong_fish_length}
                onChange={(e) => setSelectedFishLength(e.target.value)}
                value={selectedFishLength}
                fullWidth
              />
              <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2, mb: 2 }}>
                <Button onClick={() => updateCapture(captureModal, false)} color="error" variant="contained">
                  Reject
                </Button>
                <Button onClick={() => updateCapture(captureModal, true)} color="primary" variant="contained">
                  Validate
                </Button>
              </Box>
              <Box sx={{ mt: 2 }}>
                <img
                  style={{ width: "100%", maxHeight: "50vh", objectFit: "contain" }}
                  src={captureModal.photo || "/placeholder.svg"}
                  alt="Capture"
                />
              </Box>
            </Box>
          )}
        </DialogContent>
      </Dialog>
    </Box>
  )
}

const mapStateToProps = (state) => ({
  translation: state.appState.translation,
  fish_types: state.utilsState.fish_types,
  event: state.eventState.event,
})

export default connect(mapStateToProps, null)(RevalidateTable)

