import axios from "axios"

let isRefreshing = false
let failedQueue = []

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error)
    } else {
      prom.resolve(token)
    }
  })

  failedQueue = []
}

export const refreshTokenSetup = (axiosInstance) => {
  axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config

      if (error.response?.status === 401 && !originalRequest._retry) {
        if (isRefreshing) {
          return new Promise((resolve, reject) => {
            failedQueue.push({ resolve, reject })
          })
            .then((token) => {
              originalRequest.headers["Authorization"] = "JWT " + token
              return axiosInstance(originalRequest)
            })
            .catch((err) => Promise.reject(err))
        }

        originalRequest._retry = true
        isRefreshing = true

        try {
          const refreshToken = localStorage.getItem("refresh")
          const response = await axios.post(`${process.env.REACT_APP_API_URL}/auth/jwt/refresh/`, {
            refresh: refreshToken,
          })

          const { access } = response.data

          localStorage.setItem("access", access)

          axiosInstance.defaults.headers.common["Authorization"] = "JWT " + access
          originalRequest.headers["Authorization"] = "JWT " + access

          processQueue(null, access)
          return axiosInstance(originalRequest)
        } catch (err) {
          processQueue(err, null)
          localStorage.removeItem("access")
          localStorage.removeItem("refresh")
          // Redirect to login page or dispatch a logout action
          window.location.href = "/login"
          return Promise.reject(err)
        } finally {
          isRefreshing = false
        }
      }

      return Promise.reject(error)
    },
  )
}

