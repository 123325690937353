import { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { fetchTSFAssignments } from "../actions/tsfActions";
import { loadEvent } from "../actions/eventsState";
import { useIsMobile } from "../hooks/useIsMobile";

// MUI Components
import {
  Typography,
  CircularProgress,
  Grid,
  Box
} from "@mui/material";

// Component Imports
import LegSelector from "../components/tsf-event/LegSelector";
import GroupInfo from "../components/tsf-event/GroupInfo";
import UserAssignment from "../components/tsf-event/UserAssignment";
import GroupStandings from "../components/tsf-event/GroupStandings";
import ParticipantsTable from "../components/tsf-event/ParticipantsTable";

const TSFEventOngoing = () => {
  const { eventId } = useParams();
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const translation = useSelector((state) => state.appState?.translation || {});
  const event = useSelector((state) => state.eventState?.event);
  const user = useSelector((state) => state.auth?.user);

  const [assignments, setAssignments] = useState(null);
  const [loading, setLoading] = useState(true);
  const [currentLeg, setCurrentLeg] = useState(1);

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      await dispatch(loadEvent(eventId));
      const result = await dispatch(fetchTSFAssignments(eventId));
      if (result.success) {
        setAssignments(result.data);
      } else {
        toast.error(translation.failed_to_fetch_assignments || "Failed to fetch assignments");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error(translation.failed_to_fetch_assignments || "Failed to fetch assignments");
    } finally {
      setLoading(false);
    }
  }, [dispatch, eventId, translation]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const getUserAssignment = useCallback(() => {
    if (!assignments || !user) return null;

    const userAssignment = assignments.participants.find(
      (participant) => participant.user_id === user.account?.id
    );

    return userAssignment;
  }, [assignments, user]);

  // Determine the leg count from the first participant
  const getLegCount = useCallback(() => {
    if (!assignments?.participants?.length) return 0;
    const firstParticipant = assignments.participants[0];
    return firstParticipant.legs.length;
  }, [assignments]);

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: 300 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ padding: 2, maxWidth: 1200, margin: '0 auto' }}>
      <Typography variant="h4" component="h1" sx={{ mb: 1 }}>
        {translation.tsf_event_ongoing || "TSF Event"}
      </Typography>
      <Typography variant="h6" color="text.secondary" sx={{ mb: 3 }}>
        {event?.event_name}
      </Typography>

      <LegSelector
        legCount={getLegCount()}
        currentLeg={currentLeg}
        setCurrentLeg={setCurrentLeg}
        translation={translation}
      />

      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <GroupInfo
              assignments={assignments}
              event={event}
              translation={translation}
            />
            <UserAssignment
              userAssignment={getUserAssignment()}
              currentLeg={currentLeg}
              translation={translation}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={8}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <GroupStandings
              assignments={assignments}
              currentLeg={currentLeg}
              user={user}
              translation={translation}
            />
            <ParticipantsTable
              assignments={assignments}
              currentLeg={currentLeg}
              user={user}
              translation={translation}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TSFEventOngoing;

