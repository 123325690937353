import React, { useState } from "react";
import {
  Box, Typography, Autocomplete, TextField,
  Paper, Alert, Chip, Card, CardHeader, CardContent
} from "@mui/material";
import { BarChart, ChevronUp, ChevronDown } from "lucide-react";
import { useIsMobile } from "../../hooks/useIsMobile";

const GroupRanking = ({
  translation,
  groupRanking,
  selectedRankingGroup,
  setSelectedRankingGroup,
  showGroupSelector = true // Default to true for backward compatibility
}) => {
  const [expandedUser, setExpandedUser] = useState(null);
  const isMobile = useIsMobile();

  if (!groupRanking) {
    return (
      <Alert severity="info">
        {translation.no_group_ranking_data || "No group ranking data available."}
      </Alert>
    );
  }

  const availableGroups = Object.keys(groupRanking).map(Number).sort((a, b) => a - b);

  if (availableGroups.length === 0) {
    return (
      <Alert severity="info">
        {translation.no_groups_available || "No groups available."}
      </Alert>
    );
  }

  // Check if we need to select a group and show only selector
  if (!selectedRankingGroup || !groupRanking[selectedRankingGroup]) {
    // If no group is selected or the selected group doesn't exist, show only the selector
    return (
      <Card
        elevation={0}
        sx={{
          mb: 4,
          p: 3,
          border: "1px solid",
          borderColor: "divider",
          borderRadius: "lg"
        }}
      >
        <Typography variant="h6" gutterBottom>
          {translation.select_group_to_view_ranking || "Please select a group to view its ranking."}
        </Typography>
        {showGroupSelector && (
          <Autocomplete
            options={availableGroups}
            getOptionLabel={(option) => `${translation.group || "Group"} ${option}`}
            renderInput={(params) => (
              <TextField {...params} label={translation.select_group || "Select Group"} size="small" />
            )}
            onChange={(_, value) => setSelectedRankingGroup(value)}
            value={selectedRankingGroup}
            sx={{ maxWidth: 300 }}
          />
        )}
      </Card>
    );
  }

  const selectedGroupParticipants = groupRanking[selectedRankingGroup];

  return (
    <Box sx={{ mb: 4 }}>
      <Card
        elevation={0}
        sx={{
          mb: 2,
          border: "1px solid",
          borderColor: "divider",
          borderRadius: "lg"
        }}
      >
        <CardHeader
          title={
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <BarChart size={20} style={{ marginRight: 8 }} />
              {translation.group || "Group"} {selectedRankingGroup} {translation.ranking || "Ranking"}
            </Box>
          }
          subheader={translation.group_summary || "Summary of group participants"}
        />
        <CardContent>
          {selectedGroupParticipants && selectedGroupParticipants.length > 0 ? (
            selectedGroupParticipants.map((participant, index) => {
              const isExpanded = expandedUser === participant.user_id;

              // Safely format numbers with toFixed, handling potential undefined values
              const totalPoints = participant.total_points != null ?
                typeof participant.total_points === 'number' ?
                  participant.total_points.toFixed(1) : participant.total_points : "0.0";

              return (
                <Paper
                  key={`group-${selectedRankingGroup}-${participant.user_id || "ghost"}-${index}`}
                  elevation={0}
                  sx={{
                    mb: 2,
                    p: 2,
                    borderRadius: 2,
                    border: '1px solid',
                    borderColor: 'divider',
                    backgroundColor: index < 3 ?
                      [
                        'rgba(255, 215, 0, 0.05)',
                        'rgba(192, 192, 192, 0.05)',
                        'rgba(205, 127, 50, 0.05)'
                      ][index] : 'inherit'
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      cursor: 'pointer',
                      flexWrap: isMobile ? 'wrap' : 'nowrap'
                    }}
                    onClick={() => setExpandedUser(isExpanded ? null : participant.user_id)}
                  >
                    {/* Rank and User Info */}
                    <Box sx={{
                      display: 'flex',
                      alignItems: 'center',
                      width: isMobile ? '100%' : 'auto',
                      mb: isMobile ? 1 : 0
                    }}>
                      <Box
                        sx={{
                          width: 32,
                          height: 32,
                          borderRadius: '50%',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          bgcolor: index < 3 ? ['gold', 'silver', '#cd7f32'][index] : 'grey.200',
                          color: index < 3 ? (index === 0 ? 'black' : 'white') : 'text.primary',
                          fontWeight: 'bold',
                          mr: 2,
                          flexShrink: 0
                        }}
                      >
                        {index + 1}
                      </Box>
                      <Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, flexWrap: 'wrap' }}>
                          <Typography variant="subtitle1" sx={{ fontWeight: 'medium' }}>
                            {participant.name}
                          </Typography>
                          {participant.is_ghost && (
                            <Chip
                              size="small"
                              color="secondary"
                              label={translation.ghost || "Ghost"}
                            />
                          )}
                        </Box>
                        <Typography variant="caption" color="text.secondary">
                          {translation.sector || "Sector"} {participant.sector_number}
                        </Typography>
                      </Box>
                    </Box>

                    {/* Points Info */}
                    <Box sx={{
                      textAlign: isMobile ? 'left' : 'right',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: isMobile ? 'space-between' : 'flex-end',
                      width: isMobile ? '100%' : 'auto',
                      ml: isMobile ? 10 : 0
                    }}>
                      <Box sx={{ mr: 4 }}>
                        <Typography variant="caption" color="text.secondary">
                          {translation.total_points || "Total Points"}
                        </Typography>
                        <Typography variant="h6" color="primary" sx={{ fontWeight: 'bold' }}>
                          {totalPoints}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography variant="caption" color="text.secondary">
                          {translation.total_fish || "Total Fish"}
                        </Typography>
                        <Typography variant="body1" sx={{ fontWeight: 'medium' }}>
                          {participant.total_fish || 0}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>

                  {/* Stats Summary - Always Visible */}
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      mt: 2,
                      p: 1.5,
                      bgcolor: 'background.paper',
                      borderRadius: 1,
                      border: '1px solid',
                      borderColor: 'divider'
                    }}
                  >
                    <Box sx={{ textAlign: 'center' }}>
                      <Typography variant="caption" color="text.secondary">
                        {translation.total_fish || "Total Fish"}
                      </Typography>
                      <Typography variant="body2" sx={{ fontWeight: 'medium' }}>
                        {participant.total_fish || 0}
                      </Typography>
                    </Box>
                    <Box sx={{ textAlign: 'center' }}>
                      <Typography variant="caption" color="text.secondary">
                        {translation.total_points || "Total Points"}
                      </Typography>
                      <Typography variant="body2" sx={{ fontWeight: 'medium' }}>
                        {totalPoints}
                      </Typography>
                    </Box>
                    <Box sx={{ textAlign: 'center' }}>
                      <Typography variant="caption" color="text.secondary">
                        {translation.rank || "Rank"}
                      </Typography>
                      <Typography variant="body2" sx={{ fontWeight: 'medium' }}>
                        {index + 1}
                      </Typography>
                    </Box>
                  </Box>

                  {/* Expand/Collapse Indicator */}
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      mt: 1,
                      cursor: 'pointer'
                    }}
                    onClick={() => setExpandedUser(isExpanded ? null : participant.user_id)}
                  >
                    {isExpanded ? <ChevronUp size={18} /> : <ChevronDown size={18} />}
                  </Box>

                  {/* Expanded Leg Details */}
                  {isExpanded && participant.legs && (
                    <Box
                      sx={{
                        mt: 2,
                        pt: 2,
                        borderTop: '1px solid',
                        borderColor: 'divider'
                      }}
                    >
                      <Typography variant="subtitle2" sx={{ mb: 1 }}>
                        {translation.leg_details || "Leg Details"}:
                      </Typography>

                      {participant.legs.map((leg) => {
                        // Safely format numbers with toFixed, handling potential undefined values
                        const legPoints = leg.leg_points != null ?
                          typeof leg.leg_points === 'number' ?
                            leg.leg_points.toFixed(1) : leg.leg_points : "0.0";

                        return (
                          <Box
                            key={leg.leg_number}
                            sx={{
                              mb: 1.5,
                              p: 1.5,
                              bgcolor: 'background.paper',
                              borderRadius: 1,
                              border: '1px solid',
                              borderColor: 'divider'
                            }}
                          >
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
                              <Typography variant="subtitle2">
                                {translation.leg || "Leg"} {leg.leg_number}
                              </Typography>
                              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Typography variant="body2" sx={{ fontWeight: 'medium', mr: 0.5 }}>
                                  {legPoints}
                                </Typography>
                                <Typography variant="caption" color="text.secondary">
                                  {translation.points || "points"}
                                </Typography>
                              </Box>
                            </Box>

                            <Box sx={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                              <Box sx={{ display: 'flex', alignItems: 'center', mr: 2, mb: isMobile ? 1 : 0 }}>
                                <Typography variant="caption" color="text.secondary" sx={{ mr: 0.5 }}>
                                  {translation.position || "Position"}:
                                </Typography>
                                <Typography variant="body2" sx={{ fontWeight: 'medium' }}>
                                  {leg.position_value}
                                </Typography>
                              </Box>

                              <Box sx={{ display: 'flex', alignItems: 'center', mr: 2, mb: isMobile ? 1 : 0 }}>
                                <Typography variant="caption" color="text.secondary" sx={{ mr: 0.5 }}>
                                  {translation.fish_caught || "Fish"}:
                                </Typography>
                                <Typography variant="body2" sx={{ fontWeight: 'medium' }}>
                                  {leg.fish_caught || 0}
                                </Typography>
                              </Box>

                              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Typography variant="caption" color="text.secondary" sx={{ mr: 0.5 }}>
                                  {translation.seat || "Seat"}:
                                </Typography>
                                <Typography variant="body2" sx={{ fontWeight: 'medium' }}>
                                  {leg.seat_index}
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                        );
                      })}
                    </Box>
                  )}
                </Paper>
              );
            })
          ) : (
            <Alert severity="info">
              {translation.no_ranking_data_for_group || "No ranking data available for this group."}
            </Alert>
          )}
        </CardContent>
      </Card>
    </Box>
  );
};

export default GroupRanking;
