import { useEffect, useState } from "react"
import { useSelector, useDispatch, connect } from "react-redux"
import Input from "../components/Input"
import Dropdown from "../components/Dropdown"
import Checkbox from "../components/Checkbox"
import { updateUserProfile } from "../actions/userState"
import { load_countries, load_cities_by_country } from "../actions/utilsState"
import { loadUser } from "../actions/auth"

const phoneRegex = /^\+[1-9]{1,3}\d{6,10}$/

const initUserProfileForm = {
  first_name: "",
  last_name: "",
  country: "",
  city: "",
  gender: "",
  phone_number: "",
  receive_new_event_notification: false,
}

const UserProfileEditForm = ({ onClose, translation }) => {
  const dispatch = useDispatch()
  const { user } = useSelector((state) => state.auth)
  const { countries = [], cities = {} } = useSelector((state) => state.utilsState || {})

  const [formState, setFormState] = useState(initUserProfileForm)
  const [errors, setErrors] = useState({ phone_number: "" })
  const [isLoadingCities, setIsLoadingCities] = useState(false)
  const [isInitialized, setIsInitialized] = useState(false)

  // Define gender options after ensuring translation is available
  const GENDER_OPTIONS = translation
    ? [
        { id: "", name: translation.up_select_gender || "Select Gender" },
        { id: "m", name: translation.up_male || "Male" },
        { id: "f", name: translation.up_female || "Female" },
        { id: "o", name: translation.up_other || "Other" },
      ]
    : []

  // Load countries on mount
  useEffect(() => {
    const initializeData = async () => {
      if (!isInitialized) {
        await dispatch(load_countries())
        setIsInitialized(true)
      }
    }
    initializeData()
  }, [dispatch, isInitialized])

  // Initialize form data when user and countries are available
  useEffect(() => {
    if (user && !formState.first_name && countries.length > 0) {
      const updatedFormState = {
        first_name: user.account?.first_name || "",
        last_name: user.account?.last_name || "",
        country: user.country?.id?.toString() || "",
        city: user.city?.id?.toString() || "",
        gender: user.gender || "",
        phone_number: user.phone_number || "",
        receive_new_event_notification: user.receive_new_event_notification || false,
      }
      setFormState(updatedFormState)

      // Load cities for user's country if set
      if (user.country?.id && !cities[user.country.id]) {
        dispatch(load_cities_by_country(user.country.id))
      }
    }
  }, [dispatch, user, countries, cities, formState.first_name])

  // Handle country change and load corresponding cities
  const handleCountryChange = async (e) => {
    const countryId = e.target.value
    setFormState((prev) => ({
      ...prev,
      country: countryId,
      city: "", // Reset city when country changes
    }))

    if (countryId) {
      setIsLoadingCities(true)
      try {
        // Make sure we're passing the countryId as a number
        const numericCountryId = Number.parseInt(countryId, 10)
        await dispatch(load_cities_by_country(numericCountryId))
      } catch (error) {
        console.error("Failed to load cities:", error)
      } finally {
        setIsLoadingCities(false)
      }
    }
  }

  const handleInputChange = (e) => {
    const { name, type, value, checked } = e.target
    setFormState((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }))

    if (errors[name]) {
      setErrors((prev) => ({
        ...prev,
        [name]: "",
      }))
    }
  }

  const validateForm = () => {
    let isValid = true
    const newErrors = {}

    if (!phoneRegex.test(formState.phone_number)) {
      isValid = false
      newErrors.phone_number = translation?.up_invalid_phone || "Invalid phone number"
    }
    setErrors(newErrors)
    return isValid
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (validateForm()) {
      const userData = {
        account: {
          first_name: formState.first_name,
          last_name: formState.last_name,
        },
        gender: formState.gender,
        country_id: Number.parseInt(formState.country),
        city_id: Number.parseInt(formState.city),
        phone_number: formState.phone_number,
        receive_new_event_notification: formState.receive_new_event_notification,
      }

      try {
        await dispatch(updateUserProfile(userData))
        await dispatch(loadUser())
        onClose()
      } catch (error) {
        console.error("Failed to update user profile:", error)
      }
    }
  }

  const countryOptions = [
    { id: "", name: translation?.up_select_country || "Select Country" },
    ...(Array.isArray(countries)
      ? countries.map((country) => ({
          id: country.id.toString(),
          name: country.country_name,
        }))
      : []),
  ]

  // Update city options to directly use the cities data
  const cityOptions = [
    { id: "", name: translation?.up_select_city || "Select City" },
    ...(formState.country && cities[formState.country] && Array.isArray(cities[formState.country])
      ? cities[formState.country].map((city) => ({
          id: city.id.toString(),
          name: city.city_name,
        }))
      : []),
  ]

  if (!translation) {
    return null
  }

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <Input
        name="first_name"
        label={translation.first_name || "First Name"}
        value={formState.first_name}
        onChange={handleInputChange}
      />
      <Input
        name="last_name"
        label={translation.last_name || "Last Name"}
        value={formState.last_name}
        onChange={handleInputChange}
      />
      <Dropdown
        name="country"
        label={translation.country || "Country"}
        options={countryOptions}
        selectedOption={countryOptions.find((option) => option.id === formState.country)}
        onSelect={handleCountryChange}
        formAttr="country"
      />
      <Dropdown
        name="city"
        label={translation.city || "City"}
        options={cityOptions}
        selectedOption={cityOptions.find((option) => option.id === formState.city)}
        onSelect={handleInputChange}
        formAttr="city"
        disabled={!formState.country || isLoadingCities}
      />
      <Dropdown
        name="gender"
        label={translation.gender || "Gender"}
        options={GENDER_OPTIONS}
        selectedOption={GENDER_OPTIONS.find((option) => option.id === formState.gender)}
        onSelect={handleInputChange}
        formAttr="gender"
      />
      <Input
        name="phone_number"
        label={translation.phone_number || "Phone Number"}
        placeholder="+40721777999"
        type="tel"
        pattern="\+[1-9]{1,3}\d{6,10}"
        title={translation?.up_phone_format || "Enter a valid phone number"}
        value={formState.phone_number}
        onChange={handleInputChange}
      />
      {errors.phone_number && <div className="text-red-500 text-sm">{errors.phone_number}</div>}
      <Checkbox
        name="receive_new_event_notification"
        label={translation?.up_new_event_notification || "Receive new event notifications"}
        checked={formState.receive_new_event_notification}
        onChange={handleInputChange}
      />
      <button type="submit" className="btn btn-light-blue float-end">
        {translation?.up_save_changes || "Save Changes"}
      </button>
    </form>
  )
}

// Map state to props for translations
const mapStateToProps = (state) => ({
  translation: state.appState.translation,
})

// Connect the component to Redux
export default connect(mapStateToProps)(UserProfileEditForm)

