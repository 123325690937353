import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import { Tabs, Tab, Button, Container, Paper, Typography, Box, Autocomplete, TextField } from "@mui/material"
import { Refresh } from "@mui/icons-material"
import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import Loader from "../components/Loader"
import MatchCheckStatus from "../components/MatchCheckStatus"
import MatchValidateTable from "../components/MatchValidateTable"
import TaScoringTable from "../components/TaScoringTable"
import { loadEvent, loadEnrollments } from "../actions/eventsState"
import { fetchUnvalidatedMatches } from "../actions/taActions"
import { loadTaScoring } from "../actions/eventsState"

const EventTaValidate = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { eventId } = useParams()
  const [selectedTab, setSelectedTab] = useState(0)
  const [selectedUser, setSelectedUser] = useState(null)

  const event = useSelector((state) => state.eventState.event)
  const isLoading = useSelector((state) => state.myMapCatchDataReducer.loading)
  const enrollments = useSelector((state) => state.eventState.enrollments)
  const taScoring = useSelector((state) => state.eventState.taScoring)

  useEffect(() => {
    if (!eventId) {
      navigate("/")
      return
    }
    dispatch(fetchUnvalidatedMatches(eventId))
    dispatch(loadEvent(eventId))
    dispatch(loadEnrollments(eventId))
    dispatch(loadTaScoring(eventId))
  }, [dispatch, eventId, navigate])

  useEffect(() => {
    if (event && !event.is_owner && !event.is_validator) {
      navigate("/")
    }
  }, [event, navigate])

  const refreshList = () => {
    if (eventId) {
      dispatch(fetchUnvalidatedMatches(eventId))
      dispatch(loadEnrollments(eventId))
      dispatch(loadTaScoring(eventId))
      toast.info("Data refreshed")
    }
  }

  const handleUserChange = (_, newValue) => {
    setSelectedUser(newValue)
  }

  if (isLoading) return <Loader />

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
        <Typography component="h2" variant="h6" color="primary" gutterBottom>
          Event TA Validation
        </Typography>
        <Tabs
          value={selectedTab}
          onChange={(_, value) => setSelectedTab(value)}
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab label="Verifica Status Validari" />
          <Tab label="Revalidare" />
          <Tab label="Clasament" />
        </Tabs>
        <Box sx={{ mt: 2 }}>
          {selectedTab === 0 && (
            <>
              <Button variant="outlined" color="primary" startIcon={<Refresh />} onClick={refreshList} sx={{ mb: 2 }}>
                Refresh
              </Button>
              <MatchCheckStatus eventId={eventId} />
            </>
          )}
          {selectedTab === 1 && (
            <>
              <Autocomplete
                options={enrollments || []}
                getOptionLabel={(option) => `${option.user_full_name} (${option.user})`}
                renderInput={(params) => <TextField {...params} label="Select User" />}
                onChange={handleUserChange}
                value={selectedUser}
                sx={{ mb: 2 }}
              />
              {selectedUser && (
                <MatchValidateTable
                  key={selectedUser.user}
                  userId={selectedUser.user}
                  eventId={eventId}
                  refreshList={refreshList}
                />
              )}
            </>
          )}
          {selectedTab === 2 && (
            <>
              <Button variant="outlined" color="primary" startIcon={<Refresh />} onClick={refreshList} sx={{ mb: 2 }}>
                Refresh
              </Button>
              {taScoring ? (
                <TaScoringTable scoring={taScoring} event={event} />
              ) : (
                <Typography>Loading TA Scoring data...</Typography>
              )}
            </>
          )}
        </Box>
      </Paper>
      <ToastContainer position="bottom-right" autoClose={3000} />
    </Container>
  )
}

export default React.memo(EventTaValidate)

