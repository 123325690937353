import axios from 'axios';

import {
    LOAD_SPONSORS,
    USER_LOADED_FAIL,
    LOAD_SPONSORS_FAILED,
    LOAD_FISH_TYPES,
    LOAD_FISH_TYPES_FAILED,
    LOAD_LOCATIONS,
    LOAD_LOCATIONS_FAIL,
    LOAD_COUNTRIES,
    LOAD_COUNTRIES_FAIL,
    LOAD_CITIES,
    LOAD_CITIES_FAIL,
    TEST_PHOTO_UPLOAD_SUCCESS,
    TEST_PHOTO_UPLOAD_FAIL,
    EVENT_ENROLL_SUCCESS,
    EVENT_ENROLL_FAIL,
    EVENT_ONGOING,
    EVENT_ONGOING_FAIL,
    LOAD_EVENT_VALIDATORS,
    LOAD_EVENT_VALIDATORS_FAIL,
    LOAD_FISHING_SPOTS,
    LOAD_FISHING_SPOTS_FAIL,
    LOAD_PERFORMANCE,
    LOAD_PERFORMANCE_FAIL,
    LOAD_TOP_ANGLERS,
    LOAD_TOP_ANGLERS_FAIL,
    LOAD_SCORING_TYPES,
    LOAD_SCORING_TYPES_FAIL,

} from './types';
import getAxiosConfig from "../utils/axiosConfig";
import message from "../components/Message";

export const load_sponsors = () => async dispatch => {

    if (localStorage.getItem('access')) {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`,
                'Accept': 'application/json'
            }
        };

        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/v1/sponsors/sponsors/`, config);

            dispatch({
                type: LOAD_SPONSORS,
                payload: res.data
            });
        } catch (err) {
            dispatch({
                type: LOAD_SPONSORS_FAILED
            });

            if(err.request.status === 401) {
                dispatch({
                    type: USER_LOADED_FAIL
                });
            }

        }
    } else {
        dispatch({
            type: LOAD_SPONSORS_FAILED
        });
    }
};

export const load_fish_types = () => async dispatch => {

    if (localStorage.getItem('access')) {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`,
                'Accept': 'application/json'
            }
        };

        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/v1/fish/fishtypes/`, config);

            dispatch({
                type: LOAD_FISH_TYPES,
                payload: res.data.events
            });
        } catch (err) {
            dispatch({
                type: LOAD_FISH_TYPES_FAILED
            });

            if(err.request.status === 401) {
                dispatch({
                    type: USER_LOADED_FAIL
                });
            }

        }
    } else {
        dispatch({
            type: LOAD_SPONSORS_FAILED
        });
    }
};



export const load_location = () => async dispatch => {

    if (localStorage.getItem('access')) {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`,
                'Accept': 'application/json'
            }
        };

        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/v1/locations/`, config);
            dispatch({
                type: LOAD_LOCATIONS,
                payload: res.data[0]
            });
            console.log(res.data);
        } catch (err) {
            dispatch({
                type: LOAD_LOCATIONS_FAIL
            });

            if(err.request.status === 401) {
                dispatch({
                    type: LOAD_LOCATIONS_FAIL
                });
            }

        }
    } else {
        dispatch({
            type: LOAD_LOCATIONS_FAIL
        });
    }
};

export const load_countries = () => async (dispatch) => {
  const config = getAxiosConfig()
  if (!config) {
    dispatch({ type: LOAD_COUNTRIES_FAIL })
    return
  }

  try {
    const res = await axios.get(`${process.env.REACT_APP_API_URL}/v1/locations/countries/`, config)
    dispatch({
      type: LOAD_COUNTRIES,
      payload: res.data,
    })
  } catch (err) {
    dispatch({ type: LOAD_COUNTRIES_FAIL })
  }
}

export const load_cities_by_country = (countryId) => async (dispatch) => {
  const config = getAxiosConfig()
  if (!config) {
    dispatch({ type: LOAD_CITIES_FAIL })
    return
  }

  try {
    const res = await axios.get(`${process.env.REACT_APP_API_URL}/v1/locations/countries/${countryId}/cities/`, config)
    dispatch({
      type: LOAD_CITIES,
      payload: {
        countryId,
        cities: res.data,
      },
    })
  } catch (err) {
    dispatch({ type: LOAD_CITIES_FAIL })
  }
}

export const photoTestUpload = (photo) => async dispatch => {
    if (localStorage.getItem('access')) {
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data', // Important for file upload
                'Authorization': `JWT ${localStorage.getItem('access')}`,
            }
        };

        const formData = new FormData();
        formData.append('photo', photo);

        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/v2/event/check-device-settings/`, formData, config);

            dispatch({
                type: TEST_PHOTO_UPLOAD_SUCCESS,
                payload: res.data
            });

            console.log('Upload success:', res.data);
        } catch (err) {
            console.error('Error uploading photo:', err);
            dispatch({
                type: TEST_PHOTO_UPLOAD_FAIL
            });
        }
    } else {
        dispatch({
            type: TEST_PHOTO_UPLOAD_FAIL
        });
    }
};


export const enrollEvent = (eventId) => async (dispatch) => {
    try {
        const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/v2/event/enrolments/enroll/`,
            { event: eventId },
            getAxiosConfig()
        );

        dispatch({
            type: EVENT_ENROLL_SUCCESS,
            payload: response.data
        });

        console.log('Successfully enrolled:', response.data);
    } catch (err) {
        console.error('Failed to enroll:', err);
        dispatch({
            type: EVENT_ENROLL_FAIL,
            payload: message
        });
    }
};

export const load_ongoing = (eventId) => async (dispatch) => {
    try {
        const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/v2/event/ongoing/`,
            getAxiosConfig()
        );
        if(response.data.length === 0) {
            dispatch({
                type: EVENT_ONGOING_FAIL,
                payload: null
            });
        } else {
            dispatch({
                type: EVENT_ONGOING,
                payload: response.data[0]
            });
        }
    } catch (err) {

        dispatch({
            type: EVENT_ONGOING_FAIL,
            payload: message
        });
    }
};

export const load_validators = () => async (dispatch) => {
    try {
        const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/v2/club/owner/validators/`,
            getAxiosConfig()
        );
        if(response.data.length === 0) {
            dispatch({
                type: LOAD_EVENT_VALIDATORS_FAIL,
                payload: null
            });
        } else {
            dispatch({
                type: LOAD_EVENT_VALIDATORS,
                payload: response.data
            });
        }
    } catch (err) {

        dispatch({
            type: LOAD_EVENT_VALIDATORS_FAIL,
            payload: message
        });
    }
};

// export const load_fishingspots = () => async (dispatch) => {
//     try {
//         const response = await axios.get(
//             `${process.env.REACT_APP_API_URL}/v1/locations/fishingspots/`,
//             getAxiosConfig()
//         );
//         if(response.data.length === 0) {
//             dispatch({
//                 type: LOAD_FISHING_SPOTS_FAIL,
//                 payload: null
//             });
//         } else {
//             dispatch({
//                 type: LOAD_FISHING_SPOTS,
//                 payload: response.data
//             });
//         }
//     } catch (err) {
//
//         dispatch({
//             type: LOAD_FISHING_SPOTS_FAIL,
//             payload: message
//         });
//     }
// };

export const load_performance = () => async (dispatch) => {
    try {
        const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/v1/dashboard/performance/`,
            getAxiosConfig()
        );
        dispatch({
            type: LOAD_PERFORMANCE,
            payload: response.data
        });
    } catch (err) {

        dispatch({
            type: LOAD_PERFORMANCE_FAIL,
            payload: message
        });
    }
};


export const load_topanglers = () => async (dispatch) => {
    try {
        const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/v1/dashboard/top-anglers/`,
            getAxiosConfig()
        );
        dispatch({
            type: LOAD_TOP_ANGLERS,
            payload: response.data
        });
    } catch (err) {

        dispatch({
            type: LOAD_TOP_ANGLERS_FAIL,
            payload: message
        });
    }
};


export const load_scoring_types = () => async (dispatch) => {
  try {
    const res = await axios.get(`${process.env.REACT_APP_API_URL}/v2/event/scoring-types/`, getAxiosConfig())
    dispatch({
      type: LOAD_SCORING_TYPES,
      payload: res.data,
    })
  } catch (err) {
    dispatch({
      type: LOAD_SCORING_TYPES_FAIL,
    })

    if (err.response && err.response.status === 401) {
      dispatch({
        type: USER_LOADED_FAIL,
      })
    }
  }
}

export const load_fishing_spots_by_city = (cityId) => async (dispatch) => {
  const config = getAxiosConfig()
  if (!config) {
    dispatch({ type: LOAD_FISHING_SPOTS_FAIL })
    return
  }

  try {
    const res = await axios.get(`${process.env.REACT_APP_API_URL}/v1/locations/cities/${cityId}/fishingspots/`, config)
    dispatch({
      type: LOAD_FISHING_SPOTS,
      payload: {
        cityId,
        fishingSpots: res.data,
      },
    })
  } catch (err) {
    dispatch({ type: LOAD_FISHING_SPOTS_FAIL })
  }
}
