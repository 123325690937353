import { connect } from "react-redux"
import { logout } from "../actions/auth"
import { useNavigate, useLocation } from "react-router-dom"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import IconButton from "@mui/material/IconButton"
import HomeIcon from "@mui/icons-material/Home"
import EventIcon from "@mui/icons-material/Event"
import GroupIcon from "@mui/icons-material/Group"
import ContactMailIcon from "@mui/icons-material/ContactMail"
import LogoutIcon from "@mui/icons-material/Logout"
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings"
import HowToRegIcon from '@mui/icons-material/HowToReg';
import Divider from "@mui/material/Divider"
import { useMediaQuery, Drawer, Backdrop } from "@mui/material"
import DrawIcon from '@mui/icons-material/Draw';

const Menu = ({ menuOpen, logout, menuRef, user, translation, closeMenu }) => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const isMobile = useMediaQuery("(max-width:600px)")

  const handleNavigation = (path) => {
    navigate(path)
    closeMenu()
  }

  const handleLogout = () => {
    logout()
    closeMenu()
  }

  const menuContent = (
    <Box
      ref={menuRef}
      sx={{
        width: "15rem",
        height: "100%",
        backgroundColor: "var(--blue1)",
        display: "flex",
        flexDirection: "column",
        px: 3,
        py: 3,
        overflowY: "auto",
      }}
    >
      <Box sx={{ textAlign: "center", mb: 3 }}>
        <Box
          component="img"
          src="https://streetfishing.fra1.cdn.digitaloceanspaces.com/media/assets/logo_orange_yellow_transparent.png"
          alt="logo"
          sx={{ maxWidth: "100%", px: 5 }}
        />
      </Box>

      <Box sx={{ flexGrow: 1 }}>
        <MenuItem
          icon={<HomeIcon />}
          label={translation.main_home}
          selected={pathname === "/"}
          onClick={() => handleNavigation("/")}
        />

        <MenuItem
          icon={<EventIcon />}
          label={translation.main_events}
          selected={pathname === "/events"}
          onClick={() => handleNavigation("/events")}
        />

        <MenuItem
          icon={<GroupIcon />}
          label="Clubs"
          selected={pathname === "/clubs"}
          onClick={() => handleNavigation("/clubs")}
        />

        <MenuItem
          icon={<ContactMailIcon />}
          label="Contact"
          selected={pathname === "/contact"}
          onClick={() => handleNavigation("/contact")}
        />

        {user && (user.is_administrator || user.is_organiser || user.is_validator) && (
          <>
            <Divider sx={{ my: 2, borderColor: "rgba(255, 255, 255, 0.12)" }} />
            {user.is_administrator && (
              <MenuItem
                icon={<AdminPanelSettingsIcon />}
                label="Admin"
                selected={pathname === "/admin"}
                onClick={() => handleNavigation("/admin")}
              />
            )}
            {user.is_organiser && (
              <MenuItem
                icon={<DrawIcon />}
                label={translation.organizer_dashboard}
                selected={pathname === "/organizer-dashboard"}
                onClick={() => handleNavigation("/organizer-dashboard")}
              />
            )}
            {user.is_validator && (
              <MenuItem
                icon={<HowToRegIcon />}
                label={translation.validator_dashboard}
                selected={pathname === "/validator-dashboard"}
                onClick={() => handleNavigation("/validator-dashboard")}
              />
            )}
          </>
        )}
      </Box>

      <Box sx={{ mt: 1 , mb:5 }}>
        <Divider sx={{ mb: 1, borderColor: "rgba(255, 255, 255, 0.12)" }} />
        <MenuItem icon={<LogoutIcon />} label={translation.main_logout} onClick={handleLogout} />
        <Divider sx={{ mb: 1, borderColor: "rgba(255, 255, 255, 0.12)" }} />
      </Box>
    </Box>
  )

  if (isMobile) {
    return (
      <>
        <Backdrop open={menuOpen} onClick={closeMenu} sx={{ zIndex: 1300 }} />
        <Drawer
          variant="temporary"
          anchor="left"
          open={menuOpen}
          onClose={closeMenu}
          sx={{
            "& .MuiDrawer-paper": {
              width: "14rem",
              marginTop: "4rem",
              height: "calc(100% - 4rem)",
              backgroundColor: "var(--blue1)",
              border: "none",
            },
            zIndex: 1400,
          }}
          ModalProps={{
            keepMounted: true,
          }}
        >
          {menuContent}
        </Drawer>
      </>
    )
  }

  return (
    <Box
      sx={{
        width: "15rem",
        height: "calc(100vh - 4rem)",
        backgroundColor: "var(--blue1)",
        position: "fixed",
        top: "4rem",
        left: menuOpen ? 0 : "-15rem",
        boxShadow: "0 2px 1px var(--shadow1)",
        transition: "left 0.3s ease",
        zIndex: 1200,
      }}
    >
      {menuContent}
    </Box>
  )
}

const MenuItem = ({ icon, label, selected, onClick }) => (
  <Box
    sx={{
      display: "flex",
      alignItems: "center",
      gap: 2,
      py: 1,
      px: 2,
      backgroundColor: selected ? "var(--galben1)" : "transparent",
      color: selected ? "var(--blue1)" : "var(--white)",
      cursor: "pointer",
      borderRadius: "8px",
      "&:hover": {
        backgroundColor: "var(--galben1)",
        color: "var(--blue1)",
      },
    }}
    onClick={onClick}
  >
    <IconButton
      color="inherit"
      size="small"
      sx={{
        color: "inherit",
        "&:hover": {
          backgroundColor: "transparent",
        },
      }}
    >
      {icon}
    </IconButton>
    <Typography variant="body1" sx={{ color: "inherit" }}>
      {label}
    </Typography>
  </Box>
)
const mapStateToProps = (state) => ({
  menuOpen: state.appState.menuOpen,
  user: state.auth.user,
  translation: state.appState.translation,
})

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => {
      dispatch(logout())
    },
    closeMenu: () => {
      dispatch({ type: "CLOSE_MENU" })
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Menu)

