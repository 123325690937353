import React, { useCallback, useEffect, useState } from "react"
import { connect } from "react-redux"
import { loadEvents, selectEvent } from "../actions/eventsState"
import { useNavigate } from "react-router-dom"
import Loader from "../components/Loader"
import UtilitiesEvents from "../components/UtilitiesEvents"
import EventCard from "../components/EventCard"
import Empty from "../components/Empty"
import Pagination from "../components/Pagination"

const Events = ({ events, eventsLoading, loadEvents, translation, selectEvent, pagination }) => {
  const navigate = useNavigate()
  const [enroll, setEnroll] = useState(false)
  const [searchQuery, setSearchQuery] = useState("")
  const [currentPage, setCurrentPage] = useState(1)

  useEffect(() => {
    loadEvents(enroll, currentPage)
  }, [loadEvents, enroll, currentPage])

  const handleSelectedEvent = useCallback(
    (event) => {
      navigate(`/event/${event.id}`)
    },
    [navigate],
  )

  const filteredEvents = events.filter((event) => event.event_name.toLowerCase().includes(searchQuery.toLowerCase()))

  const renderEvents = useCallback(() => {
    if (searchQuery.length < 3) {
      return events.map((item) => <EventCard key={item.id} event={item} />)
    }

    return filteredEvents.map((item) => <EventCard key={item.id} event={item} />)
  }, [filteredEvents, events, searchQuery])

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage)
  }

  return (
    <>
      <div className={`page-container container-fluid`}>
        <div className="row g-3">
          <UtilitiesEvents
            onSearch={setSearchQuery}
            translation={translation}
            refreshList={() => {
              setEnroll(!enroll)
              setCurrentPage(1)
            }}
          />
          {!eventsLoading ? (
            events?.length < 1 || (searchQuery.length > 3 && filteredEvents?.length < 1) ? (
              <Empty label={`Nu exista evenimente.`} />
            ) : (
              <>
                {renderEvents()}
                <Pagination
                  currentPage={currentPage}
                  totalPages={Math.ceil(pagination.count / 10)} // Assuming 10 items per page
                  onPageChange={handlePageChange}
                />
              </>
            )
          ) : (
            <Loader />
          )}
        </div>
      </div>
    </>
  )
}

const mapStateToProps = (state) => ({
  menuOpen: state.appState.menuOpen,
  events: state.eventsState.events,
  eventsLoading: state.eventsState.eventsLoading,
  translation: state.appState.translation,
  pagination: state.eventsState.pagination,
})

const mapDispatchToProps = (dispatch) => {
  return {
    loadEvents: (enroll, page) => {
      dispatch(loadEvents(enroll, page))
    },
    selectEvent: (event) => {
      dispatch(selectEvent(event))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(Events))

