import { useCallback, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { connect } from "react-redux"
import { verify } from "../actions/auth"
import { Container, Box, Typography, Button, Paper, CircularProgress } from "@mui/material"

const Activate = ({ verify }) => {
  const [verified, setVerified] = useState(false)
  const [isVerifying, setIsVerifying] = useState(false)
  const navigate = useNavigate()
  const { uid, token } = useParams()

  useEffect(() => {
    if (verified) {
      const timer = setTimeout(() => {
        navigate("/")
      }, 3000) // Redirect after 3 seconds
      return () => clearTimeout(timer)
    }
  }, [verified, navigate])

  const verify_account = useCallback(() => {
    if (uid && token) {
      setIsVerifying(true)
      verify(uid, token)
        .then(() => {
          setVerified(true)
          setIsVerifying(false)
        })
        .catch((error) => {
          console.error("Verification failed:", error)
          setIsVerifying(false)
        })
    }
  }, [uid, token, verify])

  return (
    <Container maxWidth="sm">
      <Box sx={{ mt: 8, mb: 4 }}>
        <Paper elevation={3} sx={{ p: 4, borderRadius: 2 }}>
          <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <Typography
              variant="h4"
              component="h1"
              gutterBottom
              align="center"
              sx={{ fontWeight: "bold", color: "primary.main", mb: 4 }}
            >
              Account Activation
            </Typography>
            {!verified ? (
              <>
                <Typography variant="body1" align="center" sx={{ mb: 4 }}>
                  Click the button below to finish activation.
                </Typography>
                <Button
                  onClick={verify_account}
                  variant="contained"
                  color="primary"
                  size="large"
                  disabled={isVerifying}
                  sx={{
                    py: 1.5,
                    px: 4,
                    fontSize: "1.1rem",
                    fontWeight: "bold",
                    backgroundColor: "green",
                    "&:hover": {
                      backgroundColor: "darkgreen",
                    },
                  }}
                >
                  {isVerifying ? <CircularProgress size={24} color="inherit" /> : "Verify your account"}
                </Button>
              </>
            ) : (
              <Typography variant="body1" align="center" sx={{ color: "success.main" }}>
                Your account has been successfully verified. Redirecting to home page...
              </Typography>
            )}
          </Box>
        </Paper>
      </Box>
    </Container>
  )
}

export default connect(null, { verify })(Activate)

