import { useState } from "react"
import { Box, Button, TextField, Typography } from "@mui/material"

const ClubInviteMemberForm = ({ onInvite, onClose }) => {
  const [email, setEmail] = useState("")

  const handleSubmit = (e) => {
    e.preventDefault()
    onInvite(email)
    setEmail("")
  }

  return (
    <Box component="form" onSubmit={handleSubmit}>
      <Typography variant="h6" gutterBottom>
        Invite New Member
      </Typography>
      <TextField
        fullWidth
        label="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        margin="normal"
        required
        type="email"
      />
      <Box mt={2} display="flex" justifyContent="flex-end">
        <Button onClick={onClose} sx={{ mr: 1 }}>
          Cancel
        </Button>
        <Button type="submit" variant="contained" color="primary">
          Invite
        </Button>
      </Box>
    </Box>
  )
}

export default ClubInviteMemberForm

