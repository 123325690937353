import { useEffect, useState } from "react"
import axios from "axios"
import { connect } from "react-redux"
import { toast } from "react-toastify"
import {
  Container,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  CircularProgress,
} from "@mui/material"
import { styled } from "@mui/material/styles"
import getAxiosConfig from "../utils/axiosConfig"
import Loader from "../components/Loader"

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  height: "100%",
}))

const YellowTableCell = styled(TableCell)(() => ({
  backgroundColor: "#ffc107",
  color: "white",
  fontWeight: "bold",
}))

const EventTaStats = ({ eventId, translation, user }) => {
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (!eventId) return

    const fetchEventTaSummary = async () => {
      setLoading(true)
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/v3/event/${eventId}/ta-summary/`,
          getAxiosConfig(),
        )
        setData(response.data)
      } catch (error) {
        console.error("Error fetching event TA summary", error)
        toast.error(translation.fetch_error)
      } finally {
        setLoading(false)
      }
    }
    fetchEventTaSummary()
  }, [eventId, translation])

  if (loading) {
    return (
      <Container sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "50vh" }}>
        <CircularProgress />
      </Container>
    )
  }

  //   if (loading) {
  //   return (
  //     <Container>
  //       <Loader />
  //     </Container>
  //   )
  // }

  if (!data) {
    return (
      <Container>
        <div className="galben text-white p-2 mb-3">{translation.no_stats_available}</div>
      </Container>
    )
  }

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <div className="galben text-white p-2 mb-3">{translation.event_ta_statistics}</div>

      <Grid container spacing={3}>
         {/* Event Stats */}
        <Grid item xs={12}>
          <StyledPaper>
            <div className="galben text-white p-2 mb-3">{translation.event_stats}</div>
            <Table>
              <TableBody>
                {/*<TableRow>*/}
                {/*  <TableCell>{translation.id}</TableCell>*/}
                {/*  <TableCell align="right">{eventId}</TableCell>*/}
                {/*</TableRow>*/}
                <TableRow>
                  <TableCell>{translation.competitors}</TableCell>
                  <TableCell align="right">{data.event_stats.competitors}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{translation.total_catches}</TableCell>
                  <TableCell align="right">{data.event_stats.total_catches}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{translation.matches}</TableCell>
                  <TableCell align="right">{data.event_stats.matches}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{translation.catches_per_competitor}</TableCell>
                  <TableCell align="right">{data.event_stats.catches_per_competitor.toFixed(2)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{translation.catches_per_match}</TableCell>
                  <TableCell align="right">{data.event_stats.catches_per_match.toFixed(2)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{translation.catches_per_match_per_competitor}</TableCell>
                  <TableCell align="right">{data.event_stats.catches_per_match_per_competitor.toFixed(2)}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </StyledPaper>
        </Grid>
        {/* Basic TA Statistics */}
        <Grid item xs={12} md={6}>
          <StyledPaper>
            <div className="galben text-white p-2 mb-3">{translation.basic_ta_statistics}</div>
            <Table>
              <TableBody>
                {[
                  { label: translation.total_catches, value: data.total_catches },
                  {
                    label: translation.average_catches_per_minute,
                    value: data.average_catches_per_minute.toFixed(2),
                  },
                ].map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>{item.label}</TableCell>
                    <TableCell align="right">{item.value}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </StyledPaper>
        </Grid>

        {/* Best Average User */}
        <Grid item xs={12} md={6}>
          <StyledPaper>
            <div className="galben text-white p-2 mb-3">{translation.best_average_user}</div>
            <Table>
              <TableBody>
                {[
                  { label: translation.user_name, value: data.best_avg_user.user_name },
                  // { label: translation.user_id, value: data.best_avg_user.user_id },
                  {
                    label: translation.average_catches_per_match,
                    value: data.best_avg_user.average_catches_per_match.toFixed(2),
                  },
                ].map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>{item.label}</TableCell>
                    <TableCell align="right">{item.value}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </StyledPaper>
        </Grid>

        {/* Best Leg */}
        <Grid item xs={12}>
          <StyledPaper>
            <div className="galben text-white p-2 mb-3">{translation.best_leg}</div>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{translation.leg_number}</TableCell>
                  <TableCell align="right">{translation.total_catches}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>{data.best_leg.leg_number}</TableCell>
                  <TableCell align="right">{data.best_leg.total_catches}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </StyledPaper>
        </Grid>
      </Grid>
    </Container>
  )
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  translation: state.appState.translation,
})

export default connect(mapStateToProps)(EventTaStats)

