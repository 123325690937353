import { useEffect, useState, useCallback } from "react"
import { connect } from "react-redux"
import { useNavigate } from "react-router-dom"
import axios from "axios"
import {
  Box,
  Container,
  Grid,
  Card,
  CardContent,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  Button,
} from "@mui/material"
import FishIcon from "@mui/icons-material/SetMeal"
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents"
import GroupsIcon from "@mui/icons-material/Groups"
import CalendarTodayIcon from "@mui/icons-material/CalendarToday"
import getAxiosConfig from "../utils/axiosConfig"
import Pagination from "../components/Pagination"

const Dashboard = ({ user, translation, performance, topAnglers, eventOngoing }) => {
  const navigate = useNavigate()
  const [enrolledEvents, setEnrolledEvents] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)

  const fetchEvents = useCallback(async () => {
    setIsLoading(true)
    setError(null)
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/v2/event/enrolled-events/`, {
        ...getAxiosConfig(),
        params: { page: currentPage },
      })
      setEnrolledEvents(response.data.results.events || [])
      setTotalPages(Math.ceil(response.data.count / 10)) // Assuming 10 items per page
    } catch (err) {
      console.error("Error fetching enrolled events:", err)
      setError(translation.dash_failed_to_show)
    } finally {
      setIsLoading(false)
    }
  }, [currentPage, translation.dash_failed_to_show])

  useEffect(() => {
    fetchEvents()
  }, [fetchEvents])

  const renderPerformanceCard = (icon, title, value, subtitle, color) => (
    <Grid item xs={12} sm={6} md={4}>
      <Card sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
        <CardContent sx={{ display: "flex", alignItems: "center" }}>
          <Box
            sx={{
              backgroundColor: `var(--${color})`,
              borderRadius: "50%",
              padding: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginRight: 2,
            }}
          >
            {icon}
          </Box>
          <Box>
            <Typography variant="subtitle2">{title}</Typography>
            <Typography variant="h6" fontWeight="bold">
              {value}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {subtitle}
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </Grid>
  )

  const handleEventNavigation = (item) => {
    let path
    if (eventOngoing && eventOngoing.id === item.id) {
      if (item.scoring_type === "Type_2") {
        path = `/taeventongoing/${item.id}`
      } else if (item.scoring_type === "Type_3") {
        path = `/tsfeventongoing/${item.id}`
        } else {
          path = `/eventongoing/${item.id}`
        }
      } else {
        path = `/event/${item.id}`
      }
    navigate(path)
  }

  const getButtonText = (item) => {
    if (eventOngoing && eventOngoing.id === item.id) return translation.dash_go_to_upload
    return translation.dash_go_to_event
  }

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage)
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          flexGrow: 1,
          overflow: "auto",
          "&::-webkit-scrollbar": { display: "none" },
          scrollbarWidth: "none",
          msOverflowStyle: "none",
        }}
      >
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
          <Card sx={{ mb: 4 }}>
            <CardContent sx={{ backgroundColor: "var(--galben1)", color: "black", padding: 2 }}>
              <Typography variant="h6">{translation.dash_performance}</Typography>
            </CardContent>
            <CardContent>
              <Grid container spacing={3}>
                {renderPerformanceCard(
                  <FishIcon sx={{ color: "white" }} />,
                  translation.dash_fish_caught,
                  performance?.total_catches,
                  `${translation.biggest_catch}: ${performance?.biggest_catch} cm`,
                  "blue1",
                )}
                {renderPerformanceCard(
                  <EmojiEventsIcon sx={{ color: "white" }} />,
                  translation.dash_nb_participation,
                  performance?.number_of_participations,
                  `${translation.dash_year_events}: ${performance?.current_year_participations}`,
                  "green1",
                )}
                {renderPerformanceCard(
                  <GroupsIcon sx={{ color: "white" }} />,
                  translation.dash_best_place,
                  performance?.best_placement,
                  "",
                  "red1",
                )}
              </Grid>
            </CardContent>
          </Card>

          <Card sx={{ mb: 4 }}>
            <CardContent sx={{ backgroundColor: "var(--galben1)", color: "black", padding: 1 }}>
              <Typography variant="subtitle1">{translation.dash_enroll_events}</Typography>
            </CardContent>
            <CardContent sx={{ padding: 1 }}>
              <TableContainer>
                <Table>
                  <TableBody>
                    {enrolledEvents.map((item) => (
                      <TableRow key={item.id}>
                        <TableCell>{item.event_name}</TableCell>
                        <TableCell align="right">
                          <Button variant="contained" color="primary" onClick={() => handleEventNavigation(item)}>
                            {getButtonText(item)}
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                    {isLoading && (
                      <TableRow>
                        <TableCell colSpan={2} align="center">
                          <CircularProgress />
                        </TableCell>
                      </TableRow>
                    )}
                    {error && (
                      <TableRow>
                        <TableCell colSpan={2} align="center">
                          <Typography color="error">{error}</Typography>
                        </TableCell>
                      </TableRow>
                    )}
                    {!isLoading && !error && enrolledEvents.length === 0 && (
                      <TableRow>
                        <TableCell colSpan={2} align="center">
                          <CalendarTodayIcon sx={{ fontSize: 40, color: "primary.main" }} />
                          <Typography>{translation.dash_no_event_found}</Typography>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
            </CardContent>
          </Card>

          <Card>
            <CardContent sx={{ backgroundColor: "var(--galben1)", color: "black", padding: 1 }}>
              <Typography variant="subtitle1">{translation.dash_best_anglers}</Typography>
            </CardContent>
            <CardContent>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>#</TableCell>
                      <TableCell>{translation.name}</TableCell>
                      <TableCell>{translation.dash_nb_participation}</TableCell>
                      <TableCell>{translation.dash_overall_score}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {topAnglers.map((item, index) => (
                      <TableRow key={`top_${item.rank}`}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{item.name}</TableCell>
                        <TableCell>{item.participations}</TableCell>
                        <TableCell>{item.overall_score}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
        </Container>
      </Box>
    </Box>
  )
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  performance: state.utilsState.performance,
  topAnglers: state.utilsState.topAnglers,
  translation: state.appState.translation,
  eventOngoing: state.utilsState.eventOngoing,
})

export default connect(mapStateToProps)(Dashboard)

