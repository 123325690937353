import React, { useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';

const DropdownCity = ({ label, options, onSelect, selectedOptions, formAttr }) => {
  const [selected, setSelected] = useState(label);
  useEffect(() => {
    setSelected(options?.map((option) => selectedOptions === option.id.toString() ? option.city_name : '').join('') || label);
  }, [label, options, selectedOptions]);

  const handleSelect = (option) => {
    onSelect({target: {name: formAttr, value: option}});
  };

  return (
    <>
        <label htmlFor={label} className="form-label">
            { label }:
        </label>
        <Dropdown className='d-flex mb-3' onSelect={handleSelect}>
            <Dropdown.Toggle variant="light" id="multiSelectDropdown" className='flex-grow-1'>
                {selected}
            </Dropdown.Toggle>

            <Dropdown.Menu>
                {options?.map((option) => (
                    <Dropdown.Item
                        key={option.id}
                        eventKey={option.id}
                        // eslint-disable-next-line eqeqeq
                        active={selectedOptions == option.id}
                    >
                        {option.city_name}
                    </Dropdown.Item>
                ))}
            </Dropdown.Menu>
        </Dropdown>
    </>
    
  );
};

export default DropdownCity
;
