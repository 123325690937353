import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import { loadContestatii, loadEnrollments, loadEvent, loadScoring } from "../actions/eventsState"
import { loadAllCatchMap } from "../actions/myCatchMapDataState"
import Loader from "../components/Loader"
import axios from "axios"
import { toast } from "react-toastify"
import DropdownFishTypes from "../components/DropdownFishTypes"
import Input from "../components/Input"
import {
  Tabs,
  Tab,
  Button,
  Container,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Box,
  Divider,
  CircularProgress,
} from "@mui/material"
import { Check, CheckCircle, Visibility, Refresh, Close, Badge, Phone } from "@mui/icons-material"
import ScoringTable from "../components/ScoringTable"
import RevalidateTable from "../components/RevalidateTable"
import ReportsTable from "../components/tables/ReportsTable"

const EventValidate = ({
  event,
  mapdata,
  loadEvent,
  loadAllCatchMap,
  user,
  isLoading,
  fish_types,
  scoring,
  loadScoring,
  translation,
  enrollments,
  loadEnrollments,
  contestatii,
  loadContestatii,
}) => {
  const navigate = useNavigate()
  const [selectedTab, setSelectedTab] = useState(0)
  const [selectedFish, setSelectedFish] = useState(null)
  const [selectedFishType, setSelectedFishType] = useState(null)
  const [selectedFishLength, setSelectedFishLength] = useState(null)
  const [fullScreenImage, setFullScreenImage] = useState(null)
  const [loadingCapturi, setLoadingCapturi] = useState(false)
  const { eventId } = useParams()
  const [showMapModal, setShowMapModal] = useState(null)

  useEffect(() => {
    if (!eventId) {
      navigate("/")
    }
    loadScoring(eventId)
    loadEvent(eventId)
    loadAllCatchMap(eventId)
    loadEnrollments(eventId)
    loadContestatii(eventId)
  }, [loadEvent, eventId, navigate, loadAllCatchMap, loadScoring, loadEnrollments, loadContestatii])

  useEffect(() => {
    if (event) {
      const fishScoring = event.event_fish_scoring
      setSelectedFish(fishScoring.map((item) => item.fish))
    }
  }, [event])

  useEffect(() => {
    if (!event?.is_owner && !event?.validator === user.id) {
      navigate("/")
    }
  }, [event, user.id, navigate])

  const refreshList = () => {
    loadAllCatchMap(eventId)
    loadScoring(eventId)
    loadContestatii(eventId)
  }

  const handleFishLengthChange = (event) => {
    setSelectedFishLength(event.target.value)
  }

  const handleFishType = (event) => {
    setSelectedFishType(event.target.value)
  }

  const updateCapture = async (item, status) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("access")}`,
        Accept: "application/json",
      },
    }

    setShowMapModal(null)
    try {
      await axios.patch(
        `${process.env.REACT_APP_API_URL}/v2/event/captures-validate/${item.photo}/`,
        {
          fish_length: selectedFishLength,
          is_approved: status,
          fish: selectedFishType,
        },
        config,
      )
      toast.success("Fish updated successfully!")
      refreshList()
    } catch (err) {
      toast.error("Error while updating fish!")
    }
  }

  const loadCapturiAnterioare = () => {
    setLoadingCapturi(true)
    // Simulating an API call
    setTimeout(() => {
      setLoadingCapturi(false)
    }, 1000)
  }

  return isLoading ? (
    <Loader />
  ) : (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
        <Typography component="h2" variant="h6" color="primary" gutterBottom>
          Event Validation
        </Typography>
        <Tabs
          value={selectedTab}
          onChange={(event, value) => setSelectedTab(value)}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          <Tab label="Validare capturi" />
          <Tab label="Revalidari" />
          <Tab label="Clasament" />
          <Tab label="Contestatii" />
        </Tabs>
        <div role="tabpanel" hidden={selectedTab !== 0}>
          <Button variant="outlined" color="primary" startIcon={<Refresh />} onClick={refreshList} sx={{ my: 2 }}>
            Refresh
          </Button>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>User</TableCell>
                  <TableCell>Fish</TableCell>
                  <TableCell>Length</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {mapdata?.map((item, key) => (
                  <TableRow key={key}>
                    <TableCell>{item.full_name}</TableCell>
                    <TableCell>
                      {item.fish_name}
                      <Typography variant="caption" display="block">
                        {new Date(item.upload_date).toLocaleTimeString()}
                      </Typography>
                    </TableCell>
                    <TableCell>{item.fish_length}</TableCell>
                    <TableCell>
                      {item.fish_status ? <CheckCircle color="success" /> : <Check color="warning" />}
                    </TableCell>
                    <TableCell>
                      <IconButton
                        onClick={() => {
                          setShowMapModal(item)
                          setSelectedFishLength(item.fish_length)
                          setSelectedFishType(item.fish_id)
                          loadCapturiAnterioare()
                        }}
                      >
                        <Visibility />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <div role="tabpanel" hidden={selectedTab !== 1}>
          <RevalidateTable enrollments={enrollments} refreshList={refreshList} />
        </div>
        <div role="tabpanel" hidden={selectedTab !== 2}>
          <Button variant="outlined" color="primary" startIcon={<Refresh />} onClick={refreshList} sx={{ my: 2 }}>
            Refresh
          </Button>
          <ScoringTable scoring={scoring} event={event} />
        </div>
        <div role="tabpanel" hidden={selectedTab !== 3}>
          <Button variant="outlined" color="primary" startIcon={<Refresh />} onClick={refreshList} sx={{ my: 2 }}>
            Refresh
          </Button>
          <ReportsTable data={contestatii} refreshReports={refreshList} />
        </div>
      </Paper>
      <Dialog
        open={showMapModal !== null}
        onClose={() => setShowMapModal(null)}
        maxWidth="lg"
        fullWidth
        PaperProps={{
          sx: {
            width: "100%",
            maxWidth: "1200px",
            height: "90vh",
          },
        }}
      >
        <DialogTitle>Captura</DialogTitle>
        <DialogContent>
          {showMapModal && (
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                  <Typography variant="body2" sx={{ display: "flex", alignItems: "center" }}>
                    <Badge sx={{ mr: 1 }} />
                    {enrollments?.findIndex((item) => item.user === showMapModal?.user_id) + 1}
                  </Typography>
                  <Typography variant="body2" sx={{ display: "flex", alignItems: "center" }}>
                    <Phone sx={{ mr: 1 }} />
                    {enrollments?.find((item) => item.user === showMapModal?.user_id).user_phone}
                  </Typography>
                </Box>
                <DropdownFishTypes
                  type="text"
                  className="form-control text-start"
                  name={translation.fish_type}
                  label={translation.fish_type}
                  options={fish_types?.filter((item) => selectedFish?.includes(item.id))}
                  selectedOptions={selectedFishType}
                  onSelect={handleFishType}
                />
                <Input
                  type="number"
                  className="form-control step"
                  name={`Fish Length`}
                  label={translation.ong_fish_length}
                  placeholder={translation.ong_fish_length}
                  onChange={handleFishLengthChange}
                  value={selectedFishLength}
                />
              </Grid>
              <Grid item xs={12}>
                <DialogActions sx={{ justifyContent: "space-between", px: 0 }}>
                  <Button variant="contained" color="error" onClick={() => updateCapture(showMapModal, false)}>
                    Reject
                  </Button>
                  <Button variant="contained" color="primary" onClick={() => updateCapture(showMapModal, true)}>
                    Approve
                  </Button>
                </DialogActions>
                <Box sx={{ mt: 2 }}>
                  <img
                    style={{ width: "100%", cursor: "pointer", maxHeight: "300px", objectFit: "contain" }}
                    src={showMapModal?.photo_url || "/placeholder.svg"}
                    alt=""
                    onClick={() => setFullScreenImage(showMapModal?.photo_url)}
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Divider sx={{ my: 2 }} />
                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 2 }}>
                  <Typography variant="h6">Capturi Anterioare</Typography>
                </Box>
                {loadingCapturi ? (
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <Grid container spacing={2}>
                    {scoring?.map((item) => {
                      if (event?.is_team_event) {
                        if (item.team_id !== showMapModal.team_id) return null
                      } else {
                        if (item.user_id !== showMapModal.user_id) return null
                      }
                      return item.details
                        .slice()
                        .sort((a, b) => b.id - a.id)
                        .map((detail, index) => (
                          <Grid item xs={6} sm={4} md={3} key={`captura_${detail.id}`}>
                            <Box sx={{ position: "relative" }}>
                              <img
                                src={detail.photo_url || "/placeholder.svg"}
                                alt={`Captura ${index + 1}`}
                                style={{
                                  width: "100%",
                                  height: "100px",
                                  objectFit: "cover",
                                  cursor: "pointer",
                                }}
                                onClick={() => setFullScreenImage(detail.photo_url)}
                              />
                            </Box>
                          </Grid>
                        ))
                    })}
                  </Grid>
                )}
              </Grid>
            </Grid>
          )}
        </DialogContent>
      </Dialog>
      <Dialog open={fullScreenImage !== null} onClose={() => setFullScreenImage(null)} maxWidth="xl" fullScreen>
        <DialogActions>
          <IconButton onClick={() => setFullScreenImage(null)} color="inherit">
            <Close />
          </IconButton>
        </DialogActions>
        <DialogContent>
          <img
            src={fullScreenImage || "/placeholder.svg"}
            alt="Full screen capture"
            style={{ width: "100%", height: "100%", objectFit: "contain" }}
          />
        </DialogContent>
      </Dialog>
    </Container>
  )
}

const mapStateToProps = (state) => ({
  event: state.eventState.event,
  eventLoading: state.eventState.eventLoading,
  mapdata: state.myMapCatchDataReducer.mapdata,
  isLoading: state.myMapCatchDataReducer.loading,
  fish_types: state.utilsState.fish_types,
  translation: state.appState.translation,
  user: state.auth.user,
  scoring: state.eventsState.scoring,
  enrollments: state.eventState.enrollments,
  contestatii: state.eventsState.contestatii,
})

const mapDispatchToProps = (dispatch) => {
  return {
    loadEvent: (eventId) => dispatch(loadEvent(eventId)),
    loadScoring: (eventId) => dispatch(loadScoring(eventId)),
    loadAllCatchMap: (eventId) => dispatch(loadAllCatchMap(eventId)),
    loadEnrollments: (eventId) => dispatch(loadEnrollments(eventId)),
    loadContestatii: (eventId) => dispatch(loadContestatii(eventId)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(EventValidate))

