import { Box, Card, CardHeader, CardContent, Typography, Paper, Chip } from "@mui/material";
import { Users } from "lucide-react";

const ParticipantsTable = ({ assignments, currentLeg, user, translation }) => {
  if (!assignments || !assignments.participants || !assignments.participants.length) return null;

  const sortedParticipants = [...assignments.participants].sort((a, b) => {
    const legA = a.legs.find((leg) => leg.leg_number === currentLeg);
    const legB = b.legs.find((leg) => leg.leg_number === currentLeg);

    // Handle cases where leg data might be missing
    if (!legA) return 1;
    if (!legB) return -1;

    const posA = legA.position_value;
    const posB = legB.position_value;

    // Try to parse as numbers for proper numerical sorting
    const numA = parseInt(posA);
    const numB = parseInt(posB);

    if (!isNaN(numA) && !isNaN(numB)) {
      return numA - numB;
    }

    // Fall back to string comparison if not numbers
    return String(posA).localeCompare(String(posB));
  });

  return (
    <Card>
      <CardHeader
        title={
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Users size={20} style={{ marginRight: 8 }} />
            {translation.participants || "Participants"}
          </Box>
        }
        subheader={`${translation.leg || "Leg"} ${currentLeg} ${translation.positions || "positions"}`}
      />
      <CardContent>
        {sortedParticipants.map((participant) => {
          const legData = participant.legs.find((leg) => leg.leg_number === currentLeg);
          const isCurrentUser = user && participant.user_id === user.account?.id;

          if (!legData) return null;

          // Safely format numbers with toFixed, handling potential undefined values
          const legPoints = legData.leg_points != null ? legData.leg_points.toFixed(1) : "0.0";

          return (
            <Paper
              key={participant.user_id || participant.name}
              elevation={0}
              sx={{
                mb: 2,
                p: 2,
                borderRadius: 2,
                border: '1px solid',
                borderColor: 'divider',
                backgroundColor: isCurrentUser ? 'rgba(25, 118, 210, 0.08)' : 'inherit'
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                {/* Position and User Info */}
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Box
                    sx={{
                      width: 32,
                      height: 32,
                      borderRadius: '50%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      bgcolor: 'primary.main',
                      color: 'primary.contrastText',
                      fontWeight: 'bold',
                      mr: 2
                    }}
                  >
                    {legData.position_value}
                  </Box>
                  <Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <Typography variant="subtitle1" sx={{ fontWeight: 'medium' }}>
                        {participant.name}
                      </Typography>
                      {isCurrentUser && (
                        <Chip
                          size="small"
                          variant="outlined"
                          label={translation.you || "You"}
                        />
                      )}
                      {participant.is_ghost && (
                        <Chip
                          size="small"
                          color="secondary"
                          label={translation.ghost || "Ghost"}
                        />
                      )}
                    </Box>
                  </Box>
                </Box>

                {/* Points Info */}
                <Box sx={{ textAlign: 'right' }}>
                  <Typography variant="h6" color="primary" sx={{ fontWeight: 'bold' }}>
                    {legPoints}
                  </Typography>
                  <Typography variant="caption" color="text.secondary">
                    {legData.fish_caught || 0} {translation.catches || "fish"}
                  </Typography>
                </Box>
              </Box>
            </Paper>
          );
        })}
      </CardContent>
    </Card>
  );
};

export default ParticipantsTable;
