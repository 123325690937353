import { useState } from "react"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { Card, CardContent, Typography, Button, Avatar, Chip, Grid, Box } from "@mui/material"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import { toast } from "react-toastify"
import EditRolesForm from "./EditRolesForm"
import ClubMembersTable from "./ClubMembersTable"
import ClubInviteMemberForm from "./ClubInviteMemberForm"
import { formatDateOnly } from "../helpers/utils"
import { updateRoles, sendClubInvitation } from "../actions/clubActions"

const ClubDetails = ({ club }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [showEditRoles, setShowEditRoles] = useState(false)
  const [showInviteForm, setShowInviteForm] = useState(false)

  const handleShowEditRoles = () => setShowEditRoles(true)
  const handleCloseEditRoles = () => setShowEditRoles(false)
  const handleShowInviteForm = () => setShowInviteForm(true)
  const handleCloseInviteForm = () => setShowInviteForm(false)

  const handleSaveRoles = async (updatedMembers) => {
    try {
      await dispatch(updateRoles({ club_id: club.id, memberships: updatedMembers }))
      handleCloseEditRoles()
    } catch (error) {
      console.error("Error updating roles:", error)
      // You might want to show an error message to the user here
    }
  }

  const handleInviteMember = async (email) => {
    dispatch(sendClubInvitation(club.id, email))
      .then((message) => {
        toast.success(message)
        handleCloseInviteForm()
      })
      .catch((errorMessage) => {
        toast.error(errorMessage)
      })
  }

  const handleBackToClubs = () => {
    navigate("/clubs")
  }

  return (
    <Card>
      <CardContent>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            alignItems: { xs: "flex-start", sm: "center" },
            gap: 2,
            mb: 3,
          }}
        >
          {/*<Button*/}
          {/*  startIcon={<ArrowBackIcon />}*/}
          {/*  onClick={handleBackToClubs}*/}
          {/*  variant="outlined"*/}
          {/*  sx={{ minWidth: "auto", whiteSpace: "nowrap" }}*/}
          {/*>*/}
          {/*  Back*/}
          {/*</Button>*/}
          <Typography
            variant="h4"
            sx={{
              fontSize: { xs: "1.5rem", sm: "2rem" },
              wordBreak: "break-word",
            }}
          >
            {club.club_name}
          </Typography>
        </Box>

        <Grid container spacing={2} alignItems="center" justifyContent="space-between">
          <Grid item xs={12} md={8}>
            <Typography variant="h5" gutterBottom>
              {club.club_acronym}
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box display="flex" flexDirection="column" alignItems="center">
              <Avatar src={club.club_logo} alt={`${club.club_name} Logo`} sx={{ width: 100, height: 100, mb: 1 }} />
              <Chip
                label={club.club_is_active ? "Active" : "Inactive"}
                color={club.club_is_active ? "success" : "error"}
              />
            </Box>
          </Grid>
        </Grid>

        <Box mt={3}>
          <Typography variant="subtitle1" fontWeight="bold">
            Owner
          </Typography>
          <Box display="flex" alignItems="center" mb={2}>
            <Avatar
              src={club.owner_profile_photo}
              alt={`${club.club_owner_name}'s photo`}
              sx={{ width: 50, height: 50, mr: 2 }}
            />
            <Typography>{club.club_owner_name}</Typography>
          </Box>

          <Typography>
            <strong>Country:</strong> {club.club_country_name}
          </Typography>
          <Typography>
            <strong>City:</strong> {club.club_city_name}
          </Typography>
          <Typography>
            <strong>Creation Date:</strong> {formatDateOnly(club.club_creation_date)}
          </Typography>
        </Box>

        {club.is_club_owner && (
          <Box mt={3} display="flex" justifyContent="flex-end">
            <Button variant="contained" color="primary" onClick={handleShowInviteForm} sx={{ mr: 2 }}>
              Invite New Member
            </Button>
            <Button variant="contained" color="secondary" onClick={handleShowEditRoles}>
              Edit Club Members
            </Button>
          </Box>
        )}

        <Box mt={3}>
          <ClubMembersTable members={club.members} />
        </Box>

        {showEditRoles && (
          <Box mt={3}>
            <EditRolesForm members={club.members} onSave={handleSaveRoles} onClose={handleCloseEditRoles} />
          </Box>
        )}

        {showInviteForm && (
          <Box mt={3}>
            <ClubInviteMemberForm onInvite={handleInviteMember} onClose={handleCloseInviteForm} />
          </Box>
        )}
      </CardContent>
    </Card>
  )
}

export default ClubDetails

