import { useState } from "react"
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
  Button,
  Typography,
  Box,
  IconButton,
} from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"

const EditRolesForm = ({ members, onSave, onClose }) => {
  const [formState, setFormState] = useState(
    members.map((member) => ({
      id: member.id,
      name: member.club_member_name,
      club_member_can_create: member.club_member_can_create || false,
      club_member_can_approve: member.club_member_can_approve || false,
      club_member_can_edit: member.club_member_can_edit || false,
      club_member_can_view: member.club_member_can_view || false,
      club_member_can_validate: member.club_member_can_validate || false,
    })),
  )
  const [isLoading, setIsLoading] = useState(false)

  const handleChange = (memberId, role, value) => {
    setFormState((currentState) =>
      currentState.map((member) => (member.id === memberId ? { ...member, [role]: value } : member)),
    )
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    setIsLoading(true)
    const updatedMemberships = formState.map(
      ({
        id,
        club_member_can_create,
        club_member_can_approve,
        club_member_can_edit,
        club_member_can_view,
        club_member_can_validate,
      }) => ({
        id,
        club_member_can_create,
        club_member_can_approve,
        club_member_can_edit,
        club_member_can_view,
        club_member_can_validate,
      }),
    )

    try {
      await onSave(updatedMemberships)
    } catch (error) {
      console.error("Error saving roles:", error)
    } finally {
      setIsLoading(false)
    }
  }

  const roles = [
    { key: "club_member_can_create", label: "Create" },
    { key: "club_member_can_approve", label: "Approve" },
    { key: "club_member_can_edit", label: "Edit" },
    { key: "club_member_can_view", label: "View" },
    { key: "club_member_can_validate", label: "Validate" },
  ]

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h6">Edit Member Roles</Typography>
        <IconButton onClick={onClose} size="small">
          <CloseIcon />
        </IconButton>
      </Box>
      <form onSubmit={handleSubmit}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Member</TableCell>
                {roles.map((role) => (
                  <TableCell key={role.key} align="center">
                    <Typography variant="subtitle2">{role.label}</Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {formState.map((member) => (
                <TableRow key={member.id}>
                  <TableCell component="th" scope="row">
                    <Typography variant="body1">{member.name}</Typography>
                  </TableCell>
                  {roles.map((role) => (
                    <TableCell key={role.key} align="center">
                      <Checkbox
                        checked={member[role.key]}
                        onChange={(e) => handleChange(member.id, role.key, e.target.checked)}
                        color="primary"
                      />
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Box display="flex" justifyContent="flex-end" mt={2} gap={2}>
          <Button variant="outlined" onClick={onClose} disabled={isLoading}>
            Cancel
          </Button>
          <Button variant="contained" color="primary" type="submit" disabled={isLoading}>
            {isLoading ? "Saving..." : "Save Changes"}
          </Button>
        </Box>
      </form>
    </Box>
  )
}

export default EditRolesForm

