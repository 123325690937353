import { useState } from "react";
import { Typography, Box, Button } from "@mui/material";

const LegSelector = ({ legCount, currentLeg, setCurrentLeg, translation }) => {
  return (
    <Box sx={{ mb: 3 }}>
      <Typography variant="h6" sx={{ mb: 1 }}>
        {translation.select_leg || "Select Leg"}:
      </Typography>
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
        {Array.from({ length: legCount }, (_, i) => i + 1).map((legNumber) => (
          <Button
            key={legNumber}
            variant={currentLeg === legNumber ? "contained" : "outlined"}
            onClick={() => setCurrentLeg(legNumber)}
            sx={{ minWidth: '70px' }}
          >
            {translation.leg || "Leg"} {legNumber}
          </Button>
        ))}
      </Box>
    </Box>
  );
};

export default LegSelector;
