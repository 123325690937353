import { useEffect } from "react"
import { useParams, useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { Box, CircularProgress } from "@mui/material"
import { toast } from "react-toastify"
import ClubDetails from "../components/ClubDetails"
import { fetchClubDetails } from "../actions/clubActions"

const Club = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { selectedClub, loading } = useSelector((state) => state.clubs)

  useEffect(() => {
    dispatch(fetchClubDetails(id)).catch((errorMessage) => {
      toast.error(errorMessage)
      // Optionally navigate back to clubs list on error
      navigate("/clubs")
    })
  }, [dispatch, id, navigate])

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    )
  }

  return (
    <Box sx={{ maxWidth: 1200, margin: "auto", padding: 2 }}>{selectedClub && <ClubDetails club={selectedClub} />}</Box>
  )
}

export default Club

