import React from "react";
import { Tabs, Tab } from "@mui/material";

const EventTabs = ({ selectedTab, setSelectedTab, translation }) => {
  return (
    <Tabs
      value={selectedTab}
      onChange={(_, value) => setSelectedTab(value)}
      variant="scrollable"
      scrollButtons="auto"
    >
      <Tab
        label={translation.assignments || "Assignments"}
        id="tab-0"
        aria-controls="tabpanel-0"
      />
      <Tab
        label={translation.ranking || "Ranking"}
        id="tab-1"
        aria-controls="tabpanel-1"
      />
    </Tabs>
  );
};

export default EventTabs;
