import getAxiosConfig from "../utils/axiosConfig"
import axios from "axios"
import { toast } from "react-toastify"
import { IoCheckmark, IoCheckmarkDoneSharp } from "react-icons/io5"
import { connect } from "react-redux"
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Typography,
  Button,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import { styled } from "@mui/material/styles"

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  marginTop: theme.spacing(3),
  maxWidth: "100%",
  overflowX: "auto",
}))

const StyledTable = styled(Table)(({ theme }) => ({
  minWidth: 650,
  [theme.breakpoints.down("sm")]: {
    minWidth: "auto",
  },
}))

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: theme.spacing(1),
  "&.galben": {
    backgroundColor: "#ffc107",
    color: "white",
  },
}))

const EnrollmentsTable = ({ enrollments, isOwner, refreshEvent, translation }) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))
  // console.log("EnrollmentsTable", enrollments)

  const onApprove = async (eventId, enrollmentId) => {
    try {
      await axios.patch(
        `${process.env.REACT_APP_API_URL}/v2/event/enrolments/${eventId}/approve/`,
        { ids: [enrollmentId] },
        getAxiosConfig(),
      )
      toast.success(`Approved enrollment`)
      refreshEvent()
    } catch (error) {
      toast.error(error.response.data.detail)
    }
  }

  const onReject = async (eventId, enrollmentId) => {
    try {
      await axios.patch(
        `${process.env.REACT_APP_API_URL}/v2/event/enrolments/${eventId}/disapprove/`,
        { ids: [enrollmentId] },
        getAxiosConfig(),
      )
      toast.success(`Rejected enrollment`)
      refreshEvent()
    } catch (error) {
      toast.error(error.response.data.detail)
    }
  }

  const renderRow = (enrollment, index) => (
    <TableRow key={enrollment.id}>
      <StyledTableCell>{index + 1}</StyledTableCell>
      <StyledTableCell>
        <Box display="flex" alignItems="center">
          <img
            src={enrollment.user_photo || "/placeholder.svg"}
            className="rounded-circle club-members-img"
            alt={`${enrollment.user_full_name}`}
            style={{ width: "40px", height: "40px", marginRight: "8px" }}
          />
          {!isMobile && <Typography>{enrollment.user_full_name}</Typography>}
        </Box>
      </StyledTableCell>
      {isMobile && (
        <StyledTableCell>
          <Typography variant="body2">{enrollment.user_full_name}</Typography>
          {isOwner && <Typography variant="caption">{enrollment.user_phone}</Typography>}
        </StyledTableCell>
      )}
      {!isMobile && isOwner && <StyledTableCell>{enrollment.user_phone}</StyledTableCell>}
      <StyledTableCell>
        {enrollment.enrollment_status === "approved" ? (
          <IoCheckmarkDoneSharp style={{ color: "green" }} />
        ) : (
          <IoCheckmark style={{ color: "#ffc107" }} />
        )}
      </StyledTableCell>
      {isOwner && (
        <StyledTableCell>
          <Box display="flex" flexDirection={isMobile ? "column" : "row"} gap={1}>
            {enrollment.enrollment_status !== "approved" && (
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={() => onApprove(enrollment.event, enrollment.id)}
              >
                Approve
              </Button>
            )}
            {enrollment.enrollment_status !== "rejected" && (
              <Button
                variant="contained"
                color="error"
                size="small"
                onClick={() => onReject(enrollment.event, enrollment.id)}
              >
                Reject
              </Button>
            )}
          </Box>
        </StyledTableCell>
      )}
    </TableRow>
  )

  return (
    <Box className="club-members-table-container">
      <StyledTableContainer component={Paper}>
        <StyledTable>
          <TableHead>
            <TableRow>
              <StyledTableCell className="galben">#</StyledTableCell>
              <StyledTableCell className="galben">{isMobile ? "" : translation.enroll_name}</StyledTableCell>
              {isMobile && <StyledTableCell className="galben">Details</StyledTableCell>}
              {!isMobile && isOwner && (
                <StyledTableCell className="galben">{translation.enroll_contact}</StyledTableCell>
              )}
              <StyledTableCell className="galben">Status</StyledTableCell>
              {isOwner && <StyledTableCell className="galben">Actions</StyledTableCell>}
            </TableRow>
          </TableHead>
          <TableBody>{Array.isArray(enrollments) ? enrollments.map(renderRow) : null}</TableBody>
        </StyledTable>
      </StyledTableContainer>
    </Box>
  )
}

const mapStateToProps = (state) => ({
  translation: state.appState.translation,
})

export default connect(mapStateToProps, null)(EnrollmentsTable)

