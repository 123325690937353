import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { TextField, Button, Box, Alert } from "@mui/material"
import { sendMessage, resetContactForm } from "../actions/contactState"

function ContactForm() {
  const [formData, setFormData] = useState({
    message: "",
    honeypot: "",
  })

  const { loading, error, success, translation } = useSelector((state) => ({
    loading: state.contactState.loading,
    error: state.contactState.error,
    success: state.contactState.success,
    translation: state.appState.translation,
  }))
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    if (formData.honeypot) {
      return
    }
    dispatch(sendMessage(formData))
  }

  useEffect(() => {
    if (success) {
      dispatch(resetContactForm())
      navigate("/thank-you")
      setFormData({
        message: "",
        honeypot: "",
      })
    } else if (error === "You have exceeded the number of allowed messages. Please try again later.") {
      const timer = setTimeout(() => {
        dispatch(resetContactForm())
        navigate("/login")
      }, 5000)

      return () => clearTimeout(timer)
    }
  }, [success, error, navigate, dispatch])

  return (
    <Box component="form" onSubmit={handleSubmit} noValidate>
      <TextField
        name="message"
        value={formData.message}
        onChange={handleChange}
        required
        fullWidth
        multiline
        rows={4}
        placeholder={translation.contact_us_placeholder}
        variant="outlined"
        sx={{ mb: 2 }}
      />
      <TextField
        type="text"
        name="honeypot"
        value={formData.honeypot}
        onChange={handleChange}
        tabIndex="-1"
        sx={{ display: "none" }}
      />
      <Button
        type="submit"
        disabled={loading}
        fullWidth
        variant="contained"
        color="primary"
        sx={{
          py: 1.5,
          fontSize: "1.1rem",
          fontWeight: "bold",
          "&:hover": {
            backgroundColor: "primary.dark",
          },
        }}
      >
        {loading ? translation.contact_us_sending : translation.contact_us_send}
      </Button>
      {error && (
        <Alert severity="error" sx={{ mt: 2 }}>
          {error}
        </Alert>
      )}
    </Box>
  )
}

export default ContactForm

