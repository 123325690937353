import { useState } from "react"
import { connect } from "react-redux"
import { IoTrashBin, IoAddCircleOutline } from "react-icons/io5"
import { loadEvent } from "../actions/eventsState"
import Input from "../components/Input"
import { eventPrizes } from "../constants/forms"
import getAxiosConfig from "../utils/axiosConfig"
import axios from "axios"
import { toast } from "react-toastify"
import { Container, Paper, Typography, Grid, Button } from "@mui/material"
import { styled } from "@mui/material/styles"

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginTop: theme.spacing(3),
}))

const StyledForm = styled("form")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(3),
}))

const PrizeContainer = styled("div")(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
}))

const initialStateForm = {
  event: null,
  prize_name: "",
  prize_value: "",
}

const EditPrizesForm = ({ translation, event, onClose, refreshEvent, loadEvent }) => {
  const [dirtyForm, setDirtyForm] = useState(false)
  const [prizesForm, setPrizesForm] = useState(event.event_prizes ? [...event.event_prizes] : [])

  const handleChange = (e, indexPrize) => {
    const { name, value } = e.target
    setPrizesForm((prevState) => {
      const newState = [...prevState]
      newState[indexPrize] = { ...newState[indexPrize], [name]: value }
      return newState
    })
    setDirtyForm(true)
  }

  const addPrizeSection = (e) => {
    e.preventDefault()
    setPrizesForm((prevState) => [...prevState, { ...initialStateForm, event: event.id }])
    setDirtyForm(true)
  }

  const deletePrizeSection = (e, indexPrize) => {
    e.preventDefault()
    setPrizesForm((prevState) => {
      if (prevState.length === 1) {
        return prevState
      }
      return prevState.filter((_, index) => index !== indexPrize)
    })
    setDirtyForm(true)
  }

  const updatePrizes = async (data) => {
    const config = getAxiosConfig()
    try {
      const response = await axios.patch(`${process.env.REACT_APP_API_URL}/v2/event-prizes-amount/bulk-update/`, data, config)

      if (response.data && response.data.event_prizes) {
        // Update the Redux store with the new prizes
        loadEvent(event.id)
        onClose()
        toast.success("Prizes updated successfully")
      } else {
        toast.warning(
          "Prizes may have been updated, but the response was unexpected. Please refresh to confirm changes.",
        )
      }
    } catch (err) {
      console.error("Error updating prizes:", err)
      toast.error("Error while trying to update prizes. Please try again later.")
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (validateMandatory(prizesForm)) {
      await updatePrizes(prizesForm)
    } else {
      toast.error("Please complete all mandatory fields")
    }
  }

  const validateMandatory = (data) => {
    return data.every((prize) => prize.prize_name && prize.prize_value && prize.event)
  }

  const renderForm = (indexPrize) =>
    Object.keys(eventPrizes).map((key, index) => {
      const { type, label, placeholder } = eventPrizes[key]
      return (
        <Grid item xs={12} sm={6} key={key + index}>
          <Input
            type={type}
            className="form-control"
            name={label}
            label={translation[label]}
            placeholder={translation[placeholder]}
            value={prizesForm[indexPrize][key] || ""}
            onChange={(e) => handleChange(e, indexPrize)}
            formAttr={key}
          />
        </Grid>
      )
    })

  return (
    <Container maxWidth="md">
      <StyledPaper elevation={3}>
        <Typography variant="h4" gutterBottom>
          {translation.update_prizes}
        </Typography>
        <StyledForm onSubmit={handleSubmit}>
          <Typography variant="subtitle1" align="right" color="primary">
            {`Updating event prizes`}
          </Typography>
          {prizesForm.map((_item, indexPrize) => (
            <PrizeContainer key={`prize_${indexPrize}`}>
              <Grid container spacing={2}>
                {renderForm(indexPrize)}
              </Grid>
              <Button
                variant="contained"
                color="error"
                startIcon={<IoTrashBin />}
                onClick={(e) => deletePrizeSection(e, indexPrize)}
                sx={{ mt: 2, alignSelf: "flex-end" }}
              >
                {translation.deletePrizeSection}
              </Button>
            </PrizeContainer>
          ))}
          <Grid container justifyContent="space-between" spacing={2}>
            <Grid item>
              <Button variant="contained" color="success" startIcon={<IoAddCircleOutline />} onClick={addPrizeSection}>
                {translation.addPrizeSection}
              </Button>
            </Grid>
            <Grid item>
              <Button type="submit" variant="contained" color="primary" disabled={!dirtyForm}>
                {translation.update_prizes}
              </Button>
            </Grid>
          </Grid>
        </StyledForm>
      </StyledPaper>
    </Container>
  )
}

const mapStateToProps = (state) => ({
  translation: state.appState.translation,
  event: state.eventState.event,
})

const mapDispatchToProps = (dispatch) => {
  return {
    loadEvent: (eventId) => dispatch(loadEvent(eventId)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditPrizesForm)

