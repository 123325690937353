"use client"

import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from "@mui/material"
import { CheckCircle } from "@mui/icons-material"
import { fetchUnvalidatedMatches } from "../actions/taActions"

const MatchCheckStatus = ({ eventId }) => {
  const dispatch = useDispatch()
  const unvalidatedMatches = useSelector((state) => state.taState.unvalidatedMatches)
  const loading = useSelector((state) => state.taState.loading)

  useEffect(() => {
    dispatch(fetchUnvalidatedMatches(eventId))
  }, [dispatch, eventId])

  if (loading) {
    return <Typography>Loading...</Typography>
  }

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Match ID</TableCell>
            <TableCell>Leg</TableCell>
            <TableCell>A</TableCell>
            <TableCell> </TableCell>
            <TableCell> </TableCell>
            <TableCell>B</TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {unvalidatedMatches.map((match) => (
            <TableRow key={match.match_id}>
              <TableCell>{match.match_id}</TableCell>
              <TableCell>{match.leg_number}</TableCell>
              <TableCell>{match.competitor_a_name}</TableCell>
              <TableCell>{match.competitor_a_catches || "--"}</TableCell>
                <TableCell>
                <CheckCircle
                  sx={{
                    color: match.is_valid_a ? "var(--green1)" : "var(--maro1)",
                    fontSize: "1rem",
                    verticalAlign: "middle",
                  }}
                />
              </TableCell>
              <TableCell>{match.competitor_b_name}</TableCell>
              <TableCell>{match.competitor_b_catches || "--"}</TableCell>
              <TableCell>
                <CheckCircle
                  sx={{
                    color: match.is_valid_b ? "var(--green1)" : "var(--maro1)",
                    fontSize: "1rem",
                    verticalAlign: "middle",
                  }}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default MatchCheckStatus

