import {
  FETCH_GAME_CARDS_REQUEST,
  FETCH_GAME_CARDS_SUCCESS,
  FETCH_GAME_CARDS_FAILURE,
  UPDATE_MATCH_REQUEST,
  UPDATE_MATCH_SUCCESS,
  UPDATE_MATCH_FAILURE,
  FETCH_USER_GAME_CARD_REQUEST,
  FETCH_USER_GAME_CARD_SUCCESS,
  FETCH_USER_GAME_CARD_FAILURE,
  FETCH_UNVALIDATED_MATCHES_REQUEST,
  FETCH_UNVALIDATED_MATCHES_SUCCESS,
  FETCH_UNVALIDATED_MATCHES_FAILURE,
  UPDATE_MATCH_ORGANIZER_REQUEST,
  UPDATE_MATCH_ORGANIZER_SUCCESS,
  UPDATE_MATCH_ORGANIZER_FAILURE,
} from "../actions/taActions"

const initialState = {
  gameCard: null,
  userGameCard: null,
  unvalidatedMatches: [],
  loading: false,
  error: null,
  updateLoading: false,
  updateError: null,
}

const taReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_GAME_CARDS_REQUEST:
    case FETCH_USER_GAME_CARD_REQUEST:
    case FETCH_UNVALIDATED_MATCHES_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case FETCH_GAME_CARDS_SUCCESS:
      return {
        ...state,
        loading: false,
        gameCard: action.payload,
      }
    case FETCH_USER_GAME_CARD_SUCCESS:
      return {
        ...state,
        loading: false,
        userGameCard: action.payload,
      }
    case FETCH_UNVALIDATED_MATCHES_SUCCESS:
      return {
        ...state,
        loading: false,
        unvalidatedMatches: action.payload,
      }
    case FETCH_GAME_CARDS_FAILURE:
    case FETCH_USER_GAME_CARD_FAILURE:
    case FETCH_UNVALIDATED_MATCHES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    case UPDATE_MATCH_REQUEST:
    case UPDATE_MATCH_ORGANIZER_REQUEST:
      return {
        ...state,
        updateLoading: true,
        updateError: null,
      }
    case UPDATE_MATCH_SUCCESS:
    case UPDATE_MATCH_ORGANIZER_SUCCESS:
      return {
        ...state,
        updateLoading: false,
      }
    case UPDATE_MATCH_FAILURE:
    case UPDATE_MATCH_ORGANIZER_FAILURE:
      return {
        ...state,
        updateLoading: false,
        updateError: action.payload,
      }
    default:
      return state
  }
}

export default taReducer

