"use client"

import React, { useState, useEffect } from "react"
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  List,
  ListItem,
  ListItemText,
  Collapse,
  IconButton,
  Typography,
  Divider,
  CircularProgress,
  TextField,
  Box,
  Autocomplete,
} from "@mui/material"
import { ExpandMore, ExpandLess, Edit, Delete, Add, Close, PersonAdd, PersonRemove } from "@mui/icons-material"
import axios from "axios"
import { toast } from "react-toastify"
import getAxiosConfig from "../utils/axiosConfig"

const EventTeams = ({ enrollments, eventId, isOwner, translation, event }) => {
  const [open, setOpen] = useState(false)
  const [teams, setTeams] = useState([])
  const [expandedTeam, setExpandedTeam] = useState(null)
  const [loading, setLoading] = useState(true)
  const [addTeamDialogOpen, setAddTeamDialogOpen] = useState(false)
  const [editTeamDialogOpen, setEditTeamDialogOpen] = useState(false)
  const [newTeamName, setNewTeamName] = useState("")
  const [editingTeamId, setEditingTeamId] = useState(null)
  const [editingTeamName, setEditingTeamName] = useState("")
  const [addingTeam, setAddingTeam] = useState(false)
  const [editingTeam, setEditingTeam] = useState(false)
  const [addMemberDialogOpen, setAddMemberDialogOpen] = useState(false)
  const [selectedTeamId, setSelectedTeamId] = useState(null)
  const [selectedMemberId, setSelectedMemberId] = useState("")
  const [searchQuery, setSearchQuery] = useState("")
  const [showOnlyWithoutTeam, setShowOnlyWithoutTeam] = useState(false)

  useEffect(() => {
    if (open) {
      fetchTeams()
    }
  }, [open])

  const fetchTeams = async () => {
    setLoading(true)
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/v2/event/teams/`, {
        params: { event_id: eventId },
        ...getAxiosConfig(),
      })
      setTeams(response.data)
    } catch (error) {
      console.error("Error fetching team data", error)
      if (error.response && error.response.data && error.response.data.detail) {
        toast.error(error.response.data.detail)
      } else {
        toast.error("An error occurred while fetching teams. Please try again.")
      }
    } finally {
      setLoading(false)
    }
  }

  const handleToggle = (teamId) => {
    setExpandedTeam(expandedTeam === teamId ? null : teamId)
  }

  const handleAddTeam = async () => {
    if (!newTeamName.trim()) {
      toast.error("Team name cannot be empty")
      return
    }

    setAddingTeam(true)
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/v2/event/teams/`,
        {
          name: newTeamName,
          event: eventId,
        },
        getAxiosConfig(),
      )

      await fetchTeams()
      setNewTeamName("")
      setAddTeamDialogOpen(false)
      toast.success("Team added successfully")
    } catch (error) {
      console.error("Error adding new team", error)
      if (error.response && error.response.data && error.response.data.detail) {
        toast.error(error.response.data.detail)
      } else {
        toast.error("An error occurred while adding the team. Please try again.")
      }
    } finally {
      setAddingTeam(false)
    }
  }

  const handleEditTeam = async () => {
    if (!editingTeamName.trim()) {
      toast.error("Team name cannot be empty")
      return
    }

    setEditingTeam(true)
    try {
      await axios.patch(
        `${process.env.REACT_APP_API_URL}/v2/event/teams/${editingTeamId}/`,
        {
          name: editingTeamName,
          event: eventId,
        },
        getAxiosConfig(),
      )

      await fetchTeams()
      setEditingTeamName("")
      setEditingTeamId(null)
      setEditTeamDialogOpen(false)
      toast.success("Team updated successfully")
    } catch (error) {
      console.error("Error editing team", error)
      if (error.response && error.response.data && error.response.data.detail) {
        toast.error(error.response.data.detail)
      } else {
        toast.error("An error occurred while editing the team. Please try again.")
      }
    } finally {
      setEditingTeam(false)
    }
  }

  const handleDeleteTeam = async (teamId) => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/v2/event/teams/${teamId}/`, {
        data: { event: eventId },
        ...getAxiosConfig(),
      })

      await fetchTeams()
      toast.success("Team deleted successfully")
    } catch (error) {
      console.error("Error deleting team", error)
      if (error.response && error.response.data && error.response.data.detail) {
        toast.error(error.response.data.detail)
      } else {
        toast.error("An error occurred while deleting the team. Please try again.")
      }
    }
  }

  const handleAddMember = async () => {
    if (!selectedMemberId) {
      toast.error("Please select a member to add")
      return
    }

    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/v2/event/team-members/`,
        {
          team_id: selectedTeamId,
          event: eventId,
          members: [{ enrollment_id: selectedMemberId }],
        },
        getAxiosConfig(),
      )

      await fetchTeams()
      setAddMemberDialogOpen(false)
      setSelectedMemberId("")
      toast.success("Member added to team successfully")
    } catch (error) {
      console.error("Error adding team member", error)
      if (error.response && error.response.data && error.response.data.detail) {
        toast.error(error.response.data.detail)
      } else {
        toast.error("An error occurred while adding the team member. Please try again.")
      }
    }
  }

  const handleRemoveMember = async (teamId, memberId) => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/v2/event/team-members/update-delete/`, {
        data: {
          team_id: teamId,
          event_id: eventId,
          members: [{ id: memberId }],
        },
        ...getAxiosConfig(),
      })

      await fetchTeams()
      toast.success("Member removed from team successfully")
    } catch (error) {
      console.error("Error removing team member", error)
      if (error.response && error.response.data && error.response.data.detail) {
        toast.error(error.response.data.detail)
      } else {
        toast.error("An error occurred while removing the team member. Please try again.")
      }
    }
  }

  const filteredEnrollments = enrollments.filter((enrollment) => {
    const nameMatches = enrollment.user_full_name.toLowerCase().includes(searchQuery.toLowerCase())
    const hasNoTeam = showOnlyWithoutTeam
      ? !teams.some((team) => team.members.some((member) => member.enrollment.id === enrollment.id))
      : true
    return nameMatches && hasNoTeam
  })
  console.log(isOwner , !event.is_ongoing , !event.is_ended )
  return (
    <>
        <Button
          variant="contained"
          className="galben"
          onClick={() => setOpen(true)}
        >
          {translation.manage_teams}
        </Button>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="md"
        fullWidth
        PaperProps={{
          style: {
            backgroundColor: "#f5f5f5",
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
          },
        }}
      >
        <DialogTitle>
          <Typography variant="h6" component="div" style={{ fontWeight: "bold", color: "#333" }}>
            {translation.event_teams}
          </Typography>
        </DialogTitle>
        <Divider />
        <DialogContent>
          {loading ? (
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "200px" }}>
              <CircularProgress />
            </div>
          ) : teams.length === 0 ? (
            <Typography variant="body2" style={{ color: "#666", fontStyle: "italic" }}>
              {translation.no_teams}
            </Typography>
          ) : (
            <List>
              {teams.map((team) => (
                <React.Fragment key={team.id}>
                  <ListItem
                    style={{
                      backgroundColor: "white",
                      marginBottom: "8px",
                      borderRadius: "4px",
                      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.05)",
                    }}
                  >
                    <ListItemText
                      primary={
                        <Typography variant="subtitle1" style={{ fontWeight: "bold", color: "#444" }}>
                          {`${team.team_number}. ${team.name}`}
                        </Typography>
                      }
                    />
                    <IconButton size="small" onClick={() => handleToggle(team.id)}>
                      {expandedTeam === team.id ? <ExpandLess /> : <ExpandMore />}
                    </IconButton>
                    {isOwner && !event.is_ongoing && !event.is_ended && (
                      <>
                        <IconButton
                          size="small"
                          onClick={() => {
                            setEditingTeamId(team.id)
                            setEditingTeamName(team.name)
                            setEditTeamDialogOpen(true)
                          }}
                        >
                          <Edit fontSize="small" />
                        </IconButton>
                        <IconButton size="small" onClick={() => handleDeleteTeam(team.id)}>
                          <Delete fontSize="small" />
                        </IconButton>
                        <IconButton
                          size="small"
                          onClick={() => {
                            setSelectedTeamId(team.id)
                            setAddMemberDialogOpen(true)
                          }}
                        >
                          <PersonAdd fontSize="small" />
                        </IconButton>
                      </>
                    )}
                  </ListItem>
                  <Collapse in={expandedTeam === team.id} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      {team.members.map((member) => (
                        <ListItem key={member.id} sx={{ pl: 4, py: 0.5 }}>
                          <ListItemText
                            primary={
                              <Typography variant="body2" style={{ color: "#666" }}>
                                {member.enrollment.user_full_name}
                              </Typography>
                            }
                          />
                          {isOwner && !event.is_ongoing && !event.is_ended && (
                            <IconButton size="small" onClick={() => handleRemoveMember(team.id, member.id)}>
                              <PersonRemove fontSize="small" />
                            </IconButton>
                          )}
                        </ListItem>
                      ))}
                    </List>
                  </Collapse>
                </React.Fragment>
              ))}
            </List>
          )}
        </DialogContent>
        <Divider />
        <DialogActions style={{ padding: "16px" }}>
          {isOwner && !event.is_ongoing && !event.is_ended && (
            <Button onClick={() => setAddTeamDialogOpen(true)} startIcon={<Add />} variant="contained">
              {translation.add_team}
            </Button>
          )}
          <Button onClick={() => setOpen(false)} startIcon={<Close />} variant="outlined">
            {translation.close}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Add Team Dialog */}
      <Dialog open={addTeamDialogOpen} onClose={() => setAddTeamDialogOpen(false)}>
        <DialogTitle>{translation.add_team}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label={translation.team_name}
            type="text"
            fullWidth
            value={newTeamName}
            onChange={(e) => setNewTeamName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAddTeamDialogOpen(false)}>{translation.cancel}</Button>
          <Button onClick={handleAddTeam} disabled={addingTeam}>
            {addingTeam ? <CircularProgress size={24} /> : translation.add}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Edit Team Dialog */}
      <Dialog open={editTeamDialogOpen} onClose={() => setEditTeamDialogOpen(false)}>
        <DialogTitle>{translation.edit_team}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label={translation.team_name}
            type="text"
            fullWidth
            value={editingTeamName}
            onChange={(e) => setEditingTeamName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditTeamDialogOpen(false)}>{translation.cancel}</Button>
          <Button onClick={handleEditTeam} disabled={editingTeam}>
            {editingTeam ? <CircularProgress size={24} /> : translation.save}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Add Member Dialog */}
      <Dialog open={addMemberDialogOpen} onClose={() => setAddMemberDialogOpen(false)} maxWidth="md" fullWidth>
        <DialogTitle>{translation.add_member}</DialogTitle>
        <DialogContent>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2, pt: 1 }}>
            <Autocomplete
              value={enrollments.find((e) => e.id === selectedMemberId) || null}
              onChange={(_, newValue) => setSelectedMemberId(newValue ? newValue.id : "")}
              options={filteredEnrollments}
              getOptionLabel={(option) => option.user_full_name}
              renderInput={(params) => (
                <TextField {...params} label={translation.search_and_select_member} variant="outlined" />
              )}
              noOptionsText={translation.no_members_found}
            />
            <Button
              fullWidth
              variant={showOnlyWithoutTeam ? "contained" : "outlined"}
              color="primary"
              onClick={() => setShowOnlyWithoutTeam(!showOnlyWithoutTeam)}
            >
              {translation.show_only_without_team}
            </Button>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAddMemberDialogOpen(false)}>{translation.cancel}</Button>
          <Button onClick={handleAddMember}>{translation.add}</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default EventTeams

