import React, { useEffect, useCallback, useState } from "react"
import { connect } from "react-redux"
import { useParams, useNavigate } from "react-router-dom"
import { Box, Container, Typography, Grid, Button } from "@mui/material"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import ExpandLessIcon from "@mui/icons-material/ExpandLess"
import { fetchGameCards, updateMatch } from "../actions/taActions"
import { loadEvent } from "../actions/eventsState"
import MatchCard from "../components/TaMatchCard"
import Loader from "../components/Loader"

const TaEventOngoing = ({
  gameCard,
  loading,
  error,
  updateLoading,
  event,
  translation,
  fetchGameCards,
  updateMatch,
  loadEvent,
}) => {
  const { eventId } = useParams()
  const navigate = useNavigate()
  const [showAllLegs, setShowAllLegs] = useState(false)

  const fetchData = useCallback(() => {
    if (eventId) {
      fetchGameCards(eventId)
    }
  }, [eventId, fetchGameCards])

  useEffect(() => {
    if (!eventId) {
      navigate("/")
    } else {
      loadEvent(eventId)
    }
  }, [eventId, loadEvent, navigate])

  useEffect(() => {
    if (
      event &&
      (!event?.is_ongoing || event?.enrollement_status === "not enrolled" || event?.enrollement_status === "rejected")
    ) {
      navigate("/")
    }
  }, [event, navigate])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  if (loading) {
    return <Loader />
  }

  if (error) {
    return (
      <Container>
        <Typography color="error">
          {translation?.error || "Error"}: {error}
        </Typography>
      </Container>
    )
  }

  if (!gameCard || !event) {
    return (
      <Container>
        <Typography>No game card or event data available.</Typography>
      </Container>
    )
  }

  const toggleShowAllLegs = () => {
    setShowAllLegs(!showAllLegs)
  }

  const visibleMatches = showAllLegs
    ? gameCard.matches
    : gameCard.matches.filter((match) => match.leg_number === gameCard.current_leg)

  return (
    <Container>
      <Box py={4}>
         <Typography variant="h4" gutterBottom>
          {event?.event_name || translation?.ongoing_tournament || "Ongoing Tournament"}
        </Typography>
        <Typography variant="h6" gutterBottom>
          {gameCard.user_name} - {translation.current_leg || "Current Leg"}:{" "}
          <Typography
            component="span"
            sx={{
              color: (theme) => (gameCard.current_leg === "Finished" ? theme.palette.success.main : "inherit"),
              fontWeight: "bold",
            }}
          >
            {gameCard.current_leg}
          </Typography>
        </Typography>

        <Grid container spacing={3}>
          {visibleMatches.map((match) => (
            <Grid item xs={12} md={6} lg={4} key={match.match_id}>
              <MatchCard
                match={match}
                eventId={eventId}
                onUpdate={updateMatch}
                translation={translation || {}}
                updateLoading={updateLoading}
                refreshCard={fetchData}
                event={event}
                currentLeg={gameCard.current_leg}
              />
            </Grid>
          ))}
        </Grid>

        {gameCard.matches.length > 1 && (
          <Button
            onClick={toggleShowAllLegs}
            startIcon={showAllLegs ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            fullWidth
            sx={{ mt: 2 }}
          >
            {showAllLegs
              ? translation.hide_previous_legs || "Hide Previous Legs"
              : translation.show_previous_legs || "Show Previous Legs"}
          </Button>
        )}
      </Box>
    </Container>
  )
}

const mapStateToProps = (state) => {
  return {
    event: state.eventState.event,
    gameCard: state.taState?.gameCard,
    loading: state.taState?.loading,
    error: state.taState?.error,
    updateLoading: state.taState?.updateLoading,
    translation: state.appState?.translation,
  }
}

const mapDispatchToProps = {
  fetchGameCards,
  updateMatch,
  loadEvent,
}

export default connect(mapStateToProps, mapDispatchToProps)(TaEventOngoing)

