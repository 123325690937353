import {
  FETCH_CLUBS_REQUEST,
  FETCH_CLUBS_SUCCESS,
  FETCH_CLUBS_FAILURE,
  FETCH_CLUB_DETAILS_REQUEST,
  FETCH_CLUB_DETAILS_SUCCESS,
  FETCH_CLUB_DETAILS_FAILURE,
} from "../actions/clubActions"

const initialState = {
  clubs: [],
  selectedClub: null,
  loading: false,
  error: null,
}

const clubReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CLUBS_REQUEST:
    case FETCH_CLUB_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case FETCH_CLUBS_SUCCESS:
      return {
        ...state,
        loading: false,
        clubs: action.payload,
        error: null,
      }
    case FETCH_CLUB_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        selectedClub: action.payload,
        error: null,
      }
    case FETCH_CLUBS_FAILURE:
    case FETCH_CLUB_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        selectedClub: null,
        error: action.payload,
      }
    default:
      return state
  }
}

export default clubReducer

