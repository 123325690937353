import React from "react";
import { Box, Typography, Chip, Button, Badge, Alert, TextField } from "@mui/material";
import { Table, TableBody, TableCell, TableHead, TableRow, Paper } from "@mui/material";
import { Skeleton } from "@mui/material";

const AssignmentsTable = ({
  translation,
  selectedGroup,
  selectedLeg,
  setSelectedLeg,
  filteredParticipants,
  scoreboardData,
  handleScoreChange,
  handleSubmitScore,
  submitting
}) => {
  if (!selectedGroup) {
    return (
      <Alert severity="info" sx={{ mt: 2 }}>
        {translation.select_group_to_view_participants || "Please select a group to view participants."}
      </Alert>
    );
  }

  if (filteredParticipants.length === 0) {
    return (
      <Alert severity="info" sx={{ mt: 2 }}>
        {translation.no_participants_found || "No participants found matching the criteria."}
      </Alert>
    );
  }

  return (
    <Box sx={{ mt: 2 }}>
      <Typography variant="h6" gutterBottom sx={{ fontWeight: "bold" }}>
        {translation.group || "Group"} {selectedGroup}{" "}
        {selectedLeg && `- ${translation.leg || "Leg"} ${selectedLeg}`}
      </Typography>

      <Paper elevation={0} sx={{ overflow: "hidden", border: "1px solid", borderColor: "divider", borderRadius: "lg" }}>
        <Table>
          <TableHead>
            <TableRow sx={{ backgroundColor: "background.subtle" }}>
              <TableCell sx={{ fontWeight: "bold" }}>{translation.position || "Pos."}</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>{translation.name || "Name"}</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>{translation.draw_number || "Draw #"}</TableCell>
              {selectedLeg ? (
                <>
                  <TableCell sx={{ fontWeight: "bold" }}>{translation.fish_caught || "Fish Caught"}</TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>{translation.points || "Points"}</TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>{translation.actions || "Actions"}</TableCell>
                </>
              ) : (
                <TableCell sx={{ fontWeight: "bold" }}>{translation.legs || "Legs"}</TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredParticipants.map((participant, index) => {
              if (selectedLeg) {
                // Show specific leg data with editable fish caught
                const legData = participant.legs.find((leg) => leg.leg_number === selectedLeg);
                if (!legData) return null;

                const scoreData = scoreboardData[legData.scoreboard_id] || {
                  fishCaught: legData.fish_caught || 0,
                  originalValue: legData.fish_caught || 0,
                };
                const hasChanges = scoreData.fishCaught !== scoreData.originalValue;

                return (
                  <TableRow
                    key={`${participant.user_id || "ghost"}-${legData.scoreboard_id}`}
                    sx={{ '&:hover': { backgroundColor: 'background.subtle' } }}
                  >
                    <TableCell>{legData.position_value || index + 1}</TableCell>
                    <TableCell sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                      {participant.name}
                      {participant.is_ghost && (
                        <Badge
                          sx={{ ml: 1 }}
                          color="secondary"
                          badgeContent={translation.ghost || "Ghost"}
                        />
                      )}
                    </TableCell>
                    <TableCell>{participant.draw_number}</TableCell>
                    <TableCell>
                      <TextField
                        type="number"
                        size="small"
                        InputProps={{ inputProps: { min: 0 } }}
                        value={scoreData.fishCaught}
                        onChange={(e) => handleScoreChange(legData.scoreboard_id, e.target.value)}
                        sx={{
                          width: "100px",
                          ...(hasChanges && {
                            "& .MuiOutlinedInput-root": {
                              borderColor: "warning.main",
                            },
                          }),
                        }}
                      />
                    </TableCell>
                    <TableCell>{legData.leg_points || 0}</TableCell>
                    <TableCell>
                      <Button
                        variant={hasChanges ? "contained" : "outlined"}
                        color={hasChanges ? "warning" : "secondary"}
                        disabled={!hasChanges || submitting}
                        onClick={() => handleSubmitScore(legData.scoreboard_id)}
                        size="small"
                      >
                        {submitting ? (
                          <>
                            <Skeleton width={20} height={20} sx={{ mr: 1 }} />
                            {translation.saving || "Saving..."}
                          </>
                        ) : (
                          translation.save || "Save"
                        )}
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              } else {
                // Show all legs for the participant
                return (
                  <TableRow
                    key={participant.user_id || `ghost-${participant.draw_number}`}
                    sx={{ '&:hover': { backgroundColor: 'background.subtle' } }}
                  >
                    <TableCell>{participant.position_value || index + 1}</TableCell>
                    <TableCell sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                      {participant.name}
                      {participant.is_ghost && (
                        <Badge
                          sx={{ ml: 1 }}
                          color="secondary"
                          badgeContent={translation.ghost || "Ghost"}
                        />
                      )}
                    </TableCell>
                    <TableCell>{participant.draw_number}</TableCell>
                    <TableCell>
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
                        {participant.legs.map((leg) => (
                          <Chip
                            key={leg.leg_number}
                            label={`${translation.leg || "Leg"} ${leg.leg_number}: ${leg.fish_caught || 0}`}
                            onClick={() => setSelectedLeg(leg.leg_number)}
                            color="primary"
                            variant="outlined"
                            clickable
                          />
                        ))}
                      </Box>
                    </TableCell>
                  </TableRow>
                );
              }
            })}
          </TableBody>
        </Table>
      </Paper>
    </Box>
  );
};

export default AssignmentsTable;
