export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_FAIL = 'SIGNUP_FAIL';
export const ACTIVATION_SUCCESS = 'ACTIVATION_SUCCESS';
export const ACTIVATION_FAIL = 'ACTIVATION_FAIL';
export const USER_LOADED_SUCCESS = 'USER_LOADED_SUCCESS';
export const USER_LOADED_FAIL = 'USER_LOADED_FAIL';
export const AUTHENTICATED_SUCCESS = 'AUTHENTICATED_SUCCESS';
export const AUTHENTICATED_FAIL = 'AUTHENTICATED_FAIL';
export const PASSWORD_RESET_FAIL = 'PASSWORD_RESET_FAIL';
export const PASSWORD_RESET_SUCCESS = 'PASSWORD_RESET_SUCCESS';
export const PASSWORD_RESET_CONFIRM_FAIL = 'PASSWORD_RESET_CONFIRM_FAIL';
export const PASSWORD_RESET_CONFIRM_SUCCESS = 'PASSWORD_RESET_CONFIRM_SUCCESS';
export const GOOGLE_AUTH_SUCCESS = 'GOOGLE_AUTH_SUCCESS';
export const GOOGLE_AUTH_FAIL = 'GOOGLE_AUTH_FAIL';
export const FACEBOOK_AUTH_SUCCESS = 'FACEBOOK_AUTH_SUCCESS';
export const FACEBOOK_AUTH_FAIL = 'FACEBOOK_AUTH_FAIL';
export const LOGOUT = 'LOGOUT';
export const LOAD_USER_FAIL = 'LOAD_USER_FAIL';
export const LOAD_USER = 'LOAD_USER';
export const USER_LOAD = 'USER_LOAD';
export const LOAD_USER_SUCCESS = 'LOAD_USER_SUCCESS';
export const LOAD_TRANSLATIONS = 'LOAD_TRANSLATIONS';
export const MENU_TOGGLE = 'MENU_TOGGLE';
export const CLOSE_MENU  = 'CLOSE_MENU';
export const LOAD_EVENTS = 'LOAD_EVENTS';
export const LOADED_EVENTS = 'LOADED_EVENTS';
export const LOADED_EVENTS_FAIL = 'LOADED_EVENTS_FAIL';
export const LOAD_EVENT = 'LOAD_EVENT';
export const LOADED_EVENT = 'LOADED_EVENT';
export const LOADED_EVENT_FAIL = 'LOADED_EVENT_FAIL';
export const LOAD_PUBLISH = 'LOAD_PUBLISH';
export const LOADED_PUBLISH = 'LOADED_PUBLISH';
export const LOADED_PUBLISH_FAIL = 'LOADED_PUBLISH_FAIL';
export const LOAD_TOURNAMENTS = 'LOAD_TOURNAMENTS';
export const LOADED_TOURNAMENTS = 'LOADED_TOURNAMENTS';
export const LOADED_TOURNAMENTS_FAIL = 'LOADED_TOURNAMENTS_FAIL';
export const SELECT_TOURNAMENT = 'SELECT_TOURNAMENT';
export const LOAD_SPONSORS = 'LOAD_SPONSORS';
export const LOAD_SPONSORS_FAILED = 'LOAD_SPONSORS_FAILED';
export const LOAD_FISH_TYPES = 'LOAD_FISH_TYPES';
export const LOAD_FISH_TYPES_FAILED = 'LOAD_FISH_TYPES_FAILED';
export const SELECT_EVENT = 'SELECT_EVENT';
export const POST_EVENT_SUCCESS = 'POST_EVENT_SUCCESS';
export const POST_EVENT_FAIL = 'POST_EVENT_FAIL';
export const POST_EVENT_LOADING = 'POST_EVENT_LOADING';
export const LOGOUT_FINISHED = 'LOGOUT_FINISHED';
export const POST_EVENT_SCORING_SUCCESS = 'POST_EVENT_SCORING_SUCCESS';
export const POST_EVENT_SCORING_FAIL = 'POST_EVENT_SCORING_FAIL';
export const POST_MESSAGE = 'POST_MESSAGE';
export const CLEAR_MESSAGE = 'CLEAR_MESSAGE';
export const LOAD_MY_EVENTS = 'LOAD_MY_EVENTS';
export const LOADED_MY_EVENTS = 'LOADED_MY_EVENTS';
export const LOADED_MY_EVENTS_FAIL = 'LOADED_MY_EVENTS_FAIL';
export const SELECT_MY_EVENT = 'SELECT_MY_EVENT';
export const LOAD_MY_CATCH_MAP_DATA = 'LOAD_MY_CATCH_MAP_DATA';
export const LOADED_MY_CATCH_MAP_DATA = 'LOADED_MY_CATCH_MAP_DATA';
export const LOADED_MY_CATCH_MAP_DATA_FAIL = 'LOADED_MY_CATCH_MAP_DATA_FAIL';
export const SELECT_MY_CATCH_MAP_DATA = 'SELECT_MY_CATCH_MAP_DATA';
export const EVENT_NOT_ENDED = 'EVENT_NOT_ENDED';
export const SOCKET_MESSAGE = 'SOCKET_MESSAGE';
export const CLEAR_SOCKET = 'CLEAR_SOCKET';
export const LOAD_MY_CLUB = 'LOAD_MY_CLUB';
export const LOADED_MY_CLUB = 'LOADED_MY_CLUB';
export const LOADED_MY_CLUB_FAIL = 'LOAD_MY_CLUB_FAIL';
export const START_LOADING_MY_EVENT = 'START_LOADING_MY_EVENT';
export const MY_EVENT_LOADED_SUCCESS = 'MY_EVENT_LOADED_SUCCESS';
export const MY_EVENT_LOADED_FAIL = 'MY_EVENT_LOADED_FAIL';
export const ACCEPT_INVITATION_SUCCESS = 'ACCEPT_INVITATION_SUCCESS';
export const ACCEPT_INVITATION_FAIL = 'ACCEPT_INVITATION_FAIL';
export const SEND_INVITATION_SUCCESS = 'SEND_INVITATION_SUCCESS';
export const SEND_INVITATION_FAIL = 'SEND_INVITATION_FAIL';
export const LOAD_MY_NOTIFICATIONS = 'LOAD_MY_NOTIFICATIONS';
export const LOADED_MY_NOTIFICATIONS = 'LOADED_MY_NOTIFICATIONS';
export const LOADED_MY_NOTIFICATIONS_FAIL = 'LOADED_MY_NOTIFICATIONS_FAIL';
export const MARK_NOTIFICATION_AS_READ = 'MARK_NOTIFICATION_AS_READ';
export const MARK_NOTIFICATION_AS_READ_FAIL = 'MARK_NOTIFICATION_AS_READ_FAIL';
export const POST_EVENT_PRIZES_SUCCESS = 'POST_EVENT_PRIZES_SUCCESS';
export const POST_EVENT_PRIZES_FAIL = 'POST_EVENT_PRIZES_FAIL';
export const LOAD_VALIDATORS = 'LOAD_VALIDATORS';
export const LOAD_VALIDATORS_SUCCESS = 'LOAD_VALIDATORS_SUCCESS';
export const LOAD_VALIDATORS_FAIL = 'LOAD_VALIDATORS_FAIL';
export const SEND_MESSAGE_START = 'SEND_MESSAGE_START';

export const SEND_MESSAGE_SUCCESS = 'SEND_MESSAGE_SUCCESS';
export const SEND_MESSAGE_FAIL = 'SEND_MESSAGE_FAIL';
export const RESET_CONTACT_FORM = 'RESET_CONTACT_FORM';
export const MESSAGE_THROTTLED = 'MESSAGE_THROTTLED';
export const UPDATE_EVENT_SUCCESS = 'UPDATE_EVENT_SUCCESS';
export const UPDATE_EVENT_SCORING_SUCCESS = 'UPDATE_EVENT_SCORING_SUCCESS';

export const UPLOAD_PROFILE_PHOTO_LOADING = 'UPLOAD_PROFILE_PHOTO_LOADING';
export const UPLOAD_PROFILE_PHOTO_SUCCESS = 'UPLOAD_PROFILE_PHOTO_SUCCESS';
export const UPLOAD_PROFILE_PHOTO_FAIL = 'UPLOAD_PROFILE_PHOTO_FAIL';
export const LOAD_LOCATIONS = 'LOAD_LOCATIONS';
export const LOAD_LOCATIONS_FAIL = 'LOAD_LOCATIONS_FAIL';
export const   LOAD_COUNTRIES="LOAD_COUNTRIES";
export const   LOAD_COUNTRIES_FAIL="LOAD_COUNTRIES_FAIL";
export const   LOAD_CITIES="LOAD_CITIES";
export const   LOAD_CITIES_FAIL="LOAD_CITIES_FAIL";
export const UPDATE_USER_PROFILE_STARTING = 'UPDATE_USER_PROFILE_STARTING';
export const UPDATE_USER_PROFILE_SUCCESS = 'UPDATE_USER_PROFILE_SUCCESS';
export const UPDATE_USER_PROFILE_FAIL = 'UPDATE_USER_PROFILE_FAIL';
 export const TEST_PHOTO_UPLOAD_SUCCESS='TEST_PHOTO_UPLOAD_SUCCESS';
 export const TEST_PHOTO_UPLOAD_FAIL='TEST_PHOTO_UPLOAD_FAIL';
 export const EVENT_ENROLL_SUCCESS='EVENT_ENROLL_SUCCESS';
 export const EVENT_ENROLL_FAIL='EVENT_ENROLL_FAIL';
 export const LOAD_ENROLLMENTS_LOADING ='LOAD_ENROLLMENTS_LOADING';
 export const LOAD_ENROLLMENTS_FAIL='LOAD_ENROLLMENTS_FAIL';
 export const LOAD_ENROLLMENTS_SUCCESS='LOAD_ENROLLMENTS_SUCCESS';
 export const EVENT_ONGOING = 'EVENT_ONGOING';
 export const EVENT_ONGOING_FAIL = 'EVENT_ONGOING_FAIL';

 export const LOAD_EVENT_VALIDATORS = 'LOAD_EVENT_VALIDATORS';
 export const LOAD_EVENT_VALIDATORS_FAIL = 'LOAD_EVENT_VALIDATORS';

 export const LOAD_FISHING_SPOTS = 'LOAD_FISHING_SPOTS';
 export const LOAD_FISHING_SPOTS_FAIL = 'LOAD_FISHING_SPOTS';
 export const LOAD_SCORING = 'LOAD_SCORING';
 export const LOAD_SCORING_FAIL = 'LOAD_SCORING_FAIL';
 export const LOAD_CONTESTATII = 'LOAD_CONTESTATII';
 export const LOAD_CONTESTATII_FAIL = 'LOAD_CONTESTATII_FAIL';
 export const LOGIN_RESET = 'LOGIN_RESET';

 export const LOAD_PERFORMANCE = 'LOAD_PERFORMANCE';
 export const LOAD_PERFORMANCE_FAIL = 'LOAD_PERFORMANCE_FAIL';
 export const LOAD_TOP_ANGLERS = 'LOAD_TOP_ANGLERS';
 export const LOAD_TOP_ANGLERS_FAIL = 'LOAD_TOP_ANGLERS_FAIL';
 export const  LOAD_SCORING_TYPES= 'LOAD_SCORING_TYPES';
 export const LOAD_SCORING_TYPES_FAIL='LOAD_SCORING_TYPES_FAIL';
 export const LOAD_TA_SCORING = "LOAD_TA_SCORING";
 export const LOAD_TA_SCORING_FAIL = "LOAD_TA_SCORING_FAIL";
 export const LOAD_TA_SCORING_LOADING= "LOAD_TA_SCORING_LOADING";
export const UPDATE_ROLES_SUCCESS = "UPDATE_ROLES_SUCCESS";
export const UPDATE_ROLES_FAIL = 'UPDATE_ROLES_FAIL';
export const CLEAR_USER_PROFILE = 'CLEAR_USER_PROFILE';
export const   RESET_PASSWORD_ADMIN_SUCCESS = 'RESET_PASSWORD_ADMIN_SUCCESS';
export const   RESET_PASSWORD_ADMIN_FAIL='RESET_PASSWORD_ADMIN_FAIL';
export const   GET_USER_STATUS_SUCCESS = 'GET_USER_STATUS_SUCCESS';
export const   GET_USER_STATUS_FAIL='GET_USER_STATUS_FAIL';
export const   SEARCH_USERS_SUCCESS='SEARCH_USERS_SUCCESS';
 export const  SEARCH_USERS_FAIL='SEARCH_USERS_FAIL';
  export const   TOGGLE_USER_STATUS_SUCCESS='TOGGLE_USER_STATUS_SUCCESS';
  export const  TOGGLE_USER_STATUS_FAIL='TOGGLE_USER_STATUS_FAIL';
export const SOFT_DELETE_EVENT_SUCCESS='SOFT_DELETE_EVENT_SUCCESS';
export const SOFT_DELETE_EVENT_FAIL='SOFT_DELETE_EVENT_FAIL';
