import { Table, TableBody, TableCell, TableHead, TableRow, Avatar, Typography, Box } from "@mui/material"

const ClubMembersTable = ({ members }) => {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Member</TableCell>
          <TableCell>Join Date</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {members.map((member) => (
          <TableRow key={member.id}>
            <TableCell>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Avatar
                  src={member.club_member_photo}
                  alt={member.club_member_name}
                  sx={{ marginRight: 2, width: 40, height: 40 }}
                />
                <Typography variant="body1">{member.club_member_name}</Typography>
              </Box>
            </TableCell>
            <TableCell>
              <Typography variant="body2">{new Date(member.club_member_join_date).toLocaleDateString()}</Typography>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

export default ClubMembersTable

