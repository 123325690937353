import React from "react";
import { Box, Typography, TextField, Button, Autocomplete } from "@mui/material";
import { Search, Refresh } from "@mui/icons-material";

const FilterBar = ({
  translation,
  availableGroups,
  availableLegs,
  selectedGroup,
  setSelectedGroup,
  selectedLeg,
  setSelectedLeg,
  searchQuery,
  setSearchQuery,
  refreshData
}) => {
  return (
    <Box sx={{
      mb: 3,
      p: 3,
      borderRadius: "lg",
      backgroundColor: "background.paper",
      boxShadow: "sm",
      display: "flex",
      flexWrap: "wrap",
      gap: 2,
      alignItems: "center"
    }}>
      <Typography variant="subtitle1" component="div" fontWeight="medium">
        {translation.filter_by || "Filter by:"}
      </Typography>

      <Autocomplete
        options={availableGroups}
        getOptionLabel={(option) => `${translation.group || "Group"} ${option}`}
        renderInput={(params) => (
          <TextField {...params} label={translation.select_group || "Select Group"} size="small" />
        )}
        onChange={(_, value) => setSelectedGroup(value)}
        value={selectedGroup}
        sx={{ width: 200 }}
      />

      <Autocomplete
        options={availableLegs}
        getOptionLabel={(option) => `${translation.leg || "Leg"} ${option}`}
        renderInput={(params) => (
          <TextField {...params} label={translation.select_leg || "Select Leg"} size="small" />
        )}
        onChange={(_, value) => setSelectedLeg(value)}
        value={selectedLeg}
        sx={{ width: 200 }}
        disabled={!selectedGroup}
      />

      <TextField
        label={translation.search_participant || "Search Participant"}
        variant="outlined"
        size="small"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        sx={{ width: 250 }}
        InputProps={{
          endAdornment: <Search color="action" />,
        }}
      />

      <Button
        variant="outlined"
        color="primary"
        startIcon={<Refresh />}
        onClick={refreshData}
        sx={{ ml: "auto" }}
      >
        {translation.refresh || "Refresh"}
      </Button>
    </Box>
  );
};

export default FilterBar;
