import { useState } from "react"
import { connect } from "react-redux"
import { reset_password } from "../actions/auth"
import { useNavigate } from "react-router-dom"
import ReCAPTCHA from "react-google-recaptcha"
import { Box, Container, Typography, TextField, Button, Paper, Alert } from "@mui/material"

const ResetPassword = ({ reset_password }) => {
  const [requestSent, setRequestSent] = useState(false)
  const [formData, setFormData] = useState({ email: "" })
  const [captchaValue, setCaptchaValue] = useState(null)
  const [error, setError] = useState("")

  const navigate = useNavigate()
  const { email } = formData

  const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value })

  const onSubmit = (e) => {
    e.preventDefault()

    if (captchaValue) {
      reset_password(email, captchaValue)
      setRequestSent(true)
    } else {
      setError("Please verify that you are a human!")
    }
  }

  if (requestSent) {
    navigate("/")
    return null
  }

  return (
    <Container maxWidth="sm">
      <Box sx={{ mt: 4, mb: 4 }}>
        <Paper elevation={3} sx={{ p: 4, borderRadius: 2 }}>
          <Box sx={{ display: "flex", justifyContent: "center", mb: 3 }}>
            <img
              src="https://streetfishing.fra1.cdn.digitaloceanspaces.com/media/assets/logo_orange_yellow_transparent.png"
              alt="logo"
              style={{ width: "100%", maxWidth: "300px" }}
            />
          </Box>

          <Typography
            variant="h4"
            component="h1"
            gutterBottom
            align="center"
            sx={{ fontWeight: "bold", color: "primary.main" }}
          >
            Password Reset
          </Typography>

          <Box component="form" onSubmit={onSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={onChange}
            />
            <Box sx={{ display: "flex", justifyContent: "center", my: 2 }}>
              <ReCAPTCHA sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY} onChange={setCaptchaValue} />
            </Box>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{
                mt: 3,
                mb: 2,
                py: 1.5,
                fontSize: "1.1rem",
                fontWeight: "bold",
                backgroundColor: "green",
                "&:hover": {
                  backgroundColor: "darkgreen",
                },
              }}
            >
              Reset Password
            </Button>
          </Box>

          {error && (
            <Alert severity="error" sx={{ mt: 2 }}>
              {error}
            </Alert>
          )}
        </Paper>
      </Box>
    </Container>
  )
}

export default connect(null, { reset_password })(ResetPassword)

