import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  Container,
  Paper,
  Box,
} from "@mui/material";

// Custom hooks
import useTSFValidateData from "../components/tsf-validate/useTSFValidateData";

// Custom components
import AssignmentsTab from "../components/tsf-validate/AssignmentsTab";
import RankingTab from "../components/tsf-validate/RankingTab";
import EventHeader from "../components/tsf-validate/EventHeader";
import EventTabs from "../components/tsf-validate/EventTabs";
import TabPanel from "../components/tsf-validate/TabPanel";

const EventTSFValidate = () => {
  const navigate = useNavigate();
  const { eventId } = useParams();

  // Tab state
  const [selectedTab, setSelectedTab] = useState(0);

  // Redux state
  const event = useSelector((state) => state.eventState?.event);
  const translation = useSelector((state) => state.appState?.translation || {});

  // Custom hook to handle data fetching and state management
  const {
    loading,
    submitting,
    filteredParticipants,
    scoreboardData,
    selectedGroup,
    setSelectedGroup,
    selectedLeg,
    setSelectedLeg,
    searchQuery,
    setSearchQuery,
    availableGroups,
    availableLegs,
    rankingView,
    selectedRankingGroup,
    setSelectedRankingGroup,
    handleScoreChange,
    handleSubmitScore,
    refreshData,
    handleRankingViewChange,
    rankingsData
  } = useTSFValidateData(eventId, translation);

  useEffect(() => {
    if (event && !event.is_owner && !event.is_validator) {
      navigate("/");
    }
  }, [event, navigate]);

  return (
    <Container
      maxWidth="lg"
      sx={{ mt: 4, mb: 4 }}
    >
      <Paper
        elevation={2}
        sx={{
          p: 3,
          display: "flex",
          flexDirection: "column",
          borderRadius: "lg",
          overflow: "hidden"
        }}
      >
        <EventHeader translation={translation} />

        <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 3 }}>
          <EventTabs
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            translation={translation}
          />
        </Box>

        <TabPanel value={selectedTab} index={0} id="0">
          <AssignmentsTab
            loading={loading}
            translation={translation}
            availableGroups={availableGroups}
            availableLegs={availableLegs}
            selectedGroup={selectedGroup}
            setSelectedGroup={setSelectedGroup}
            selectedLeg={selectedLeg}
            setSelectedLeg={setSelectedLeg}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            refreshData={refreshData}
            filteredParticipants={filteredParticipants}
            scoreboardData={scoreboardData}
            handleScoreChange={handleScoreChange}
            handleSubmitScore={handleSubmitScore}
            submitting={submitting}
          />
        </TabPanel>

        <TabPanel value={selectedTab} index={1} id="1">
          <RankingTab
            loading={loading}
            translation={translation}
            rankingView={rankingView}
            handleRankingViewChange={handleRankingViewChange}
            availableGroups={availableGroups}
            selectedRankingGroup={selectedRankingGroup}
            setSelectedRankingGroup={setSelectedRankingGroup}
            refreshData={refreshData}
            eventId={eventId}
            rankingsData={rankingsData}
          />
        </TabPanel>
      </Paper>
    </Container>
  );
};

export default React.memo(EventTSFValidate);
