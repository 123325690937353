import {
  GENERATE_TSF_LINEUP_REQUEST,
  GENERATE_TSF_LINEUP_SUCCESS,
  GENERATE_TSF_LINEUP_FAILURE,
  GENERATE_TSF_GENERAL_LINEUP_REQUEST,
  GENERATE_TSF_GENERAL_LINEUP_SUCCESS,
  GENERATE_TSF_GENERAL_LINEUP_FAILURE,
  CHECK_TSF_READINESS_REQUEST,
  CHECK_TSF_READINESS_SUCCESS,
  CHECK_TSF_READINESS_FAILURE,
  EXPORT_TSF_LINEUP_REQUEST,
  EXPORT_TSF_LINEUP_SUCCESS,
  EXPORT_TSF_LINEUP_FAILURE,
  EXPORT_TSF_RANKING_REQUEST,
  EXPORT_TSF_RANKING_SUCCESS,
  EXPORT_TSF_RANKING_FAILURE,
  DOWNLOAD_TSF_SUMMARY_REQUEST,
  DOWNLOAD_TSF_SUMMARY_SUCCESS,
  DOWNLOAD_TSF_SUMMARY_FAILURE,
  FETCH_TSF_ASSIGNMENTS_REQUEST,
  FETCH_TSF_ASSIGNMENTS_SUCCESS,
  FETCH_TSF_ASSIGNMENTS_FAILURE,
  FETCH_TSF_RANKING_REQUEST,
  FETCH_TSF_RANKING_SUCCESS,
  FETCH_TSF_RANKING_FAILURE,
  UPDATE_TSF_SCOREBOARD_REQUEST,
  UPDATE_TSF_SCOREBOARD_SUCCESS,
  UPDATE_TSF_SCOREBOARD_FAILURE,
} from "../actions/tsfActions"

const initialState = {
  tsfAssignments: null,
  tsfRanking: null,
  lineupReady: false,
  positionsReady: false,
  loading: false,
  error: null,
  generateLineupLoading: false,
  exportLineupLoading: false,
  exportRankingLoading: false,
  downloadSummaryLoading: false,
  updateScoreboardLoading: false,
}

const tsfReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TSF_ASSIGNMENTS_REQUEST:
    case FETCH_TSF_RANKING_REQUEST:
    case CHECK_TSF_READINESS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case GENERATE_TSF_LINEUP_REQUEST:
    case GENERATE_TSF_GENERAL_LINEUP_REQUEST:
      return {
        ...state,
        generateLineupLoading: true,
        error: null,
      }
    case EXPORT_TSF_LINEUP_REQUEST:
      return {
        ...state,
        exportLineupLoading: true,
        error: null,
      }
    case EXPORT_TSF_RANKING_REQUEST:
      return {
        ...state,
        exportRankingLoading: true,
        error: null,
      }
    case DOWNLOAD_TSF_SUMMARY_REQUEST:
      return {
        ...state,
        downloadSummaryLoading: true,
        error: null,
      }
    case UPDATE_TSF_SCOREBOARD_REQUEST:
      return {
        ...state,
        updateScoreboardLoading: true,
        error: null,
      }
    case FETCH_TSF_ASSIGNMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        tsfAssignments: action.payload,
      }
    case FETCH_TSF_RANKING_SUCCESS:
      return {
        ...state,
        loading: false,
        tsfRanking: action.payload,
      }
    case CHECK_TSF_READINESS_SUCCESS:
      return {
        ...state,
        loading: false,
        lineupReady: action.payload.lineup_ready,
        positionsReady: action.payload.positions_ready,
      }
    case GENERATE_TSF_LINEUP_SUCCESS:
    case GENERATE_TSF_GENERAL_LINEUP_SUCCESS:
      return {
        ...state,
        generateLineupLoading: false,
        lineupReady: true,
      }
    case EXPORT_TSF_LINEUP_SUCCESS:
      return {
        ...state,
        exportLineupLoading: false,
      }
    case EXPORT_TSF_RANKING_SUCCESS:
      return {
        ...state,
        exportRankingLoading: false,
      }
    case DOWNLOAD_TSF_SUMMARY_SUCCESS:
      return {
        ...state,
        downloadSummaryLoading: false,
      }
    case UPDATE_TSF_SCOREBOARD_SUCCESS:
      return {
        ...state,
        updateScoreboardLoading: false,
      }
    case FETCH_TSF_ASSIGNMENTS_FAILURE:
    case FETCH_TSF_RANKING_FAILURE:
    case CHECK_TSF_READINESS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    case GENERATE_TSF_LINEUP_FAILURE:
    case GENERATE_TSF_GENERAL_LINEUP_FAILURE:
      return {
        ...state,
        generateLineupLoading: false,
        error: action.payload,
      }
    case EXPORT_TSF_LINEUP_FAILURE:
      return {
        ...state,
        exportLineupLoading: false,
        error: action.payload,
      }
    case EXPORT_TSF_RANKING_FAILURE:
      return {
        ...state,
        exportRankingLoading: false,
        error: action.payload,
      }
    case DOWNLOAD_TSF_SUMMARY_FAILURE:
      return {
        ...state,
        downloadSummaryLoading: false,
        error: action.payload,
      }
    case UPDATE_TSF_SCOREBOARD_FAILURE:
      return {
        ...state,
        updateScoreboardLoading: false,
        error: action.payload,
      }
    default:
      return state
  }
}

export default tsfReducer

