import React, { useEffect, useState, useCallback } from "react"
import { connect, useDispatch, useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import { toast } from "react-toastify"
import Linkify from "react-linkify"
import { formatDate } from "../helpers/utils"
import { loadEvent, loadEnrollments, loadScoring, loadContestatii, loadTaScoring, softDeleteEvent } from "../actions/eventsState"
import {
  createTALineup,
  generateMatches,
  checkLineupMatchExists,
  downloadLineup,
  downloadTAScoreboard, downloadTAStatistics
} from "../actions/taActions"
import EventActions from "../components/EventActions"
import Modal from "../components/Modal"
import EnrollmentsTable from "../components/EnrollmentsTable"
import FishPrizesTable from "../components/FishPrizesTable"
import ScoringTable from "../components/ScoringTable"
import Empty from "../components/Empty"
import MyEventCatchMap from "./MyCatchMap"
import { Helmet } from "react-helmet"
import {IoCameraReverse } from "react-icons/io5"
import EditEventBanner from "../components/EditEventBanner"
import Tabs from "../components/Tabs"
import EventStats from "../components/EventStats"
import ReportsTable from "../components/tables/ReportsTable"
import FishScoringTable from "../components/FishScoringTable"
import SponsorsCard from "../components/SponsorsCard"
import RankingMovement from "../components/RankingMovement"
import TaRankingMovement from "../components/TaRankingMovement";
import TaScoringTable from "../components/TaScoringTable";
import EventTaStats from "../components/EventTaStats";
import { Box, IconButton } from "@mui/material"
import EventTeams from "../components/EventTeams"
import BonusPoints from "../components/EventBonusPoints"
import {
  generateTSFGeneralLineup,
  checkTSFReadiness,
  exportTSFLineup,
  exportTSFRanking,
  downloadTSFSummary,
} from "../actions/tsfActions"
import RankingTab from "../components/tsf-validate/RankingTab";
import { fetchTSFFinalRankings } from "../actions/tsfActions";
import TSFStatistics from "../components/tsf-validate/TSFStatistics";



const Event = ({
  event,
  translation,
  sponsors,
  fish_types,
  loadEvent,
  eventLoading,
  user,
  fishingspots,
  locations,
  scoring,
  contestatii,
  taScoring,
}) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { eventId } = useParams()
  const [selectedTab, setSelectedTab] = useState(0)
  const [openEventBanner, setOpenEventBanner] = useState(false)
  const [open, setOpen] = useState(false)
  const enrollments = useSelector((state) => state.eventState.enrollments)

  const [isGeneratingLineup, setIsGeneratingLineup] = useState(false)
  const [isGeneratingMatches, setIsGeneratingMatches] = useState(false)
  const [isGeneratingTSFGeneralLineup, setIsGeneratingTSFGeneralLineup] = useState(false)
  const [lineupMatchExists, setLineupMatchExists] = useState({
    lineup: false,
    match: false,
    tsf_general_lineup: false,
  })
    // TSF Rankings states
  const [tsfRankingsData, setTsfRankingsData] = useState(null)
  const [tsfRankingsLoading, setTsfRankingsLoading] = useState(false)
  const [rankingView, setRankingView] = useState("global")
  const [selectedRankingGroup, setSelectedRankingGroup] = useState(null)
  const [availableGroups, setAvailableGroups] = useState([])

  const [tsfStatistics, setTsfStatistics] = useState(null);
  const [tsfStatisticsLoading, setTsfStatisticsLoading] = useState(false);

  const handleEventSoftDelete = useCallback(async () => {
    try {
      const result = await dispatch(softDeleteEvent(eventId))
      if (result.success) {
        toast.success(translation.event_deleted_success)
        navigate("/organizer-dashboard")
      } else {
        toast.error(result.error || translation.event_delete_failed)
      }
    } catch (error) {
      console.error("Error soft deleting event", error)
      toast.error(translation.event_delete_failed)
    }
}, [dispatch, eventId, translation, navigate])


  const handleGenerateLineup = useCallback(async () => {
    if (isGeneratingLineup) return

    setIsGeneratingLineup(true)
    try {
      const result = await dispatch(createTALineup(eventId))
      if (result.success) {
        toast.success(result.message || translation.lineup_generated_success)
        checkLineupMatchStatus()
      } else {
        toast.error(result.error || translation.lineup_generation_failed)
      }
    } catch (error) {
      // console.error("Error generating lineup", error)
      toast.error(translation.lineup_generation_failed)
    } finally {
      setIsGeneratingLineup(false)
    }
  }, [dispatch, eventId, translation, isGeneratingLineup])

  const handleGenerateMatches = useCallback(async () => {
    if (isGeneratingMatches) return

    setIsGeneratingMatches(true)
    try {
      const result = await dispatch(generateMatches(eventId))
      if (result.success) {
        toast.success(result.message || translation.matches_generated_success)
        checkLineupMatchStatus()
      } else {
        toast.error(result.error || translation.matches_generation_failed)
      }
    } catch (error) {
      console.error("Error generating matches", error)
      toast.error(translation.matches_generation_failed)
    } finally {
      setIsGeneratingMatches(false)
    }
  }, [dispatch, eventId, translation, isGeneratingMatches])

  const handleGenerateTSFGeneralLineup = useCallback(async () => {
    if (isGeneratingTSFGeneralLineup) return

    setIsGeneratingTSFGeneralLineup(true)
    try {
      const result = await dispatch(generateTSFGeneralLineup(eventId))
      if (result.success) {
        toast.success(result.message || translation.tsf_general_lineup_generated_success)
        checkLineupMatchStatus()
      } else {
        toast.error(result.error || translation.tsf_general_lineup_generation_failed)
      }
    } catch (error) {
      console.error("Error generating TSF general lineup", error)
      toast.error(translation.tsf_general_lineup_generation_failed)
    } finally {
      setIsGeneratingTSFGeneralLineup(false)
    }
  }, [dispatch, eventId, translation, isGeneratingTSFGeneralLineup])

  const checkLineupMatchStatus = useCallback(async () => {
    try {
      const result = await dispatch(checkLineupMatchExists(eventId))
      if (result.success) {
        setLineupMatchExists(result.data)
      } else {
        console.error("Error checking lineup/match status:", result.error)
      }

      if (event?.scoring_type === "Type_3") {
        const tsfResult = await dispatch(checkTSFReadiness(eventId))
        if (tsfResult.success) {
          setLineupMatchExists((prev) => ({
            ...prev,
            tsf_general_lineup: tsfResult.data.lineup_ready,
          }))
        }
      }
    } catch (error) {
      console.error("Error checking lineup/match status:", error)
    }
  }, [dispatch, eventId, event?.scoring_type])

  const handleDownloadTSFLineup = useCallback(async () => {
    try {
      const result = await dispatch(exportTSFLineup(eventId, event?.event_name, event?.start_date))
      if (result.success) {
        toast.success(result.message || translation.downloaded_success)
      } else {
        toast.error(result.error || translation.lineup_download_failed)
      }
    } catch (error) {
      console.error("Error downloading TSF lineup", error)
      toast.error(translation.lineup_download_failed)
    }
  }, [dispatch, eventId, event?.event_name, event?.start_date, translation])

  const handleExportTSFRanking = useCallback(async () => {
    try {
      const result = await dispatch(exportTSFRanking(eventId, event?.event_name, event?.start_date))
      if (result.success) {
        toast.success(result.message || translation.exported_success)
      } else {
        toast.error(result.error || translation.ranking_export_failed)
      }
    } catch (error) {
      console.error("Error exporting TSF ranking", error)
      toast.error(translation.ranking_export_failed)
    }
  }, [dispatch, eventId, event?.event_name, event?.start_date, translation])

  const handleDownloadTSFSummary = useCallback(async () => {
    try {
      const result = await dispatch(downloadTSFSummary(eventId, event?.event_name, event?.start_date))
      if (result.success) {
        toast.success(result.message || translation.downloaded_success)
      } else {
        toast.error(result.error || translation.summary_download_failed)
      }
    } catch (error) {
      console.error("Error downloading TSF summary", error)
      toast.error(translation.summary_download_failed)
    }
  }, [dispatch, eventId, event?.event_name, event?.start_date, translation])

  const navigateToEventOngoing = useCallback(() => {
    if (event?.scoring_type === "Type_3") {
      navigate(`/tsfeventongoing/${eventId}`)
    } else if (event?.scoring_type === "Type_2") {
      navigate(`/taeventongoing/${eventId}`)
    } else {
      navigate(`/eventongoing/${eventId}`)
    }
  }, [navigate, event?.scoring_type, eventId])

   const fetchTSFStatistics = useCallback(async () => {
    if (!eventId) return;

    setTsfStatisticsLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/v1/event/${eventId}/tsf-statistics/`, {
        headers: {
          Authorization: `JWT ${localStorage.getItem("access")}`,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch TSF statistics');
      }

      const data = await response.json();
      setTsfStatistics(data);
    } catch (error) {
      console.error("Error fetching TSF statistics:", error);
    } finally {
      setTsfStatisticsLoading(false);
    }
  }, [eventId]);

  const fetchTSFRankings = useCallback(async () => {
    if (!eventId) return;

    setTsfRankingsLoading(true);
    try {
      const response = await dispatch(fetchTSFFinalRankings(eventId));
      if (response.success) {
        setTsfRankingsData(response.data);

        // Extract available groups from participants data
        if (response.data && response.data.participants) {
          const groups = [...new Set(response.data.participants.map(p => p.group_number))].sort();
          setAvailableGroups(groups);

          // Set default selected group if in group view and none selected
          if (rankingView === "group" && groups.length > 0 && !selectedRankingGroup) {
            setSelectedRankingGroup(groups[0]);
          }
        }
      }
    } catch (error) {
      console.error("Error fetching TSF rankings:", error);
    } finally {
      setTsfRankingsLoading(false);
    }
  }, [dispatch, eventId, rankingView, selectedRankingGroup]);

  const handleRankingViewChange = (_, newView) => {
    if (newView !== null) {
      setRankingView(newView);
    }
  };

  useEffect(() => {
    if (!eventId) {
      navigate("/")
    }

    loadEvent(eventId)
    dispatch(loadEnrollments(eventId))
    dispatch(loadScoring(eventId))
    dispatch(loadContestatii(eventId))
    dispatch(loadTaScoring(eventId))
    checkLineupMatchStatus()
  }, [loadEvent, eventId, navigate, dispatch, checkLineupMatchStatus])

  // Add effect to load TSF rankings when event is of Type_3
  useEffect(() => {
    if (event && event.scoring_type === "Type_3") {
      fetchTSFRankings();
      if (event.is_ended) {
        fetchTSFStatistics();
      }
    }
  }, [event?.scoring_type, event?.is_ended, fetchTSFRankings, fetchTSFStatistics]);

  const refreshEvent = useCallback(() => {
    loadEvent(eventId)
    dispatch(loadEnrollments(eventId))
  }, [loadEvent, eventId, dispatch])

  const refreshReports = useCallback(() => {
    dispatch(loadContestatii(eventId))
  }, [dispatch, eventId])


  const handleDownloadLineup = useCallback(async () => {
    try {
      const result = await dispatch(downloadLineup(eventId, event?.event_name, event?.start_date))
      if (result.success) {
        toast.success(result.message || translation.downloaded_success)
      } else {
        toast.error(result.error || translation.lineup_download_failed)
      }
    } catch (error) {
      // console.error("Error downloading lineup", error)
      toast.error(translation.lineup_download_failed)
    }
  }, [dispatch, eventId, event?.event_name, event?.start_date, translation])

  const handleDownloadTAStatistics = useCallback(async () => {
    try {
      const result = await dispatch(downloadTAStatistics(eventId, event?.event_name, event?.start_date))
      if (result.success) {
        toast.success(result.message || translation.downloaded_success)
      } else {
        toast.error(result.error || translation.ta_statistics_download_failed)
      }
    } catch (error) {
      // console.error("Error downloading TA Statistics", error)
      toast.error(translation.ta_statistics_download_failed)
    }
  }, [dispatch, eventId, event?.event_name, event?.start_date, translation])

  const handleDownloadTAScoreboard = useCallback(async () => {
    try {
      const result = await dispatch(downloadTAScoreboard(eventId, event?.event_name, event?.start_date))
      if (result.success) {
        toast.success(result.message || translation.downloaded_success)
      } else {
        toast.error(result.error || translation.ta_scoreboard_download_failed)
      }
    } catch (error) {
      // console.error("Error downloading TA Scoreboard", error)
      toast.error(translation.ta_scoreboard_download_failed)
    }
  }, [dispatch, eventId, event?.event_name, event?.start_date, translation])


  const renderGeneralView = () => {
    if (eventLoading) {
      return null
    }

    const componentDecorator = (href, text, key) => (
      <a className="mx-1" href={href} key={key} target="_blank" rel="noopener noreferrer">
        {translation.details_link}
      </a>
    )

    return (
      <div className="col-12 d-flex mt-0 position-relative">
        <div className="w-100">
          <p className="fw-bold fs-5">{event.event_name}</p>
          <Box position="relative" display="inline-block">
            <img className="max-w-20 mx-auto d-block" src={event.event_logo || "/placeholder.svg"} alt="event_banner" />
            {event?.is_owner && (
              <IconButton
                onClick={() => setOpenEventBanner(true)}
                sx={{
                  position: "absolute",
                  bottom: 0,
                  right: 0,
                  backgroundColor: "rgba(255, 255, 255, 0.7)",
                  "&:hover": { backgroundColor: "rgba(255, 255, 255, 0.9)" },
                }}
              >
                <IoCameraReverse />
              </IconButton>
        )}
      </Box>
          <p className="m-0 mb-2 text-start mt-3">
            <span className="event-card-label fw-bold">{translation.details}:</span>{" "}
            <Linkify componentDecorator={componentDecorator}>{event.details}</Linkify>
          </p>
          <p className="m-0 mb-2 text-start">
            <span className="event-card-label fw-bold">{translation.start_date}:</span> {formatDate(event.start_date)}
          </p>
          <p className="m-0 mb-2 text-start">
            <span className="event-card-label fw-bold">{translation.event_duration}:</span> {event.event_duration_hours}{" "}
            {translation.hours}
          </p>
          <p className="m-0 mb-2 text-start">
            <span className="event-card-label fw-bold">{translation.participation_tax}:</span> {event.participation_tax}{" "}
            RON
          </p>
          <p className="m-0 mb-2 text-start">
            <span className="event-card-label fw-bold">{translation.location}:</span>{" "}
            {event.city_name}
            , {event.fishing_spot_name}
          </p>
          <p className="m-0 mb-2 text-start">
            <span className="event-card-label fw-bold">{translation.location_details}:</span>{" "}
            {event.event_location_details}
          </p>
          <div className="row w-100">
            {event.principal_sponsors.concat(event.other_sponsors).map((item, index) => (
              <SponsorsCard sponsor={item} index={index} key={index} />
            ))}
          </div>
        </div>
      </div>
    )
  }

  const renderFishScoring = () => {
    if (!event || !fish_types || event.event_fish_scoring.length < 1) {
      return null
    }

    return (
      <>
        {event.has_bonus_points && (
          <BonusPoints eventId={eventId} isOwner={event?.is_owner} translation={translation} event={event} />
        )}
         <FishScoringTable fishScoring={event.event_fish_scoring} fish_types={fish_types} />
      </>
    )
  }

  const renderPrizes = () => {
    if (!event && !fish_types) {
      return null
    }

    return (
      <>
        <FishPrizesTable fishPrizes={event.event_prizes} />
      </>
    )
  }

    const renderEnrollments = () => {
    if (!enrollments) {
      return null
    }
    return (
      <>
        {event?.is_team_event && <EventTeams enrollments={enrollments} eventId={eventId} isOwner={event?.is_owner} translation={translation} event={event} />}
        <EnrollmentsTable enrollments={enrollments} isOwner={event?.is_owner} refreshEvent={refreshEvent} />
      </>
    )
  }

const renderScoring = () => {
    if (!event) {
      return <div>Loading event data...</div>
    }

    // Add support for Type_3 scoring
    if (event.scoring_type === "Type_3") {
      return (
        <RankingTab
          loading={tsfRankingsLoading}
          translation={translation}
          rankingView={rankingView}
          handleRankingViewChange={handleRankingViewChange}
          availableGroups={availableGroups}
          selectedRankingGroup={selectedRankingGroup}
          setSelectedRankingGroup={setSelectedRankingGroup}
          eventId={eventId}
          rankingsData={tsfRankingsData}
        />
      );
    }

    if (event.is_ongoing) {
      return event.scoring_type === "Type_2" ? <TaRankingMovement /> : <RankingMovement />
    } else if (event.is_ended) {
      if (event.scoring_type === "Type_2") {
        return taScoring ? <TaScoringTable scoring={taScoring} event={event} /> : <div>Loading TA Scoring data...</div>
      } else {
        return scoring ? <ScoringTable scoring={scoring} event={event} /> : <div>Loading Scoring data...</div>
      }
    } else {
      return <Empty label={translation.no_ranking} />
    }
  }

  const renderStats = () => {
    if (!event.is_ended) {
      return <Empty label={translation.no_stats} />;
    }

    if (event.scoring_type === "Type_3") {
      return tsfStatisticsLoading ?
        <div>{translation.loading_statistics || "Loading statistics..."}</div> :
        <TSFStatistics statistics={tsfStatistics} translation={translation} />;
    } else if (event.scoring_type === "Type_2") {
      return <EventTaStats eventId={event.id} token={user.token} translation={translation} />;
    } else {
      return <EventStats eventId={event.id} token={user.token} translation={translation} />;
    }
  };

  const renderContestatii = () => {
    return <ReportsTable data={contestatii} refreshReports={refreshReports} />
  }

  const renderTabContent = () => {
    switch (selectedTab) {
      case 0:
        return renderGeneralView()
      case 1:
        return renderFishScoring()
      case 2:
        return renderPrizes()
      case 3:
        return renderEnrollments()
      case 4:
        return renderScoring()
      case 5:
        return renderStats()
      case 6:
        return renderContestatii()
      default:
        return null
    }
  }

  return (
    <>
      <div className={`page-container container-fluid bg-white`}>
        {event && (
          <Helmet>
            <meta property="og:image" content={event.event_logo} />
            <title>{event.event_name}</title>
          </Helmet>
        )}
        <div className="row gx-3 g-3">
          <Tabs
            tabOptions={[
              translation.general,
              translation.scoring,
              translation.prizes,
              translation.enrollments,
              translation.ranking,
              translation.stats,
              translation.contestatii,
            ]}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            key={`tab_reference`}
          />
          <EventActions
            selectedTab={selectedTab}
            eventId={event?.id}
            event={event}
            is_active={event?.is_active}
            is_owner={event?.is_owner}
            is_ongoing={event?.is_ongoing}
            enrollment_status={event?.enrollment_status}
            is_validator={event?.validator === user?.account?.id}
            refreshEvent={refreshEvent}
            handleGenerateLineup={handleGenerateLineup}
            isGeneratingLineup={isGeneratingLineup}
            handleGenerateMatches={handleGenerateMatches}
            isGeneratingMatches={isGeneratingMatches}
            lineupExists={lineupMatchExists.lineup}
            matchExists={lineupMatchExists.match}
            handleDownloadLineup={handleDownloadLineup}
            handleDownloadTAScoreboard={handleDownloadTAScoreboard}
            handleDownloadTAStatistics={handleDownloadTAStatistics}
            handleEventSoftDelete={handleEventSoftDelete}
            handleGenerateTSFGeneralLineup={handleGenerateTSFGeneralLineup}
            isGeneratingTSFGeneralLineup={isGeneratingTSFGeneralLineup}
            handleDownloadTSFLineup={handleDownloadTSFLineup}
            handleExportTSFRanking={handleExportTSFRanking}
            handleDownloadTSFSummary={handleDownloadTSFSummary}
            lineupMatchExists={lineupMatchExists}
            navigateToEventOngoing={navigateToEventOngoing}
          />
          {renderTabContent()}
          <Modal isOpen={open} onClose={() => setOpen(false)} title={`Catch map for ${event?.event_name}`}>
            <MyEventCatchMap eventId={event?.id} />
          </Modal>
          {openEventBanner && <EditEventBanner eventId={event?.id} onUploadDone={() => setOpenEventBanner(false)} />}
        </div>
      </div>
    </>
  )
}

const mapStateToProps = (state) => ({
  event: state.eventState.event,
  eventLoading: state.eventState.eventLoading,
  menuOpen: state.appState.menuOpen,
  translation: state.appState.translation,
  sponsors: state.utilsState.sponsors,
  fish_types: state.utilsState.fish_types,
  fishingspots: state.utilsState.fishingspots,
  locations: state.utilsState.locations,
  user: state.auth.user,
  scoring: state.eventsState.scoring,
  contestatii: state.eventsState.contestatii,
  taScoring: state.eventState.taScoring,
  taScoringLoading: state.eventState.taScoringLoading,
})

const mapDispatchToProps = (dispatch) => {
  return {
    loadEvent: (eventId) => {
      dispatch(loadEvent(eventId))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(Event))

