import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  USER_LOADED_SUCCESS,
  USER_LOADED_FAIL,
  AUTHENTICATED_SUCCESS,
  AUTHENTICATED_FAIL,
  PASSWORD_RESET_SUCCESS,
  PASSWORD_RESET_FAIL,
  PASSWORD_RESET_CONFIRM_SUCCESS,
  PASSWORD_RESET_CONFIRM_FAIL,
  SIGNUP_SUCCESS,
  SIGNUP_FAIL,
  ACTIVATION_SUCCESS,
  ACTIVATION_FAIL,
  GOOGLE_AUTH_SUCCESS,
  GOOGLE_AUTH_FAIL,
  FACEBOOK_AUTH_SUCCESS,
  FACEBOOK_AUTH_FAIL,
  LOGOUT,
  LOGOUT_FINISHED,
  USER_LOAD,
  LOGIN_RESET,
  CLEAR_USER_PROFILE,
} from "../actions/types"

const initialState = {
  access: localStorage.getItem("access"),
  refresh: localStorage.getItem("refresh"),
  isAuthenticated: false,
  user: null,
  is_organiser: false,
  is_validator: false,
  is_angler: false,
  is_administrator: false,
  failedAuth: false,
  logout: false,
  tokenRefresh: true,
}

function authReducer(state = initialState, action) {
  const { type, payload } = action
  switch (type) {
    case AUTHENTICATED_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        failedAuth: false,
        tokenRefresh: true,
      }
    case LOGIN_SUCCESS:
    case GOOGLE_AUTH_SUCCESS:
    case FACEBOOK_AUTH_SUCCESS:
      localStorage.setItem("access", payload.access)
      localStorage.setItem("refresh", payload.refresh)
      return {
        ...state,
        isAuthenticated: true,
        access: payload.access,
        refresh: payload.refresh,
        failedAuth: false,
        tokenRefresh: true,
      }
    case SIGNUP_SUCCESS:
      return {
        ...state,
        isAuthenticated: false,
        failedAuth: false,
        tokenRefresh: true,
      }
    case USER_LOAD:
      return {
        ...state,
        isAuthenticated: true,
        loading: true,
      }
    case USER_LOADED_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        user: payload,
        is_organiser: payload.is_organiser,
        is_validator: payload.is_validator,
        is_angler: payload.is_angler,
        is_administrator: payload.is_administrator,
        failedAuth: false,
      }
    case AUTHENTICATED_FAIL:
      return {
        ...state,
        isAuthenticated: false,
        failedAuth: true,
      }
    case USER_LOADED_FAIL:
      return {
        ...state,
        user: null,
        failedAuth: true,
      }
    case GOOGLE_AUTH_FAIL:
    case FACEBOOK_AUTH_FAIL:
    case LOGIN_RESET:
      return {
        ...state,
        failedAuth: false,
      }
    case LOGIN_FAIL:
      localStorage.removeItem("access")
      localStorage.removeItem("refresh")
      return {
        ...state,
        isAuthenticated: false,
        failedAuth: true,
      }
    case SIGNUP_FAIL:
    case LOGOUT:
      localStorage.removeItem("access")
      localStorage.removeItem("refresh")
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        logout: true,
        failedAuth: true,
      }
    case LOGOUT_FINISHED:
      return {
        ...state,
        logout: false,
      }
    case CLEAR_USER_PROFILE:
      return {
        ...state,
        user: null,
        is_organiser: false,
        is_validator: false,
        is_angler: false,
        is_administrator: false,
      }
    case PASSWORD_RESET_SUCCESS:
    case PASSWORD_RESET_FAIL:
    case PASSWORD_RESET_CONFIRM_SUCCESS:
    case PASSWORD_RESET_CONFIRM_FAIL:
    case ACTIVATION_SUCCESS:
    case ACTIVATION_FAIL:
      return {
        ...state,
      }
    default:
      return state
  }
}

export default authReducer

