import React, { useState, useEffect } from "react"
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  List,
  ListItem,
  ListItemText,
  Collapse,
  IconButton,
  Typography,
  Divider,
  CircularProgress,
  TextField,
} from "@mui/material"
import { ExpandMore, ExpandLess, Edit, Delete, Add, Close } from "@mui/icons-material"
import axios from "axios"
import { toast } from "react-toastify"
import getAxiosConfig from "../utils/axiosConfig"

const BonusPoints = ({ eventId, isOwner, translation, event }) => {
  const [open, setOpen] = useState(false)
  const [bonusPoints, setBonusPoints] = useState([])
  const [expandedBonus, setExpandedBonus] = useState(null)
  const [loading, setLoading] = useState(true)
  const [addBonusDialogOpen, setAddBonusDialogOpen] = useState(false)
  const [editBonusDialogOpen, setEditBonusDialogOpen] = useState(false)
  const [newSpeciesCount, setNewSpeciesCount] = useState("")
  const [newBonusPoints, setNewBonusPoints] = useState("")
  const [editingBonusId, setEditingBonusId] = useState(null)
  const [editingSpeciesCount, setEditingSpeciesCount] = useState("")
  const [editingBonusPoints, setEditingBonusPoints] = useState("")
  const [addingBonus, setAddingBonus] = useState(false)
  const [editingBonus, setEditingBonus] = useState(false)

  useEffect(() => {
    if (open) {
      fetchBonusPoints()
    }
  }, [open])

  const fetchBonusPoints = async () => {
    setLoading(true)
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/v2/event-species-bonus/${eventId}/`,
        getAxiosConfig(),
      )
      setBonusPoints(response.data)
    } catch (error) {
      console.error("Error fetching bonus points data", error)
      toast.error("Failed to fetch bonus points data")
    } finally {
      setLoading(false)
    }
  }

  const handleToggle = (bonusId) => {
    setExpandedBonus(expandedBonus === bonusId ? null : bonusId)
  }

  const handleAddBonus = async () => {
    if (!newSpeciesCount.trim() || !newBonusPoints.trim()) {
      toast.error("Species count and bonus points cannot be empty")
      return
    }

    setAddingBonus(true)
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/v2/event-species-bonus/create/`,
        {
          event: eventId,
          species_count: Number.parseInt(newSpeciesCount),
          bonus_points: Number.parseInt(newBonusPoints),
        },
        getAxiosConfig(),
      )

      await fetchBonusPoints()
      setNewSpeciesCount("")
      setNewBonusPoints("")
      setAddBonusDialogOpen(false)
      toast.success("Bonus points added successfully")
    } catch (error) {
      console.error("Error adding new bonus points", error)
       if (error.response && error.response.data && error.response.data.detail) {
        toast.error(error.response.data.detail[0])
      } else {
         toast.error("An error occurred while adding bonus points. Please try again.")
       }
    } finally {
      setAddingBonus(false)
    }
  }

  const handleEditBonus = async () => {
    if (!editingSpeciesCount.trim() || !editingBonusPoints.trim()) {
      toast.error("Species count and bonus points cannot be empty")
      return
    }

    setEditingBonus(true)
    try {
      await axios.patch(
        `${process.env.REACT_APP_API_URL}/v2/event-species-bonus/update/${editingBonusId}/`,
        {
          event: eventId,
          species_count: Number.parseInt(editingSpeciesCount),
          bonus_points: Number.parseInt(editingBonusPoints),
        },
        getAxiosConfig(),
      )

      await fetchBonusPoints()
      setEditingSpeciesCount("")
      setEditingBonusPoints("")
      setEditingBonusId(null)
      setEditBonusDialogOpen(false)
      toast.success("Bonus points updated successfully")
    } catch (error) {
      console.error("Error editing team", error)
      if (error.response && error.response.data && error.response.data.detail) {
        toast.error(error.response.data.detail[0])
      } else {
        toast.error("An error occurred while editing bonus points. Please try again.")
      }
    } finally {
      setEditingBonus(false)
    }
  }

  const handleDeleteBonus = async (bonusId) => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/v2/event-species-bonus/delete/${bonusId}/`, {
        ...getAxiosConfig(),
        data: {
          event_id: eventId,
        },
      })

      await fetchBonusPoints()
      toast.success("Bonus points deleted successfully")
    } catch (error) {
      console.error("Error deleting bonus points", error)
      toast.error("An error occurred while deleting bonus points. Please try again.")
    }
  }

  return (
    <>
      <Button
        variant="contained"
        className="galben"
        onClick={() => setOpen(true)}
      >
        {translation.bonus_points}
      </Button>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="md"
        fullWidth
        PaperProps={{
          style: {
            backgroundColor: "#f5f5f5",
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
          },
        }}
      >
        <DialogTitle>
          <Typography variant="h6" component="div" style={{ fontWeight: "bold", color: "#333" }}>
            {translation.bonus_points}
          </Typography>
        </DialogTitle>
        <Divider />
        <DialogContent>
          {loading ? (
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "200px" }}>
              <CircularProgress />
            </div>
          ) : bonusPoints.length === 0 ? (
            <Typography variant="body2" style={{ color: "#666", fontStyle: "italic" }}>
              {translation.no_bonus_points}
            </Typography>
          ) : (
            <List>
              {bonusPoints.map((bonus) => (
                <React.Fragment key={bonus.id}>
                  <ListItem
                    style={{
                      backgroundColor: "white",
                      marginBottom: "8px",
                      borderRadius: "4px",
                      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.05)",
                    }}
                  >
                    <ListItemText
                      primary={
                        <Typography variant="subtitle1" style={{ fontWeight: "bold", color: "#444" }}>
                          {`${bonus.species_count} ${translation.species_p}: ${bonus.bonus_points} ${translation.points}`}
                        </Typography>
                      }
                    />
                    <IconButton size="small" onClick={() => handleToggle(bonus.id)}>
                      {expandedBonus === bonus.id ? <ExpandLess /> : <ExpandMore />}
                    </IconButton>
                    {isOwner && !event.is_ongoing && !event.is_ended && (
                      <>
                        <IconButton
                          size="small"
                          onClick={() => {
                            setEditingBonusId(bonus.id)
                            setEditingSpeciesCount(bonus.species_count.toString())
                            setEditingBonusPoints(bonus.bonus_points.toString())
                            setEditBonusDialogOpen(true)
                          }}
                        >
                          <Edit fontSize="small" />
                        </IconButton>
                        <IconButton size="small" onClick={() => handleDeleteBonus(bonus.id)}>
                          <Delete fontSize="small" />
                        </IconButton>
                      </>
                    )}
                  </ListItem>
                  <Collapse in={expandedBonus === bonus.id} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      <ListItem sx={{ pl: 4, py: 0.5 }}>
                        <ListItemText
                          primary={
                            <Typography variant="body2" style={{ color: "#666" }}>
                              {`${translation.species_count}: ${bonus.species_count}`}
                            </Typography>
                          }
                        />
                      </ListItem>
                      <ListItem sx={{ pl: 4, py: 0.5 }}>
                        <ListItemText
                          primary={
                            <Typography variant="body2" style={{ color: "#666" }}>
                              {`${translation.bonus_points}: ${bonus.bonus_points}`}
                            </Typography>
                          }
                        />
                      </ListItem>
                    </List>
                  </Collapse>
                </React.Fragment>
              ))}
            </List>
          )}
        </DialogContent>
        <Divider />
        <DialogActions style={{ padding: "16px" }}>
          {isOwner && !event.is_ongoing && !event.is_ended && (
            <Button
              onClick={() => setAddBonusDialogOpen(true)}
              startIcon={<Add />}
              variant="contained"
            >
              {translation.add_bonus}
            </Button>
          )}
          <Button onClick={() => setOpen(false)} startIcon={<Close />} variant="outlined">
            {translation.close}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Add Bonus Dialog */}
      <Dialog open={addBonusDialogOpen} onClose={() => setAddBonusDialogOpen(false)}>
        <DialogTitle>{translation.add_bonus}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label={translation.species_count}
            type="number"
            fullWidth
            value={newSpeciesCount}
            onChange={(e) => setNewSpeciesCount(e.target.value)}
          />
          <TextField
            margin="dense"
            label={translation.bonus_points}
            type="number"
            fullWidth
            value={newBonusPoints}
            onChange={(e) => setNewBonusPoints(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAddBonusDialogOpen(false)}>{translation.cancel}</Button>
          <Button onClick={handleAddBonus} disabled={addingBonus}>
            {addingBonus ? <CircularProgress size={24} /> : translation.add}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Edit Bonus Dialog */}
      <Dialog open={editBonusDialogOpen} onClose={() => setEditBonusDialogOpen(false)}>
        <DialogTitle>{translation.edit_bonus}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label={translation.species_count}
            type="number"
            fullWidth
            value={editingSpeciesCount}
            onChange={(e) => setEditingSpeciesCount(e.target.value)}
          />
          <TextField
            margin="dense"
            label={translation.bonus_points}
            type="number"
            fullWidth
            value={editingBonusPoints}
            onChange={(e) => setEditingBonusPoints(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditBonusDialogOpen(false)}>{translation.cancel}</Button>
          <Button onClick={handleEditBonus} disabled={editingBonus}>
            {editingBonus ? <CircularProgress size={24} /> : translation.save}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default BonusPoints

