import { useState } from "react"
import { connect } from "react-redux"
import { reset_password_confirm } from "../actions/auth"
import { Link, useNavigate, useParams } from "react-router-dom"
import { Visibility, VisibilityOff } from "@mui/icons-material"
import { Container, Box, Typography, TextField, Button, Paper, InputAdornment, IconButton, Alert } from "@mui/material"
import axios from "axios"

const ResetPasswordConfirm = ({ reset_password_confirm, translation }) => {
  const [showPassword, setShowPassword] = useState(false)
  const [formData, setFormData] = useState({
    new_password: "",
    re_new_password: "",
  })
  const [error, setError] = useState("")
  const [success, setSuccess] = useState("")

  const { new_password, re_new_password } = formData

  const navigate = useNavigate()
  const { uid, token } = useParams()

  const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value })

  const onSubmit = async (e) => {
    e.preventDefault()

    if (new_password !== re_new_password) {
      setError(translation.passwords_do_not_match || "Passwords do not match")
      return
    }

    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      }

      const body = JSON.stringify({ uid, token, new_password, re_new_password })
      await axios.post(`${process.env.REACT_APP_API_URL}/auth/users/reset_password_confirm/`, body, config)

      setSuccess(translation.password_reset_successful || "Password reset successful")
      setTimeout(() => navigate("/login"), 3000)
    } catch (e) {
      setError(translation.password_reset_failed || "Password reset failed")
    }
  }

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  return (
    <Container maxWidth="sm">
      <Box sx={{ mt: 4, mb: 4 }}>
        <Paper elevation={3} sx={{ p: 4, borderRadius: 2 }}>
          <Box sx={{ display: "flex", justifyContent: "center", mb: 3 }}>
            <img
              src="https://streetfishing.fra1.cdn.digitaloceanspaces.com/media/assets/logo_orange_yellow_transparent.png"
              alt="logo"
              style={{ width: "100%", maxWidth: "300px" }}
            />
          </Box>

          <Typography
            variant="h4"
            component="h1"
            gutterBottom
            align="center"
            sx={{ fontWeight: "bold", color: "primary.main" }}
          >
            {translation.reset_password_title || "Reset Password"}
          </Typography>
          <Typography variant="subtitle1" gutterBottom align="center" sx={{ mb: 3 }}>
            {translation.reset_password_subtitle || "Enter your new password below"}
          </Typography>

          <Box component="form" onSubmit={onSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              name="new_password"
              label={translation.new_password || "New Password"}
              type={showPassword ? "text" : "password"}
              id="new_password"
              autoComplete="new-password"
              value={new_password}
              onChange={onChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} edge="end">
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="re_new_password"
              label={translation.confirm_new_password || "Confirm New Password"}
              type={showPassword ? "text" : "password"}
              id="re_new_password"
              autoComplete="new-password"
              value={re_new_password}
              onChange={onChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} edge="end">
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mt: 2 }}>
              <Button
                type="submit"
                variant="contained"
                sx={{
                  py: 1.5,
                  px: 4,
                  fontSize: "1rem",
                  fontWeight: "bold",
                  backgroundColor: "green",
                  "&:hover": {
                    backgroundColor: "darkgreen",
                  },
                }}
              >
                {translation.reset_password_button || "Reset Password"}
              </Button>
              <Button component={Link} to="/login" color="primary">
                {translation.login}
              </Button>
            </Box>
          </Box>

          {error && (
            <Alert severity="error" sx={{ mt: 2 }}>
              {error}
            </Alert>
          )}
          {success && (
            <Alert severity="success" sx={{ mt: 2 }}>
              {success}
            </Alert>
          )}
        </Paper>
      </Box>
    </Container>
  )
}

const mapStateToProps = (state) => ({
  translation: state.appState.translation,
})

const mapDispatchToProps = (dispatch) => ({
  reset_password_confirm: (uid, token, new_password, re_new_password) =>
    dispatch(reset_password_confirm(uid, token, new_password, re_new_password)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordConfirm)

