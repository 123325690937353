import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Box, Typography } from "@mui/material"
import ClubCard from "../components/ClubCard"
import { fetchClubs } from "../actions/clubActions"
import Loader from "../components/Loader";

const Clubs = () => {
  const dispatch = useDispatch()
  const clubsState = useSelector((state) => state.clubs)
  const { clubs, loading, error } = clubsState || {}

  useEffect(() => {
    dispatch(fetchClubs())
  }, [dispatch])

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Loader />
      </Box>
    )
  }

  if (error) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Typography color="error">{error}</Typography>
      </Box>
    )
  }

  return (
    <Box sx={{ maxWidth: 800, margin: "auto", padding: 2 }}>
      <Typography variant="h4" component="h1" gutterBottom>
        Clubs
      </Typography>
      {clubs && clubs.map((club) => <ClubCard key={club.id} club={club} />)}
    </Box>
  )
}

export default Clubs

