import axios from "axios"
export const FETCH_GAME_CARDS_REQUEST = "FETCH_GAME_CARDS_REQUEST"
export const FETCH_GAME_CARDS_SUCCESS = "FETCH_GAME_CARDS_SUCCESS"
export const FETCH_GAME_CARDS_FAILURE = "FETCH_GAME_CARDS_FAILURE"
export const UPDATE_MATCH_REQUEST = "UPDATE_MATCH_REQUEST"
export const UPDATE_MATCH_SUCCESS = "UPDATE_MATCH_SUCCESS"
export const UPDATE_MATCH_FAILURE = "UPDATE_MATCH_FAILURE"
export const GENERATE_LINEUP_REQUEST = "GENERATE_LINEUP_REQUEST"
export const GENERATE_LINEUP_SUCCESS = "GENERATE_LINEUP_SUCCESS"
export const GENERATE_LINEUP_FAILURE = "GENERATE_LINEUP_FAILURE"
export const GENERATE_MATCHES_REQUEST = "GENERATE_MATCHES_REQUEST"
export const GENERATE_MATCHES_SUCCESS = "GENERATE_MATCHES_SUCCESS"
export const GENERATE_MATCHES_FAILURE = "GENERATE_MATCHES_FAILURE"
export const CHECK_LINEUP_MATCH_EXISTS_REQUEST = "CHECK_LINEUP_MATCH_EXISTS_REQUEST"
export const CHECK_LINEUP_MATCH_EXISTS_SUCCESS = "CHECK_LINEUP_MATCH_EXISTS_SUCCESS"
export const CHECK_LINEUP_MATCH_EXISTS_FAILURE = "CHECK_LINEUP_MATCH_EXISTS_FAILURE"
export const DOWNLOAD_LINEUP_REQUEST = "DOWNLOAD_LINEUP_REQUEST"
export const DOWNLOAD_LINEUP_SUCCESS = "DOWNLOAD_LINEUP_SUCCESS"
export const DOWNLOAD_LINEUP_FAILURE = "DOWNLOAD_LINEUP_FAILURE"
export const FETCH_USER_GAME_CARD_REQUEST = "FETCH_USER_GAME_CARD_REQUEST"
export const FETCH_USER_GAME_CARD_SUCCESS = "FETCH_USER_GAME_CARD_SUCCESS"
export const FETCH_USER_GAME_CARD_FAILURE = "FETCH_USER_GAME_CARD_FAILURE"
export const FETCH_UNVALIDATED_MATCHES_REQUEST = "FETCH_UNVALIDATED_MATCHES_REQUEST"
export const FETCH_UNVALIDATED_MATCHES_SUCCESS = "FETCH_UNVALIDATED_MATCHES_SUCCESS"
export const FETCH_UNVALIDATED_MATCHES_FAILURE = "FETCH_UNVALIDATED_MATCHES_FAILURE"

export const UPDATE_MATCH_ORGANIZER_REQUEST = "UPDATE_MATCH_ORGANIZER_REQUEST"
export const UPDATE_MATCH_ORGANIZER_SUCCESS = "UPDATE_MATCH_ORGANIZER_SUCCESS"
export const UPDATE_MATCH_ORGANIZER_FAILURE = "UPDATE_MATCH_ORGANIZER_FAILURE"
export const DOWNLOAD_TA_STATISTICS_REQUEST = "DOWNLOAD_TA_STATISTICS_REQUEST"
export const DOWNLOAD_TA_STATISTICS_SUCCESS = "DOWNLOAD_TA_STATISTICS_SUCCESS"
export const DOWNLOAD_TA_STATISTICS_FAILURE = "DOWNLOAD_TA_STATISTICS_FAILURE"

export const DOWNLOAD_TA_SCOREBOARD_REQUEST = "DOWNLOAD_TA_SCOREBOARD_REQUEST"
export const DOWNLOAD_TA_SCOREBOARD_SUCCESS = "DOWNLOAD_TA_SCOREBOARD_SUCCESS"
export const DOWNLOAD_TA_SCOREBOARD_FAILURE = "DOWNLOAD_TA_SCOREBOARD_FAILURE"

const downloadFile = (data, fileName) => {
  const url = window.URL.createObjectURL(new Blob([data]))
  const link = document.createElement("a")
  link.href = url
  link.setAttribute("download", fileName)
  document.body.appendChild(link)
  link.click()
  link.remove()
}

const formatDate = (date) => {
  return new Date(date)
    .toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
    })
    .replace(/\//g, "-")
}

export const fetchUnvalidatedMatches = (eventId) => async (dispatch) => {
  dispatch({ type: FETCH_UNVALIDATED_MATCHES_REQUEST })

  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/v2/events/${eventId}/unvalidated-matches/`, {
      headers: {
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    })
    dispatch({ type: FETCH_UNVALIDATED_MATCHES_SUCCESS, payload: response.data })
  } catch (error) {
    dispatch({ type: FETCH_UNVALIDATED_MATCHES_FAILURE, payload: error.message })
  }
}

export const updateMatchOrganizer = (eventId, matchId, update) => async (dispatch) => {
  dispatch({ type: UPDATE_MATCH_ORGANIZER_REQUEST })

  try {
    const response = await axios.patch(
      `${process.env.REACT_APP_API_URL}/v2/events/${eventId}/matches/${matchId}/organizer-update/`,
      update,
      {
        headers: {
          Authorization: `JWT ${localStorage.getItem("access")}`,
        },
      },
    )
    dispatch({ type: UPDATE_MATCH_ORGANIZER_SUCCESS, payload: response.data })
    return { success: true, message: "Match updated successfully" }
  } catch (error) {
    dispatch({ type: UPDATE_MATCH_ORGANIZER_FAILURE, payload: error.message })
    return { success: false, message: error.message }
  }
}

export const fetchGameCards = (eventId) => async (dispatch) => {
  dispatch({ type: FETCH_GAME_CARDS_REQUEST })

  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/v2/event/${eventId}/my-game-cards/`, {
      headers: {
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    })

    dispatch({
      type: FETCH_GAME_CARDS_SUCCESS,
      payload: response.data,
    })
  } catch (error) {
    dispatch({
      type: FETCH_GAME_CARDS_FAILURE,
      payload: error.message,
    })
  }
}

export const updateMatch = (eventId, matchId, update) => async (dispatch) => {
  dispatch({ type: UPDATE_MATCH_REQUEST })

  try {
    const response = await axios.patch(
      `${process.env.REACT_APP_API_URL}/v2/event/${eventId}/matches/${matchId}/update/`,
      update,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${localStorage.getItem("access")}`,
        },
      },
    )

    dispatch({ type: UPDATE_MATCH_SUCCESS })
    dispatch(fetchGameCards(eventId))
    return { success: true, message: response.data.detail }
  } catch (error) {
    const errorMessage = error.response?.data?.detail || error.message
    dispatch({
      type: UPDATE_MATCH_FAILURE,
      payload: errorMessage,
    })
    return { error: errorMessage }
  }
}

export const createTALineup = (eventId) => async (dispatch) => {
  dispatch({ type: GENERATE_LINEUP_REQUEST })

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/v2/event/${eventId}/create-ta-lineup/`,
      { event: eventId },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${localStorage.getItem("access")}`,
        },
      },
    )

    dispatch({
      type: GENERATE_LINEUP_SUCCESS,
      payload: response.data,
    })

    dispatch(fetchGameCards(eventId))

    return { success: true, message: response.data.detail || "Lineup generated successfully" }
  } catch (error) {
    const errorMessage = error.response?.data?.detail || error.message
    dispatch({
      type: GENERATE_LINEUP_FAILURE,
      payload: errorMessage,
    })
    return { error: errorMessage }
  }
}

export const generateMatches = (eventId) => async (dispatch) => {
  dispatch({ type: GENERATE_MATCHES_REQUEST })

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/v2/event/${eventId}/generate-matches/`,
      { event: eventId },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${localStorage.getItem("access")}`,
        },
      },
    )

    dispatch({
      type: GENERATE_MATCHES_SUCCESS,
      payload: response.data,
    })

    dispatch(fetchGameCards(eventId))

    return { success: true, message: response.data.detail || "Matches generated successfully" }
  } catch (error) {
    const errorMessage = error.response?.data?.detail || error.message
    dispatch({
      type: GENERATE_MATCHES_FAILURE,
      payload: errorMessage,
    })
    return { error: errorMessage }
  }
}


export const checkLineupMatchExists = (eventId) => async (dispatch) => {
  dispatch({ type: CHECK_LINEUP_MATCH_EXISTS_REQUEST })

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/v2/events/${eventId}/check-lineup-match-exists/`,
      {
        headers: {
          Authorization: `JWT ${localStorage.getItem("access")}`,
        },
      },
    )

    dispatch({
      type: CHECK_LINEUP_MATCH_EXISTS_SUCCESS,
      payload: response.data,
    })

    return { success: true, data: response.data }
  } catch (error) {
    const errorMessage = error.response?.data?.detail || error.message
    dispatch({
      type: CHECK_LINEUP_MATCH_EXISTS_FAILURE,
      payload: errorMessage,
    })
    return { error: errorMessage }
  }
}

export const downloadLineup = (eventId, eventName, startDate) => async (dispatch) => {
  dispatch({ type: DOWNLOAD_LINEUP_REQUEST })

  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/v2/event/${eventId}/export-lineup-xls/`, {
      headers: {
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
      responseType: "blob",
    })

    const formattedDate = new Date(startDate)
      .toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "2-digit",
      })
      .replace(/\//g, "-")

    const fileName = `${eventName}_${formattedDate}.xlsx`

    const url = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement("a")
    link.href = url
    link.setAttribute("download", fileName)
    document.body.appendChild(link)
    link.click()
    link.remove()

    dispatch({
      type: DOWNLOAD_LINEUP_SUCCESS,
    })

    return { success: true, message: "Lineup downloaded successfully" }
  } catch (error) {
    const errorMessage = error.response?.data?.detail || error.message
    dispatch({
      type: DOWNLOAD_LINEUP_FAILURE,
      payload: errorMessage,
    })
    return { error: errorMessage }
  }
}

export const fetchUserGameCard = (eventId, userId) => async (dispatch) => {
  dispatch({ type: FETCH_USER_GAME_CARD_REQUEST })

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/v2/event/${eventId}/user-game-cards/${userId}/`,
      {
        headers: {
          Authorization: `JWT ${localStorage.getItem("access")}`,
        },
      },
    )

    dispatch({
      type: FETCH_USER_GAME_CARD_SUCCESS,
      payload: response.data,
    })
  } catch (error) {
    dispatch({
      type: FETCH_USER_GAME_CARD_FAILURE,
      payload: error.message,
    })
  }
}

export const downloadTAStatistics = (eventId, eventName, startDate) => async (dispatch) => {
  dispatch({ type: DOWNLOAD_TA_STATISTICS_REQUEST })

  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/v2/export-event-ta-statistics/${eventId}/`, {
      headers: {
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
      responseType: "blob",
    })

    const formattedDate = formatDate(startDate)
    const fileName = `${eventName}_TA_Statistics_${formattedDate}.xlsx`

    downloadFile(response.data, fileName)

    dispatch({ type: DOWNLOAD_TA_STATISTICS_SUCCESS })

    return { success: true, message: "TA Statistics downloaded successfully" }
  } catch (error) {
    const errorMessage = error.response?.data?.detail || error.message
    dispatch({
      type: DOWNLOAD_TA_STATISTICS_FAILURE,
      payload: errorMessage,
    })
    return { error: errorMessage }
  }
}

export const downloadTAScoreboard = (eventId, eventName, startDate) => async (dispatch) => {
  dispatch({ type: DOWNLOAD_TA_SCOREBOARD_REQUEST })

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/v2/export-ta-event-scoreboard/${eventId}/?export_format=excel`,
      {
        headers: {
          Authorization: `JWT ${localStorage.getItem("access")}`,
        },
        responseType: "blob",
      },
    )

    const formattedDate = formatDate(startDate)
    const fileName = `${eventName}_TA_Scoreboard_${formattedDate}.xlsx`

    downloadFile(response.data, fileName)

    dispatch({ type: DOWNLOAD_TA_SCOREBOARD_SUCCESS })

    return { success: true, message: "TA Scoreboard downloaded successfully" }
  } catch (error) {
    const errorMessage = error.response?.data?.detail || error.message
    dispatch({
      type: DOWNLOAD_TA_SCOREBOARD_FAILURE,
      payload: errorMessage,
    })
    return { error: errorMessage }
  }
}

