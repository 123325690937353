import React from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { postPrizes } from '../actions/eventsState';
import { Box, Typography, Button, Container } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledContainer = styled(Container)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '100vh',
  padding: theme.spacing(3),
}));

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(3),
}));

const EventFormSuccess = ({ translation }) => {
  const navigate = useNavigate();

  return (
    <StyledContainer maxWidth="sm">
      <Box textAlign="center">
        <Typography variant="h5" gutterBottom>
          {translation.success_message}
        </Typography>
        <StyledButton
          variant="contained"
          color="primary"
          onClick={() => navigate('/organizer-dashboard')}
        >
          {translation.cta_goToMyEvents}
        </StyledButton>
      </Box>
    </StyledContainer>
  );
};

const mapStateToProps = state => ({
  translation: state.appState.translation,
});

const mapDispatchToProps = (dispatch) => {
  return {
    postPrizes: (stepThreeData) => dispatch(postPrizes(stepThreeData))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(EventFormSuccess));
