"use client"

import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { searchUsers, resetUserPasswordAdmin, toggleUserStatus } from "../actions/adminActions"
import {
  Box,
  TextField,
  Button,
  Typography,
  List,
  ListItem,
  ListItemText,
  Switch,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  InputAdornment,
  useMediaQuery,
  useTheme,
  Grid,
  Paper,
} from "@mui/material"
import { Visibility, VisibilityOff, LockReset as LockResetIcon } from "@mui/icons-material"

const Admin = () => {
  const dispatch = useDispatch()
  const { searchResults, error } = useSelector((state) => state.admin)
  const [searchTerm, setSearchTerm] = useState("")
  const [selectedUser, setSelectedUser] = useState(null)
  const [newPassword, setNewPassword] = useState("")
  const [showPassword, setShowPassword] = useState(false)
  const [isPasswordDialogOpen, setIsPasswordDialogOpen] = useState(false)
  const [localUserStates, setLocalUserStates] = useState({})

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

  const handleSearchUsers = () => {
    dispatch(searchUsers(searchTerm))
  }

  const handleToggleUserStatus = (userId, currentStatus) => {
    dispatch(toggleUserStatus(userId, !currentStatus))
    setLocalUserStates((prev) => ({
      ...prev,
      [userId]: { ...prev[userId], is_active: !currentStatus },
    }))
  }

  const handleOpenPasswordDialog = (user) => {
    setSelectedUser(user)
    setIsPasswordDialogOpen(true)
  }

  const handleClosePasswordDialog = () => {
    setSelectedUser(null)
    setNewPassword("")
    setShowPassword(false)
    setIsPasswordDialogOpen(false)
  }

  const handleResetPassword = () => {
    dispatch(resetUserPasswordAdmin(selectedUser.id, newPassword))
    handleClosePasswordDialog()
  }

  const toggleShowPassword = () => {
    setShowPassword(!showPassword)
  }

  return (
    <Box sx={{ maxWidth: "100%", margin: "auto", padding: isMobile ? 2 : 3 }}>
      <Typography variant={isMobile ? "h5" : "h4"} gutterBottom>
        Admin Panel
      </Typography>

      <Paper elevation={3} sx={{ p: 2, mb: 4 }}>
        <Typography variant="h6" gutterBottom>
          Search Users
        </Typography>
        <TextField
          label="Search by First Name or Last Name"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          fullWidth
          margin="normal"
        />
        <Button variant="contained" onClick={handleSearchUsers} sx={{ mt: 1, width: "100%" }}>
          Search Users
        </Button>
        {error && (
          <Alert severity="error" sx={{ mt: 2 }}>
            {error}
          </Alert>
        )}
      </Paper>

      {searchResults && searchResults.length > 0 && (
        <Paper elevation={3} sx={{ p: 2, mb: 4 }}>
          <Typography variant="h6" gutterBottom>
            Search Results
          </Typography>
          <List>
            {searchResults.map((user) => {
              const localState = localUserStates[user.id] || user
              return (
                <ListItem
                  key={user.id}
                  alignItems="flex-start"
                  sx={{ flexDirection: "column", alignItems: "stretch", borderBottom: "1px solid #e0e0e0", py: 2 }}
                >
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} sm={6}>
                      <ListItemText
                        primary={`${user.first_name} ${user.last_name}`}
                        secondary={
                          <React.Fragment>
                            <Typography component="span" variant="body2" color="text.primary" display="block">
                              ID: {user.id} | Status: {localState.is_active ? "Active" : "Inactive"}
                            </Typography>
                            <Typography component="span" variant="body2" color="text.secondary" display="block">
                              Email: {user.email}
                            </Typography>
                          </React.Fragment>
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Box
                        sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center", mt: isMobile ? 2 : 0 }}
                      >
                        <Switch
                          edge="end"
                          onChange={() => handleToggleUserStatus(user.id, localState.is_active)}
                          checked={localState.is_active}
                        />
                        <Button
                          variant="outlined"
                          startIcon={<LockResetIcon />}
                          onClick={() => handleOpenPasswordDialog(user)}
                          sx={{ ml: 2 }}
                          size={isMobile ? "small" : "medium"}
                        >
                          Reset Password
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </ListItem>
              )
            })}
          </List>
        </Paper>
      )}

      <Dialog open={isPasswordDialogOpen} onClose={handleClosePasswordDialog} fullWidth maxWidth="sm">
        <DialogTitle>
          Reset Password for {selectedUser?.first_name} {selectedUser?.last_name}
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="New Password"
            type={showPassword ? "text" : "password"}
            fullWidth
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton aria-label="toggle password visibility" onClick={toggleShowPassword} edge="end">
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClosePasswordDialog}>Cancel</Button>
          <Button onClick={handleResetPassword} variant="contained" color="primary">
            Reset Password
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}

export default Admin

