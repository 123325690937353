import { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import axios from "axios";

// Actions
import { loadEvent } from "../../actions/eventsState";
import {
  fetchTSFAllRankings,
  fetchTSFFinalRankings,
  updateTSFScoreboard
} from "../../actions/tsfActions";

const useTSFValidateData = (eventId, translation) => {
  const dispatch = useDispatch();

  // Data loading states
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);

  // Data states
  const [groupAssignments, setGroupAssignments] = useState(null);
  const [rankingsData, setRankingsData] = useState(null);
  const [filteredParticipants, setFilteredParticipants] = useState([]);
  const [scoreboardData, setScoreboardData] = useState({});

  // Filters and view options
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [selectedLeg, setSelectedLeg] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [availableGroups, setAvailableGroups] = useState([]);
  const [availableLegs, setAvailableLegs] = useState([]);
  const [rankingView, setRankingView] = useState("global");
  const [selectedRankingGroup, setSelectedRankingGroup] = useState(null);

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      await dispatch(loadEvent(eventId));

      // Fetch all group assignments
      const assignmentsResponse = await axios.get(
        `${process.env.REACT_APP_API_URL}/v2/event/${eventId}/all-group-assignments/`,
        {
          headers: {
            Authorization: `JWT ${localStorage.getItem("access")}`,
          },
        },
      );

      setGroupAssignments(assignmentsResponse.data);

      // Extract available groups and legs
      if (assignmentsResponse.data && assignmentsResponse.data.groups) {
        const groups = assignmentsResponse.data.groups.map((g) => g.group_number);
        setAvailableGroups(groups);

        // Get legs from first group's first participant (assuming all have same leg structure)
        if (assignmentsResponse.data.groups[0]?.participants[0]?.legs) {
          const legs = assignmentsResponse.data.groups[0].participants[0].legs.map((l) => l.leg_number);
          setAvailableLegs(legs);
        }
      }

      // Fetch rankings data from the final rankings endpoint
      try {
        const response = await dispatch(fetchTSFFinalRankings(eventId));
        if (response.success) {
          setRankingsData(response.data);
        }
      } catch (error) {
        console.error("Error loading rankings:", error);
      }

    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error(translation.failed_to_fetch_data || "Failed to fetch data");
    } finally {
      setLoading(false);
    }
  }, [dispatch, eventId, translation]);

  useEffect(() => {
    if (!eventId) return;
    fetchData();
  }, [fetchData, eventId]);

  useEffect(() => {
    // Filter participants based on selected group, leg, and search query
    if (!groupAssignments || !groupAssignments.groups) return;

    let filtered = [];

    if (selectedGroup !== null) {
      const group = groupAssignments.groups.find((g) => g.group_number === selectedGroup);
      if (group) {
        filtered = [...group.participants];
      }
    }

    // Filter by search query if provided
    if (searchQuery) {
      filtered = filtered.filter((p) => p.name.toLowerCase().includes(searchQuery.toLowerCase()));
    }

    setFilteredParticipants(filtered);
  }, [groupAssignments, selectedGroup, searchQuery]);

  const handleScoreChange = (scoreboardId, value) => {
    const numValue = Number.parseInt(value, 10) || 0;
    setScoreboardData((prev) => ({
      ...prev,
      [scoreboardId]: {
        ...prev[scoreboardId],
        fishCaught: numValue,
      },
    }));
  };

  const handleSubmitScore = async (scoreboardId) => {
    const scoreData = scoreboardData[scoreboardId];
    if (scoreData.fishCaught === scoreData.originalValue) {
      return; // No change, don't submit
    }

    setSubmitting(true);
    try {
      const result = await dispatch(updateTSFScoreboard(eventId, scoreboardId, scoreData.fishCaught));
      if (result.success) {
        toast.success(translation.score_updated_successfully || "Score updated successfully");
        // Update original value to match the new value
        setScoreboardData((prev) => ({
          ...prev,
          [scoreboardId]: {
            ...prev[scoreboardId],
            originalValue: scoreData.fishCaught,
          },
        }));
      } else {
        toast.error(result.error || translation.failed_to_update_score || "Failed to update score");
      }
    } catch (error) {
      console.error("Error updating score:", error);
      toast.error(translation.failed_to_update_score || "Failed to update score");
    } finally {
      setSubmitting(false);
    }
  };

  const refreshData = () => {
    fetchData();
    toast.info(translation.data_refreshed || "Data refreshed");
  };

  const handleRankingViewChange = (_, newView) => {
    if (newView !== null) {
      setRankingView(newView);
      if (newView === "group" && !selectedRankingGroup && availableGroups.length > 0) {
        setSelectedRankingGroup(availableGroups[0]);
      }
    }
  };

  return {
    loading,
    submitting,
    groupAssignments,
    rankingsData,
    filteredParticipants,
    scoreboardData,
    selectedGroup,
    setSelectedGroup,
    selectedLeg,
    setSelectedLeg,
    searchQuery,
    setSearchQuery,
    availableGroups,
    availableLegs,
    rankingView,
    selectedRankingGroup,
    setSelectedRankingGroup,
    handleScoreChange,
    handleSubmitScore,
    refreshData,
    handleRankingViewChange
  };
};

export default useTSFValidateData;
