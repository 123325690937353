const initialState = {
  resetPasswordResult: null,
  userStatus: null,
  searchResults: null,
  error: null,
}

const adminReducer = (state = initialState, action) => {
  switch (action.type) {
    case "RESET_PASSWORD_SUCCESS":
      return { ...state, resetPasswordResult: "Password reset successfully", error: null }
    case "RESET_PASSWORD_FAILURE":
      return { ...state, resetPasswordResult: null, error: action.payload }
    case "GET_USER_STATUS_SUCCESS":
      return { ...state, userStatus: action.payload, error: null }
    case "GET_USER_STATUS_FAILURE":
      return { ...state, userStatus: null, error: action.payload }
    case "SEARCH_USERS_SUCCESS":
      return { ...state, searchResults: action.payload, error: null }
    case "SEARCH_USERS_FAILURE":
      return { ...state, searchResults: null, error: action.payload }
    default:
      return state
  }
}

export default adminReducer

