import React from "react";
import { Typography, Box } from "@mui/material";

const EventHeader = ({ translation }) => {
  return (
    <Typography
      component="h1"
      variant="h5"
      color="primary"
      gutterBottom
      sx={{ fontWeight: "bold" }}
    >
      {translation.tsf_event_validation || "TSF Event Validation"}
    </Typography>
  );
};

export default EventHeader;
