import React, { useState } from "react"
import { connect } from "react-redux"
import { updateEvent } from "../actions/eventsState"
import Input from "./../components/Input"
import Textarea from "../components/Textarea"
import Dropdown from "../components/Dropdown"
import DateTimePicker from "../components/DateTimePicker"
import { eventForm } from "../constants/forms"
import MultiDropdown from "../components/MultiDropdown"
import { toast } from "react-toastify"
import axios from "axios"
import getAxiosConfig from "../utils/axiosConfig"
import { Container, Paper, Typography, Grid, Button } from "@mui/material"
import { styled } from "@mui/material/styles"
import DropdownCity from "../components/DropdownCity"
import DropdownFishingSpots from "../components/DropdownFishingSpots"

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginTop: theme.spacing(3),
}))

const StyledForm = styled("form")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(3),
}))

const initialStateForm = {
  event_name: { value: "" },
  details: { value: "" },
  event_location_details: { value: "" },
  event_participation_type: { value: "I" },
  event_type: { value: "S" },
  start_date: { value: new Date() },
  event_duration_hours: { value: "" },
  tournament: { value: null },
  event_location_country: 1,
  event_location_city: 1,
  participation_tax: "10.00",
  participation_tax_currency: 1,
  principal_sponsors: [],
  other_sponsors: [],
  validator: 1,
}

const GeneralEditForm = ({
  translation,
  updateEvent,
  sponsors,
  event,
  onClose,
  refreshEvent,
  locations,
  fishingspots,
}) => {
  const [dirtyForm, setDirtyForm] = useState(false)
  const [editGeneralForm, setEditGeneralForm] = useState(
    Object.keys(event).reduce((acc, current) => {
      if (current.includes("date")) {
        acc.push({ value: new Date(event[current]), dirty: false, name: current })
      } else {
        acc.push({ value: event[current], dirty: false, name: current })
      }
      return acc
    }, []),
  )

  const handleInputChange = (e) => {
    const { name, value } = e.target
    const __editGeneralForm = [...editGeneralForm]

    const searchIndex = editGeneralForm.findIndex((searchElement) => {
      return searchElement.name === name
    })
    __editGeneralForm[searchIndex] = {
      ...__editGeneralForm[searchIndex],
      value,
      dirty: true,
    }
    setEditGeneralForm(__editGeneralForm)
    setDirtyForm(true)
  }

  const handleSponsorChange = (e) => {
    const { name, value } = e.target
    const __editGeneralForm = [...editGeneralForm]
    const sponsorIndex = __editGeneralForm.findIndex((item) => item.name === name)
    const getSponsor = sponsors.find((item) => item.id === Number.parseInt(value))

    if (__editGeneralForm[sponsorIndex].value.find((item) => item.id === Number.parseInt(value))) {
      __editGeneralForm[sponsorIndex].value = __editGeneralForm[sponsorIndex].value.filter(
        (item) => item.id !== Number.parseInt(value),
      )
    } else {
      __editGeneralForm[sponsorIndex].value.push(getSponsor)
    }
    __editGeneralForm[sponsorIndex].dirty = true
    setEditGeneralForm(__editGeneralForm)
    setDirtyForm(true)
  }

  const handleDateChange = (date, name) => {
    const __editGeneralForm = [...editGeneralForm]
    const searchIndex = editGeneralForm.findIndex((searchElement) => {
      return searchElement.name === name
    })
    __editGeneralForm[searchIndex] = {
      ...__editGeneralForm[searchIndex],
      value: new Date(date),
      dirty: true,
    }
    setEditGeneralForm(__editGeneralForm)
    setDirtyForm(true)
  }

  const submitForm = async (e) => {
    e.preventDefault()
    if (validateMandatory(editGeneralForm)) {
      const trimEditGeneralForm = editGeneralForm
        .filter((item) => item.dirty)
        .reduce((acc, current) => {
          if (current.name.split("sponsor").length > 1) {
            acc[current.name] = current.value.reduce((acc, current) => {
              acc.push(current.id)
              return acc
            }, [])
          } else {
            acc[current.name] = current.value
          }
          return acc
        }, {})

      const { id, event_type } = event
      const config = getAxiosConfig()
      await axios.patch(
        `${process.env.REACT_APP_API_URL}/v2/event/update/${id}/`,
        { ...trimEditGeneralForm, id, event_type },
        config,
      )

      onClose()
      toast.success("Modified event, please refresh")
    } else {
      toast.error("Please complete all mandatory fields")
      onClose()
    }
  }

  const validateMandatory = (data) => {
    let validData = true
    const arrayObjectKeys = Object.keys(initialStateForm)

    for (let k = 0; k < arrayObjectKeys.length; k++) {
      const dataIndex = data.findIndex((searchElem) => searchElem.name === arrayObjectKeys[k])
      if (!data[dataIndex]?.value && arrayObjectKeys[k] !== "tournament") {
        validData = false
      }
    }

    return validData
  }

  const renderForm = () =>
    Object.keys(eventForm).map((key, index) => {
      const dataObject = editGeneralForm.find((item) => item.name === key)
      switch (eventForm[key].type) {
        case "input":
        case "number":
          return (
            <Grid item xs={12} sm={6} key={key + index}>
              <Input
                type={eventForm[key].type}
                className="form-control"
                name={eventForm[key].label}
                label={translation[eventForm[key].label]}
                placeholder={translation[eventForm[key].placeholder]}
                value={dataObject.value || ""}
                onChange={handleInputChange}
                formAttr={key}
              />
            </Grid>
          )
        case "textarea":
          return (
            <Grid item xs={12} key={key + index}>
              <Textarea
                type="text"
                className="form-control"
                name={eventForm[key].label}
                label={translation[eventForm[key].label]}
                value={dataObject.value || ""}
                onChange={handleInputChange}
                formAttr={key}
              />
            </Grid>
          )
        case "datetime":
          return (
            <Grid item xs={12} sm={6} key={key + index}>
              <DateTimePicker
                type="text"
                className="form-control"
                name={eventForm[key].label}
                label={translation[eventForm[key].label]}
                value={new Date(dataObject.value) || ""}
                onChange={(date) => handleDateChange(date, dataObject.name)}
                formAttr={key}
              />
            </Grid>
          )
        case "dropdown":
          return (
            <Grid item xs={12} sm={6} key={key + index}>
              <Dropdown
                type="text"
                className="form-control"
                name={eventForm[key].label}
                label={translation[eventForm[key].label]}
                options={eventForm[key].options}
                selectedOption={dataObject.value || ""}
                onSelect={handleInputChange}
                formAttr={key}
              />
            </Grid>
          )
        case "dropdown_sponsor":
          return (
            <Grid item xs={12} sm={6} key={key + index}>
              <MultiDropdown
                type="text"
                className="form-control"
                name={eventForm[key].label}
                label={translation[eventForm[key].label]}
                options={sponsors}
                selectedOptions={dataObject.value.reduce((acc, current) => {
                  acc.push(current.id.toString())
                  return acc
                }, [])}
                onSelect={handleSponsorChange}
                formAttr={key}
              />
            </Grid>
          )
        case "dropdown_city":
          return (
            <Grid item xs={12} sm={6} key={key + index}>
              <DropdownCity
                type="text"
                className="form-control"
                name={eventForm[key].label}
                label={translation[eventForm[key].label]}
                options={locations ? locations[0]?.cities : []}
                selectedOptions={dataObject.value}
                onSelect={handleInputChange}
                formAttr={key}
              />
            </Grid>
          )
        case "dropdown_fishingspots":
          return (
            <Grid item xs={12} sm={6} key={key + index}>
              <DropdownFishingSpots
                type="text"
                className="form-control"
                name={eventForm[key].label}
                label={translation[eventForm[key].label]}
                options={fishingspots}
                selectedOptions={dataObject.value}
                onSelect={handleInputChange}
                formAttr={key}
              />
            </Grid>
          )
        default:
          return null
      }
    })

  return (
    <Container maxWidth="md">
      <StyledPaper elevation={3}>
        <Typography variant="h4" gutterBottom>
          {translation.update_event}
        </Typography>
        <StyledForm onSubmit={submitForm}>
          <Typography variant="subtitle1" align="right" color="primary">
            {`Updating Event`}
          </Typography>
          <Grid container spacing={3}>
            {renderForm()}
          </Grid>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={!dirtyForm}
            sx={{ mt: 2, alignSelf: "flex-end" }}
          >
            {translation.update_general}
          </Button>
        </StyledForm>
      </StyledPaper>
    </Container>
  )
}

const mapStateToProps = (state) => ({
  translation: state.appState.translation,
  sponsors: state.utilsState.sponsors,
  fish_types: state.utilsState.fish_types,
  submitedEvent: state.eventsState.submitedEvent,
  createLoading: state.eventsState.createLoading,
  event: state.eventState.event,
  locations: state.utilsState.locations,
  fishingspots: state.utilsState.fishingspots,
})

const mapDispatchToProps = (dispatch) => {
  return {
    updateEvent: (formData) => dispatch(updateEvent(formData)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(GeneralEditForm))

