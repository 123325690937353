import React from "react";
import { Box } from "@mui/material";
import FilterBar from "./FilterBar";
import AssignmentsTable from "./AssignmentsTable";
import LoadingSpinner from "./LoadingSpinner";

const AssignmentsTab = ({
  loading,
  translation,
  availableGroups,
  availableLegs,
  selectedGroup,
  setSelectedGroup,
  selectedLeg,
  setSelectedLeg,
  searchQuery,
  setSearchQuery,
  refreshData,
  filteredParticipants,
  scoreboardData,
  handleScoreChange,
  handleSubmitScore,
  submitting
}) => {
  if (loading) {
    return <LoadingSpinner translation={translation} />;
  }

  return (
    <Box>
      <FilterBar
        translation={translation}
        availableGroups={availableGroups}
        availableLegs={availableLegs}
        selectedGroup={selectedGroup}
        setSelectedGroup={setSelectedGroup}
        selectedLeg={selectedLeg}
        setSelectedLeg={setSelectedLeg}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        refreshData={refreshData}
      />

      <AssignmentsTable
        translation={translation}
        selectedGroup={selectedGroup}
        selectedLeg={selectedLeg}
        setSelectedLeg={setSelectedLeg}
        filteredParticipants={filteredParticipants}
        scoreboardData={scoreboardData}
        handleScoreChange={handleScoreChange}
        handleSubmitScore={handleSubmitScore}
        submitting={submitting}
      />
    </Box>
  );
};

export default AssignmentsTab;
