import React, { useEffect, useRef, useMemo } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { IoReorderThree, IoRemoveOutline, IoNotificationsOutline } from 'react-icons/io5';
import Menu from './Menu';
import { menuToggle } from './../actions/appState';
import useClickOutside from '../hooks/useClickOutside';
import NotificationsManager from './NotificationsManager';
import { hiddenMenuRoutes } from '../constants/enums';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';

const Header = ({ menuOpen, menuToggle, user }) => {
  const openMenuRef = useRef(null);
  const menuRef = useRef(null);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const clickOutside = useClickOutside(menuRef, openMenuRef);

  const showHeader = useMemo(() => {
    if (pathname.indexOf('/password/reset') !== -1) {
      return false;
    }
    return !hiddenMenuRoutes?.includes(pathname);
  }, [pathname]);

  useEffect(() => {
    if (clickOutside && menuOpen) {
      menuToggle();
    }
  }, [clickOutside, menuToggle, menuOpen]);

  return (
    showHeader && (
      <AppBar position="sticky" sx={{ backgroundColor: 'var(--blue1)', height: '4rem', zIndex: 1300 }}>
  <Toolbar sx={{ minHeight: '4rem !important' }}>
    <IconButton
      edge="start"
      color="inherit"
      onClick={() => setTimeout(() => menuToggle(), 50)}
      ref={openMenuRef}
      sx={{ display: menuOpen ? 'none' : 'block' }}
    >
      <IoReorderThree size={24} />
    </IconButton>

    <IconButton
      edge="start"
      color="inherit"
      onClick={() => menuToggle()}
      sx={{ display: menuOpen ? 'block' : 'none' }}
    >
      <IoRemoveOutline size={24} />
    </IconButton>

    <Box
      component="img"
      src="https://streetfishing.fra1.cdn.digitaloceanspaces.com/media/assets/logo_orange_yellow_transparent.png"
      alt="logo"
      sx={{ height: '50px', px: 2 }}
    />

    <Box sx={{ flexGrow: 1 }} />

    {user && (
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        <Avatar
          src={user.profile_picture}
          alt={`${user.account.first_name}'s profile`}
          sx={{ width: 30, height: 30, cursor: 'pointer' }}
          onClick={() => navigate('/profile')}
        />
        <Typography
          variant="body1"
          sx={{ color: 'white', cursor: 'pointer' }}
          onClick={() => navigate('/profile')}
        >
          {user.account.first_name}
        </Typography>
      </Box>
    )}

    <IconButton
      color="inherit"
      onClick={() => navigate('/notifications')}
    >
      <IoNotificationsOutline size={24} />
    </IconButton>

    <NotificationsManager />
    <Menu open={menuOpen} menuRef={menuRef} />
  </Toolbar>
</AppBar>
    )
  );
};

const mapStateToProps = (state) => ({
  menuOpen: state.appState.menuOpen,
  user: state.auth.user,
});

const mapDispatchToProps = (dispatch) => {
  return {
    menuToggle: () => dispatch(menuToggle()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
