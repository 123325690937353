import { useState } from "react"
import PropTypes from "prop-types"
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
  Chip,
  IconButton,
  Collapse,
  useTheme,
} from "@mui/material"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp"
import { styled } from "@mui/material/styles"

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: "var(--galben1)",
  color: theme.palette.common.white,
  padding: "8px",
  fontSize: "0.9rem",
  [theme.breakpoints.down("sm")]: {
    padding: "6px",
    fontSize: "0.8rem",
  },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
}))

const OUTCOME_MAP = {
  V: { label: "Victory", color: "#4caf50" },
  T: { label: "Tie with fish", color: "#2196f3" },
  T0: { label: "Tie without fish", color: "#ff9800" },
  L: { label: "Loss with fish", color: "#dc004e" },
  L0: { label: "Loss without fish", color: "#FFD700" },
}

const OutcomeChip = ({ outcome }) => {
  const { label, color } = OUTCOME_MAP[outcome] || { label: "Unknown", color: "default" }
  return (
    <Chip
      label={label}
      style={{
        backgroundColor: color,
        color: "white",
        fontSize: "0.8rem",
        height: "28px",
      }}
    />
  )
}

const CompetitorRow = ({ competitor, matches }) => {
  const [open, setOpen] = useState(false)

  return (
    <>
      <StyledTableRow>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{competitor.rank}</TableCell>
        <TableCell>{competitor.user_name}</TableCell>
        <TableCell>{competitor.points}</TableCell>
        <TableCell>{competitor.captures}</TableCell>
        <TableCell>{competitor.victories}</TableCell>
        <TableCell>{competitor.ties_with_fish}</TableCell>
        <TableCell>{competitor.ties_without_fish}</TableCell>
        <TableCell>{competitor.losses_with_fish}</TableCell>
        <TableCell>{competitor.losses_without_fish}</TableCell>
      </StyledTableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                Match Details
              </Typography>
              <Table size="small" aria-label="matches">
                <TableHead>
                  <TableRow>
                    <TableCell>Leg</TableCell>
                    <TableCell>Opponent</TableCell>
                    <TableCell>Points</TableCell>
                    <TableCell>Outcome</TableCell>
                    <TableCell>Catches</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {matches.map((match) => {
                    const isCompetitorA = match.competitor_a_name === competitor.user_name
                    return (
                      <TableRow key={match.match_id}>
                        <TableCell>{match.leg_number}</TableCell>
                        <TableCell>{isCompetitorA ? match.competitor_b_name : match.competitor_a_name}</TableCell>
                        <TableCell>{isCompetitorA ? match.competitor_a_points : match.competitor_b_points}</TableCell>
                        <TableCell>
                          <OutcomeChip
                            outcome={isCompetitorA ? match.competitor_a_outcome : match.competitor_b_outcome}
                          />
                        </TableCell>
                        <TableCell>{isCompetitorA ? match.competitor_a_catches : match.competitor_b_catches}</TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

const TaScoringTable = ({ scoring, event }) => {
  const theme = useTheme()

  if (!scoring || !scoring.ranking) {
    return <Typography>No scoring data available.</Typography>
  }

  const getCompetitorMatches = (competitorName) => {
    return scoring.matches.filter(
      (match) => match.competitor_a_name === competitorName || match.competitor_b_name === competitorName,
    )
  }

  return (
    <Box sx={{ marginY: 2, marginX: 0, overflowX: "auto" }}>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <StyledTableCell />
              <StyledTableCell>Rank</StyledTableCell>
              <StyledTableCell>Name</StyledTableCell>
              <StyledTableCell>Points</StyledTableCell>
              <StyledTableCell>Captures</StyledTableCell>
              <StyledTableCell>Victories</StyledTableCell>
              <StyledTableCell>Ties with fish</StyledTableCell>
              <StyledTableCell>Ties without fish</StyledTableCell>
              <StyledTableCell>Losses with fish</StyledTableCell>
              <StyledTableCell>Losses without fish</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {scoring.ranking.map((competitor) => (
              <CompetitorRow
                key={competitor.rank}
                competitor={competitor}
                matches={getCompetitorMatches(competitor.user_name)}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

TaScoringTable.propTypes = {
  scoring: PropTypes.shape({
    ranking: PropTypes.arrayOf(
      PropTypes.shape({
        rank: PropTypes.number,
        user_name: PropTypes.string,
        points: PropTypes.number,
        captures: PropTypes.number,
        victories: PropTypes.number,
        ties_with_fish: PropTypes.number,
        ties_without_fish: PropTypes.number,
        losses_with_fish: PropTypes.number,
        losses_without_fish: PropTypes.number,
      }),
    ),
    matches: PropTypes.arrayOf(
      PropTypes.shape({
        match_id: PropTypes.number,
        leg_number: PropTypes.number,
        competitor_a_name: PropTypes.string,
        competitor_a_points: PropTypes.string,
        competitor_a_outcome: PropTypes.string,
        competitor_a_catches: PropTypes.number,
        competitor_b_name: PropTypes.string,
        competitor_b_points: PropTypes.string,
        competitor_b_outcome: PropTypes.string,
        competitor_b_catches: PropTypes.number,
      }),
    ),
  }),
  event: PropTypes.shape({
    event_name: PropTypes.string,
  }),
}

export default TaScoringTable

