import { connect, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { Container, Box, Typography, Paper } from "@mui/material"
import NonAuthContactForm from "../components/ContactFormNoAuth"

const ContactPageNoAuth = ({ translation }) => {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated)

  return (
    <Container maxWidth="sm">
      <Box sx={{ mt: 4, mb: 4 }}>
        <Paper elevation={3} sx={{ p: 4, borderRadius: 2 }}>
          <Box sx={{ display: "flex", justifyContent: "center", mb: 3 }}>
            <img
              src="https://streetfishing.fra1.cdn.digitaloceanspaces.com/media/assets/logo_orange_yellow_transparent.png"
              alt="logo"
              style={{ width: "100%", maxWidth: "300px" }}
            />
          </Box>
          <Typography
            variant="h4"
            component="h1"
            gutterBottom
            align="center"
            sx={{ fontWeight: "bold", color: "primary.main" }}
          >
            {translation.contact_us_title}
          </Typography>
          <Typography variant="subtitle1" gutterBottom align="center" sx={{ mb: 3 }}>
            {translation.contact_us_subtitle}
          </Typography>
          <NonAuthContactForm />
          {isAuthenticated && (
            <Box sx={{ mt: 3, textAlign: "center" }}>
              <Link to="/sponsors" style={{ textDecoration: "none", color: "primary.main" }}>
                {translation.sponsors_link_text}
              </Link>
            </Box>
          )}
        </Paper>
      </Box>
    </Container>
  )
}

const mapStateToProps = (state) => ({
  translation: state.appState.translation,
  isAuthenticated: state.auth.isAuthenticated,
})

export default connect(mapStateToProps)(ContactPageNoAuth)

