import { Card, CardContent, CardMedia, Typography, Box } from "@mui/material"
import { useNavigate } from "react-router-dom"

const ClubCard = ({ club }) => {
  const navigate = useNavigate()

  return (
    <Card
      sx={{
        display: "flex",
        mb: 2,
        cursor: "pointer",
        "&:hover": {
          boxShadow: 3,
        },
      }}
      onClick={() => navigate(`/club/${club.id}`)}
    >
      <CardMedia
        component="img"
        sx={{ width: 100, height: 100, objectFit: "contain" }}
        image={club.club_logo || "/placeholder.svg"}
        alt={club.club_name}
      />
      <Box sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
        <CardContent sx={{ flex: "1 0 auto" }}>
          <Typography component="div" variant="h6">
            {club.club_name}
          </Typography>
          <Typography variant="subtitle1" color="text.secondary" component="div">
            {club.club_acronym}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {club.club_city_name}, {club.club_country_name}
          </Typography>
        </CardContent>
      </Box>
    </Card>
  )
}

export default ClubCard

