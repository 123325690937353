import { useEffect, useState, useCallback } from "react"
import { connect } from "react-redux"
import { useNavigate } from "react-router-dom"
import axios from "axios"
import {
  Box,
  Container,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Button,
  CircularProgress,
  Card,
  CardContent,
} from "@mui/material"
import CalendarTodayIcon from "@mui/icons-material/CalendarToday"
import getAxiosConfig from "../utils/axiosConfig"
import Pagination from "../components/Pagination"

const OrganizerDashboard = ({ user, translation, eventOngoing }) => {
  const navigate = useNavigate()
  const [organizedEvents, setOrganizedEvents] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)

  const fetchEvents = useCallback(async () => {
    setIsLoading(true)
    setError(null)
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/v2/event/organized-events/`, {
        ...getAxiosConfig(),
        params: { page: currentPage },
      })
      setOrganizedEvents(response.data.results.events || [])
      setTotalPages(Math.ceil(response.data.count / 10))
    } catch (err) {
      console.error("Error fetching organized events:", err)
      setError(translation.dash_failed_to_show)
    } finally {
      setIsLoading(false)
    }
  }, [currentPage, translation.dash_failed_to_show])

  useEffect(() => {
    fetchEvents()
  }, [fetchEvents])

  const handleEventNavigation = (item) => {
    let path
    if (eventOngoing && eventOngoing.id === item.id) {
      path = item.scoring_type === "Type_2" ? `/taeventongoing/${item.id}` : `/eventongoing/${item.id}`
    } else {
      path = `/event/${item.id}`
    }
    navigate(path)
  }

  const getButtonText = (item) => {
    if (eventOngoing && eventOngoing.id === item.id) return translation.dash_go_to_upload
    return translation.dash_go_to_event
  }

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage)
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          flexGrow: 1,
          overflow: "auto",
          "&::-webkit-scrollbar": { display: "none" },
          scrollbarWidth: "none",
          msOverflowStyle: "none",
        }}
      >
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
          <Card sx={{ mb: 4 }}>
            <CardContent sx={{ backgroundColor: "var(--galben1)", color: "black", padding: 2 }}>
              <Typography variant="h6">{translation.dash_organized_events}</Typography>
            </CardContent>
            <CardContent>
              <TableContainer>
                <Table>
                  <TableBody>
                    {organizedEvents.map((item) => (
                      <TableRow key={item.id}>
                        <TableCell>{item.event_name}</TableCell>
                        <TableCell align="right">
                          <Button variant="contained" color="primary" onClick={() => handleEventNavigation(item)}>
                            {getButtonText(item)}
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                    {isLoading && (
                      <TableRow>
                        <TableCell colSpan={2} align="center">
                          <CircularProgress />
                        </TableCell>
                      </TableRow>
                    )}
                    {error && (
                      <TableRow>
                        <TableCell colSpan={2} align="center">
                          <Typography color="error">{error}</Typography>
                        </TableCell>
                      </TableRow>
                    )}
                    {!isLoading && !error && organizedEvents.length === 0 && (
                      <TableRow>
                        <TableCell colSpan={2} align="center">
                          <CalendarTodayIcon sx={{ fontSize: 40, color: "primary.main" }} />
                          <Typography>{translation.dash_no_event_found}</Typography>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
            </CardContent>
          </Card>
        </Container>
      </Box>
    </Box>
  )
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  translation: state.appState.translation,
  eventOngoing: state.utilsState.eventOngoing,
})

export default connect(mapStateToProps)(OrganizerDashboard)

