import {
  LOAD_EVENT,
  LOADED_EVENT,
  LOADED_EVENT_FAIL,
  LOAD_PUBLISH,
  LOADED_PUBLISH,
  LOADED_PUBLISH_FAIL,
  LOAD_ENROLLMENTS_FAIL,
  LOAD_ENROLLMENTS_SUCCESS,
  LOAD_ENROLLMENTS_LOADING,
  LOAD_TA_SCORING_FAIL,
  LOAD_TA_SCORING,
} from "../actions/types"

const initialState = {
  eventLoading: true,
  event: null,
  eventIsUpdating: true,
  publishLoading: false,
  publishError: false,
  publishDone: false,
  enrollmentsLoading: false,
  enrollmentsError: null,
  enrollments: [],
  taScoringLoading: false,
  taScoring: null,
  taScoringError: null,
}

function eventStateReducer(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case LOAD_EVENT:
      return {
        ...state,
        eventLoading: true,
      }
    case LOADED_EVENT:
      return {
        ...state,
        eventLoading: false,
        event: payload,
      }
    case LOADED_EVENT_FAIL:
      return {
        ...state,
        eventLoading: false,
        event: null,
      }
    case LOAD_PUBLISH:
      return {
        ...state,
        publishLoading: true,
      }
    case LOADED_PUBLISH:
      return {
        ...state,
        publishLoading: false,
        publishDone: true,
      }
    case LOADED_PUBLISH_FAIL:
      return {
        ...state,
        publishLoading: false,
        publishDone: false,
        publishError: true,
      }
    case LOAD_ENROLLMENTS_LOADING:
      return {
        ...state,
        enrollmentsLoading: true,
      }
    case LOAD_ENROLLMENTS_SUCCESS:
      return {
        ...state,
        enrollmentsLoading: false,
        enrollments: payload,
      }
    case LOAD_ENROLLMENTS_FAIL:
      return {
        ...state,
        enrollmentsLoading: false,
        enrollmentsError: payload,
      }
    case LOAD_TA_SCORING:
      return {
        ...state,
        taScoringLoading: false,
        taScoring: payload,
        taScoringError: null,
      }
    case LOAD_TA_SCORING_FAIL:
      return {
        ...state,
        taScoringLoading: false,
        taScoring: null,
        taScoringError: payload,
      }
    default:
      return state
  }
}

export default eventStateReducer

