import axios from "axios"
import getAxiosConfig from "../utils/axiosConfig"
import { toast } from "react-toastify"

export const ACCEPT_INVITATION_SUCCESS = "ACCEPT_INVITATION_SUCCESS"
export const ACCEPT_INVITATION_FAIL = "ACCEPT_INVITATION_FAIL"
export const SEND_INVITATION_SUCCESS = "SEND_INVITATION_SUCCESS"
export const SEND_INVITATION_FAIL = "SEND_INVITATION_FAIL"
export const UPDATE_ROLES_SUCCESS = "UPDATE_ROLES_SUCCESS"
export const UPDATE_ROLES_FAIL = "UPDATE_ROLES_FAIL"
export const FETCH_CLUBS_REQUEST = "FETCH_CLUBS_REQUEST"
export const FETCH_CLUBS_SUCCESS = "FETCH_CLUBS_SUCCESS"
export const FETCH_CLUBS_FAILURE = "FETCH_CLUBS_FAILURE"
export const FETCH_CLUB_DETAILS_REQUEST = "FETCH_CLUB_DETAILS_REQUEST"
export const FETCH_CLUB_DETAILS_SUCCESS = "FETCH_CLUB_DETAILS_SUCCESS"
export const FETCH_CLUB_DETAILS_FAILURE = "FETCH_CLUB_DETAILS_FAILURE"

export const acceptInvitation = (membershipId) => async (dispatch) => {
  try {
    await axios.post(
      `${process.env.REACT_APP_API_URL}/v2/club/club-membership/${membershipId}/accept/`,
      {},
      getAxiosConfig(),
    )

    toast.success("You have accepted club invitation. You can now go to Club page.")
    dispatch({ type: ACCEPT_INVITATION_SUCCESS })
  } catch (error) {
    toast.error(error?.response?.data?.message || "Couldn't accept invitation.")
    dispatch({ type: ACCEPT_INVITATION_FAIL, payload: error?.response?.data?.message || "Couldn't accept invitation." })
  }
}

export const sendClubInvitation = (clubId, email) => async (dispatch) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/v2/club/club-membership/${clubId}/invite/`,
      { email },
      getAxiosConfig(),
    )
    dispatch({
      type: SEND_INVITATION_SUCCESS,
      payload: response.data,
    })
    return response.data.message || "Invitation sent successfully!"
  } catch (error) {
    let errorMessage = "Error sending invitation"
    if (error.response && error.response.data) {
      errorMessage = error.response.data.message || errorMessage
    }
    dispatch({
      type: SEND_INVITATION_FAIL,
      payload: errorMessage,
    })
    throw errorMessage
  }
}

export const updateRoles = (updatedMembers) => async (dispatch) => {
  try {
    const response = await axios.patch(
      `${process.env.REACT_APP_API_URL}/v2/club/owner/members/bulk-edit/`,
      updatedMembers,
      getAxiosConfig(),
    )

    dispatch({
      type: UPDATE_ROLES_SUCCESS,
      payload: response.data,
    })
  } catch (error) {
    let errorMessage = "Error updating roles"
    if (error.response && error.response.data) {
      errorMessage = error.response.data.message || errorMessage
    }

    dispatch({
      type: UPDATE_ROLES_FAIL,
      payload: errorMessage,
    })
  }
}

export const fetchClubs = () => async (dispatch) => {
  dispatch({ type: FETCH_CLUBS_REQUEST })
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/v2/club/clubs/active/`, getAxiosConfig())
    dispatch({ type: FETCH_CLUBS_SUCCESS, payload: response.data.results })
  } catch (error) {
    dispatch({ type: FETCH_CLUBS_FAILURE, payload: error.message })
  }
}

export const fetchClubDetails = (clubId) => async (dispatch) => {
  dispatch({ type: FETCH_CLUB_DETAILS_REQUEST })
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/v2/club/clubs/${clubId}/`, getAxiosConfig())
    dispatch({
      type: FETCH_CLUB_DETAILS_SUCCESS,
      payload: response.data,
    })
  } catch (error) {
    const errorMessage = error.response?.data?.message || "Error fetching club details"
    dispatch({
      type: FETCH_CLUB_DETAILS_FAILURE,
      payload: errorMessage,
    })
    return Promise.reject(errorMessage)
  }
}
