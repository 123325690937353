import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { IoCaretDownOutline, IoFishSharp } from "react-icons/io5"
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Typography,
  Box,
  Button,
  Collapse,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
} from "@mui/material"
import { styled } from "@mui/material/styles"
import { Field, Form, Formik } from "formik"
import { contestatieSchmea } from "../utils/validationSchema"
import { AppLoader } from "./common/AppLoader"
import { AppTextField } from "./common/AppTextField"
import { toast } from "react-toastify"
import axios from "axios"
import getAxiosConfig from "../utils/axiosConfig"
import PhotoGallery from "./PhotoGallery"

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: "var(--galben1)",
  color: theme.palette.common.white,
  fontWeight: "bold",
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
}))

const ScoringTable = ({ scoring, event, translation, userId = false }) => {
  const [expand, setExpand] = useState(-1)
  const [biggestCatch, setBiggestCatch] = useState(null)
  const [fishNameCatch, setFishNameCatch] = useState(null)
  const [tableScoring, setTableScoring] = useState(scoring)
  const [contestatie, setContestatie] = useState(false)
  const [modalView, setModalView] = useState(null)
  const [loading, setLoading] = useState(false)
  const [galleryOpen, setGalleryOpen] = useState(false)
  const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0)
  const [allPhotos, setAllPhotos] = useState([])

  useEffect(() => {
    if (!scoring || !scoring.length) return
    const filterBiggestCatch = scoring.reduce(
      (prev, current) => (prev && prev.biggest_catch > current.biggest_catch ? prev : current),
      scoring[0],
    )
    setBiggestCatch(filterBiggestCatch)
    setFishNameCatch(filterBiggestCatch?.details.find((item) => filterBiggestCatch.biggest_catch === item.length))

    // Prepare all photos
    const photos = scoring.flatMap((item, parentIndex) =>
      item.details.map((detail, detailIndex) => ({
        ...detail,
        parentIndex,
        detailIndex,
        time: `${new Date(detail.upload_date).getHours().toString().padStart(2, "0")}:${new Date(detail.upload_date).getMinutes().toString().padStart(2, "0")}`,
        entity_initials: item.is_team_event ? item.entity_initials : null,
      })),
    )
    setAllPhotos(photos)
  }, [scoring])

  useEffect(() => {
    if (!userId) return
    let getPositionIndex = scoring.findIndex((item) => item.user === userId)
    if (getPositionIndex === 0) {
      getPositionIndex = 1
    } else if (getPositionIndex + 1 === scoring.length) {
      getPositionIndex -= 1
    }

    const tableScoringMap = scoring.filter(
      (item, index) =>
        index === getPositionIndex ||
        (index > getPositionIndex && index - 1 === getPositionIndex) ||
        (index < getPositionIndex && index + 1 === getPositionIndex),
    )
    setTableScoring(tableScoringMap)
  }, [userId, scoring])

  const submitContestatie = async (values) => {
    const config = getAxiosConfig()
    try {
      setLoading(true)
      await axios.post(
        `${process.env.REACT_APP_API_URL}/v2/event/${event.id}/photos/${modalView.photo_id}/report/`,
        { reason: values.reason },
        config,
      )
      toast.success("Contestatie trimisa cu success")
    } catch (err) {
      toast.error(err?.response?.data?.error || "Contestatia nu a putut fi trimisa")
    } finally {
      setContestatie(false)
      setLoading(false)
    }
  }

  const renderCatch = (item, key, parentIndex) => {
    return (
      <StyledTableRow
        key={`catchData${key}`}
        onClick={() => {
          const index = allPhotos.findIndex((photo) => photo.parentIndex === parentIndex && photo.detailIndex === key)
          setCurrentPhotoIndex(index)
          setGalleryOpen(true)
        }}
        sx={{ cursor: "pointer" }}
      >
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell>
          {item.fish_name}
          <Typography variant="caption" sx={{ position: "absolute", right: 0, top: 0 }}>
            {new Date(item.upload_date).getHours() > 9
              ? new Date(item.upload_date).getHours()
              : `0${new Date(item.upload_date).getHours()}`}
            :
            {new Date(item.upload_date).getMinutes() > 9
              ? new Date(item.upload_date).getMinutes()
              : `0${new Date(item.upload_date).getMinutes()}`}{" "}
            {item.entity_initials}
          </Typography>
        </TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell>{item.length} cm</TableCell>
        <TableCell>{item.points}</TableCell>
        <TableCell>
          <IoFishSharp color={item.is_valid_fish ? "green" : "red"} />
        </TableCell>
      </StyledTableRow>
    )
  }

  const renderRow = (item, key) => {
    return (
      <React.Fragment key={`rowData${key}`}>
        <StyledTableRow>
          <TableCell>
            <IconButton onClick={() => setExpand(key === expand ? -1 : key)}>
              <IoCaretDownOutline style={{ transform: key === expand ? "rotate(180deg)" : "none", color: "green" }} />
            </IconButton>
          </TableCell>
          <TableCell>{item.is_team_event ? item.team_rank : item.user_rank}</TableCell>
          <TableCell>
            {item.is_team_event ? item.team_name : item.user_name}
            {item.is_team_event && item.team_members && (
              <Typography variant="caption" display="block">
                {item.team_members.join(", ")}
              </Typography>
            )}
          </TableCell>
          <TableCell>{item.total_calculated_fishes}</TableCell>
          <TableCell>
            {item.total_points}{" "}
            {event?.has_bonus_points && <Typography variant="caption">({item.bonus_points})</Typography>}
          </TableCell>
          <TableCell>{item.total_species}</TableCell>
          <TableCell>{item.biggest_catch} cm</TableCell>
          <TableCell>{item.average_catch} cm</TableCell>
        </StyledTableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
            <Collapse in={expand === key} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell>{translation.cls_fish}</TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell>{translation.cls_length}</TableCell>
                      <TableCell>{translation.cls_points}</TableCell>
                      <TableCell>{translation.cls_valid}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>{item.details.map((detail, index) => renderCatch(detail, index, key))}</TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    )
  }

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell></StyledTableCell>
              <StyledTableCell>{translation.cls_rank}</StyledTableCell>
              <StyledTableCell>{translation.cls_name}</StyledTableCell>
              <StyledTableCell>{translation.cls_total_caught}</StyledTableCell>
              <StyledTableCell>{translation.cls_points}</StyledTableCell>
              <StyledTableCell>{translation.cls_fish}</StyledTableCell>
              <StyledTableCell>{translation.cls_cmmc}</StyledTableCell>
              <StyledTableCell>{translation.cls_average}</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {biggestCatch && fishNameCatch && (
              <StyledTableRow>
                <TableCell></TableCell>
                <TableCell>{translation.cls_cmmc}</TableCell>
                <TableCell>{biggestCatch.is_team_event ? biggestCatch.team_name : biggestCatch.user_name}</TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell>{fishNameCatch.fish_name}</TableCell>
                <TableCell>{biggestCatch.biggest_catch} cm</TableCell>
                <TableCell></TableCell>
              </StyledTableRow>
            )}
            {scoring?.map(renderRow)}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={!!contestatie} onClose={() => setContestatie(false)}>
        <DialogTitle>Contestatie</DialogTitle>
        <DialogContent>
          <Box sx={{ mt: 2 }}>
            <Formik initialValues={{ reason: "" }} onSubmit={submitContestatie} validationSchema={contestatieSchmea}>
              <Form>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Field
                      name="reason"
                      as={AppTextField}
                      rows={4}
                      label="Motivul contestatiei"
                      size="small"
                      multiline
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button variant="contained" color="success" type="submit" fullWidth>
                      Trimite contestatia
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            </Formik>
          </Box>
        </DialogContent>
      </Dialog>

      <PhotoGallery
        open={galleryOpen}
        onClose={() => setGalleryOpen(false)}
        photos={allPhotos}
        initialIndex={currentPhotoIndex}
        translation={translation}
        onContestatie={(photo) => {
          setModalView(photo)
          setContestatie(true)
        }}
        event={event}
      />

      <AppLoader show={loading} />
    </>
  )
}

const mapStateToProps = (state) => ({
  translation: state.appState.translation,
})

export default connect(mapStateToProps, null)(ScoringTable)

