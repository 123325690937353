export const formatDate = (date) => {
  const dateObject = new Date(date)
  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  }

  const roLocale = "ro-RO"

  let formattedDate = dateObject.toLocaleString(roLocale, options)

  formattedDate = formattedDate.replace(",", "").replace("/", ".").replace("/", ".")

  return formattedDate
}


export const formatDateOnly = (date) => {
  const dateObject = new Date(date)
  const day = String(dateObject.getDate()).padStart(2, "0")
  const month = String(dateObject.getMonth() + 1).padStart(2, "0") // Months are 0-indexed
  const year = dateObject.getFullYear()

  return `${day}/${month}/${year}`
}
