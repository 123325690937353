import React from "react";
import { Box } from "@mui/material";

const TabPanel = ({ children, value, index, id }) => {
  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${id}`}
      aria-labelledby={`tab-${id}`}
    >
      {value === index && children}
    </Box>
  );
};

export default TabPanel;
