"use client"

import { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Box,
  Checkbox,
  FormControlLabel,
} from "@mui/material"
import { CheckCircle } from "@mui/icons-material"
import { toast } from "react-toastify"
import { fetchUserGameCard, updateMatchOrganizer } from "../actions/taActions"

const MatchValidateTable = ({ userId, eventId, refreshList }) => {
  const dispatch = useDispatch()
  const { userGameCard, loading } = useSelector((state) => state.taState)
  const [openDialog, setOpenDialog] = useState(false)
  const [selectedMatch, setSelectedMatch] = useState(null)
  const [formData, setFormData] = useState({
    competitor_a_catches: "",
    competitor_b_catches: "",
    is_valid_a: false,
    is_valid_b: false,
  })

  useEffect(() => {
    if (userId && eventId) {
      dispatch(fetchUserGameCard(eventId, userId))
    }
  }, [dispatch, eventId, userId])

  const handleOpenDialog = (match) => {
    setSelectedMatch(match)
    setFormData({
      competitor_a_catches: match.competitor_a_catches?.toString() ?? "",
      competitor_b_catches: match.competitor_b_catches?.toString() ?? "",
      is_valid_a: match.is_valid_a ?? false,
      is_valid_b: match.is_valid_b ?? false,
    })
    setOpenDialog(true)
  }

  const handleCloseDialog = () => {
    setOpenDialog(false)
    setSelectedMatch(null)
  }

  const handleInputChange = (e) => {
    const { name, value, checked } = e.target
    setFormData((prevData) => ({
      ...prevData,
      [name]: name.startsWith("is_valid") ? checked : value,
    }))
  }

  const handleUpdateMatch = () => {
    if (selectedMatch) {
      const update = {
        competitor_a_catches: Number.parseInt(formData.competitor_a_catches) || 0,
        competitor_b_catches: Number.parseInt(formData.competitor_b_catches) || 0,
        is_valid_a: formData.is_valid_a,
        is_valid_b: formData.is_valid_b,
      }
      dispatch(updateMatchOrganizer(eventId, selectedMatch.match_id, update)).then(() => {
        handleCloseDialog()
        toast.success("Match updated successfully")
        refreshList()
        dispatch(fetchUserGameCard(eventId, userId))
      })
    }
  }

  if (loading) {
    return <div>Loading...</div>
  }

  if (!userGameCard || !userGameCard.matches) {
    return <div>No data available for this user</div>
  }

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Leg</TableCell>
              <TableCell>Competitor</TableCell>
              <TableCell>Catches</TableCell>
              <TableCell>Points</TableCell>
              <TableCell>Valid</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {userGameCard.matches.map((match) => (
              <>
                <TableRow
                  key={`${match.match_id}-a`}
                  sx={{
                    "& > *": { borderBottom: "unset" },
                    backgroundColor: (theme) => theme.palette.action.hover,
                  }}
                >
                  <TableCell rowSpan={2} sx={{ fontWeight: "bold", fontSize: "1.1rem" }}>
                    {match.leg_number}
                  </TableCell>
                  <TableCell>{match.competitor_a_name}</TableCell>
                  <TableCell>{match.competitor_a_catches}</TableCell>
                  <TableCell>{match.competitor_a_points}</TableCell>
                  <TableCell>
                    <CheckCircle
                      sx={{
                        color: match.is_valid_a ? "var(--green1)" : "var(--maro1)",
                        fontSize: "1rem",
                        verticalAlign: "middle",
                      }}
                    />
                  </TableCell>
                  <TableCell rowSpan={2}>
                    <Button variant="contained" color="primary" onClick={() => handleOpenDialog(match)}>
                      Update
                    </Button>
                  </TableCell>
                </TableRow>
                <TableRow key={`${match.match_id}-b`}>
                  <TableCell>{match.competitor_b_name}</TableCell>
                  <TableCell>{match.competitor_b_catches}</TableCell>
                  <TableCell>{match.competitor_b_points}</TableCell>
                  <TableCell>
                    <CheckCircle
                      sx={{
                        color: match.is_valid_b ? "var(--green1)" : "var(--maro1)",
                        fontSize: "1rem",
                        verticalAlign: "middle",
                      }}
                    />
                  </TableCell>
                </TableRow>
              </>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="md" fullWidth>
        <DialogTitle>Update Match</DialogTitle>
        <DialogContent>
          {selectedMatch && (
            <Box display="flex" justifyContent="space-between">
              <Box flex={1} mr={2}>
                <Typography variant="h6">{selectedMatch.competitor_a_name}</Typography>
                <TextField
                  label="Catches"
                  type="number"
                  name="competitor_a_catches"
                  value={formData.competitor_a_catches}
                  onChange={handleInputChange}
                  fullWidth
                  margin="normal"
                />
                <FormControlLabel
                  control={<Checkbox checked={formData.is_valid_a} onChange={handleInputChange} name="is_valid_a" />}
                  label="Valid"
                />
              </Box>
              <Box flex={1} ml={2}>
                <Typography variant="h6">{selectedMatch.competitor_b_name}</Typography>
                <TextField
                  label="Catches"
                  type="number"
                  name="competitor_b_catches"
                  value={formData.competitor_b_catches}
                  onChange={handleInputChange}
                  fullWidth
                  margin="normal"
                />
                <FormControlLabel
                  control={<Checkbox checked={formData.is_valid_b} onChange={handleInputChange} name="is_valid_b" />}
                  label="Valid"
                />
              </Box>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleUpdateMatch} color="primary">
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default MatchValidateTable

