const DropdownScoringTypes = ({ name, label, options, selectedOption, onSelect, disabled }) => {
  return (
    <div className="form-group">
      <label htmlFor={name}>{label}</label>
      <select
        className="form-control"
        id={name}
        name={name}
        value={selectedOption}
        onChange={onSelect}
        disabled={disabled}
      >
        <option value="">Select a scoring type</option>
        {options.map((type) => (
          <option key={type.scoring_type} value={type.scoring_type}>
            {type.label}
          </option>
        ))}
      </select>
    </div>
  )
}

export default DropdownScoringTypes

