import axios from "axios"

// Action Types
export const GENERATE_TSF_LINEUP_REQUEST = "GENERATE_TSF_LINEUP_REQUEST"
export const GENERATE_TSF_LINEUP_SUCCESS = "GENERATE_TSF_LINEUP_SUCCESS"
export const GENERATE_TSF_LINEUP_FAILURE = "GENERATE_TSF_LINEUP_FAILURE"

export const GENERATE_TSF_GENERAL_LINEUP_REQUEST = "GENERATE_TSF_GENERAL_LINEUP_REQUEST"
export const GENERATE_TSF_GENERAL_LINEUP_SUCCESS = "GENERATE_TSF_GENERAL_LINEUP_SUCCESS"
export const GENERATE_TSF_GENERAL_LINEUP_FAILURE = "GENERATE_TSF_GENERAL_LINEUP_FAILURE"

export const CHECK_TSF_READINESS_REQUEST = "CHECK_TSF_READINESS_REQUEST"
export const CHECK_TSF_READINESS_SUCCESS = "CHECK_TSF_READINESS_SUCCESS"
export const CHECK_TSF_READINESS_FAILURE = "CHECK_TSF_READINESS_FAILURE"

export const EXPORT_TSF_LINEUP_REQUEST = "EXPORT_TSF_LINEUP_REQUEST"
export const EXPORT_TSF_LINEUP_SUCCESS = "EXPORT_TSF_LINEUP_SUCCESS"
export const EXPORT_TSF_LINEUP_FAILURE = "EXPORT_TSF_LINEUP_FAILURE"

export const EXPORT_TSF_RANKING_REQUEST = "EXPORT_TSF_RANKING_REQUEST"
export const EXPORT_TSF_RANKING_SUCCESS = "EXPORT_TSF_RANKING_SUCCESS"
export const EXPORT_TSF_RANKING_FAILURE = "EXPORT_TSF_RANKING_FAILURE"

export const DOWNLOAD_TSF_SUMMARY_REQUEST = "DOWNLOAD_TSF_SUMMARY_REQUEST"
export const DOWNLOAD_TSF_SUMMARY_SUCCESS = "DOWNLOAD_TSF_SUMMARY_SUCCESS"
export const DOWNLOAD_TSF_SUMMARY_FAILURE = "DOWNLOAD_TSF_SUMMARY_FAILURE"

export const FETCH_TSF_ASSIGNMENTS_REQUEST = "FETCH_TSF_ASSIGNMENTS_REQUEST"
export const FETCH_TSF_ASSIGNMENTS_SUCCESS = "FETCH_TSF_ASSIGNMENTS_SUCCESS"
export const FETCH_TSF_ASSIGNMENTS_FAILURE = "FETCH_TSF_ASSIGNMENTS_FAILURE"

export const FETCH_TSF_RANKING_REQUEST = "FETCH_TSF_RANKING_REQUEST"
export const FETCH_TSF_RANKING_SUCCESS = "FETCH_TSF_RANKING_SUCCESS"
export const FETCH_TSF_RANKING_FAILURE = "FETCH_TSF_RANKING_FAILURE"

export const UPDATE_TSF_SCOREBOARD_REQUEST = "UPDATE_TSF_SCOREBOARD_REQUEST"
export const UPDATE_TSF_SCOREBOARD_SUCCESS = "UPDATE_TSF_SCOREBOARD_SUCCESS"
export const UPDATE_TSF_SCOREBOARD_FAILURE = "UPDATE_TSF_SCOREBOARD_FAILURE"

const downloadFile = (data, fileName) => {
  const url = window.URL.createObjectURL(new Blob([data]))
  const link = document.createElement("a")
  link.href = url
  link.setAttribute("download", fileName)
  document.body.appendChild(link)
  link.click()
  link.remove()
}

const formatDate = (date) => {
  return new Date(date)
    .toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
    })
    .replace(/\//g, "-")
}

// Action Creators
export const generateTSFGeneralLineup = (eventId) => async (dispatch) => {
  dispatch({ type: GENERATE_TSF_GENERAL_LINEUP_REQUEST })

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/v2/event/${eventId}/generate-tsf-complete-lineup/`,
      { event: eventId },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${localStorage.getItem("access")}`,
        },
      },
    )

    dispatch({
      type: GENERATE_TSF_GENERAL_LINEUP_SUCCESS,
      payload: response.data,
    })

    return { success: true, message: response.data.detail || "TSF General Lineup generated successfully" }
  } catch (error) {
    const errorMessage = error.response?.data?.detail || error.message
    dispatch({
      type: GENERATE_TSF_GENERAL_LINEUP_FAILURE,
      payload: errorMessage,
    })
    return { success: false, error: errorMessage }
  }
}

export const checkTSFReadiness = (eventId) => async (dispatch) => {
  dispatch({ type: CHECK_TSF_READINESS_REQUEST })

  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/v2/event/${eventId}/check-tsf-readiness/`, {
      headers: {
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    })

    dispatch({
      type: CHECK_TSF_READINESS_SUCCESS,
      payload: response.data,
    })

    return { success: true, data: response.data }
  } catch (error) {
    const errorMessage = error.response?.data?.detail || error.message
    dispatch({
      type: CHECK_TSF_READINESS_FAILURE,
      payload: errorMessage,
    })
    return { success: false, error: errorMessage }
  }
}

export const exportTSFLineup = (eventId, eventName, startDate) => async (dispatch) => {
  dispatch({ type: EXPORT_TSF_LINEUP_REQUEST })

  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/v2/event/${eventId}/export-tsf-lineup/`, {
      headers: {
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
      responseType: "blob",
    })

    const formattedDate = formatDate(startDate)
    const fileName = `${eventName}_TSF_Lineup_${formattedDate}.xlsx`

    downloadFile(response.data, fileName)

    dispatch({
      type: EXPORT_TSF_LINEUP_SUCCESS,
    })

    return { success: true, message: "TSF Lineup exported successfully" }
  } catch (error) {
    const errorMessage = error.response?.data?.detail || error.message
    dispatch({
      type: EXPORT_TSF_LINEUP_FAILURE,
      payload: errorMessage,
    })
    return { success: false, error: errorMessage }
  }
}

export const exportTSFRanking = (eventId, eventName, startDate) => async (dispatch) => {
  dispatch({ type: EXPORT_TSF_RANKING_REQUEST })

  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/v3/event/${eventId}/export-tsf-ranking/`, {
      headers: {
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
      responseType: "blob",
    })

    const formattedDate = formatDate(startDate)
    const fileName = `${eventName}_TSF_Ranking_${formattedDate}.xlsx`

    downloadFile(response.data, fileName)

    dispatch({
      type: EXPORT_TSF_RANKING_SUCCESS,
    })

    return { success: true, message: "TSF Ranking exported successfully" }
  } catch (error) {
    const errorMessage = error.response?.data?.detail || error.message
    dispatch({
      type: EXPORT_TSF_RANKING_FAILURE,
      payload: errorMessage,
    })
    return { success: false, error: errorMessage }
  }
}

export const downloadTSFSummary = (eventId, eventName, startDate) => async (dispatch) => {
  dispatch({ type: DOWNLOAD_TSF_SUMMARY_REQUEST })

  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/v3/event/${eventId}/tsf-summary/`, {
      headers: {
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
      responseType: "blob",
    })

    const formattedDate = formatDate(startDate)
    const fileName = `${eventName}_TSF_Summary_${formattedDate}.xlsx`

    downloadFile(response.data, fileName)

    dispatch({
      type: DOWNLOAD_TSF_SUMMARY_SUCCESS,
    })

    return { success: true, message: "TSF Summary downloaded successfully" }
  } catch (error) {
    const errorMessage = error.response?.data?.detail || error.message
    dispatch({
      type: DOWNLOAD_TSF_SUMMARY_FAILURE,
      payload: errorMessage,
    })
    return { success: false, error: errorMessage }
  }
}

export const fetchTSFAssignments = (eventId) => async (dispatch) => {
  dispatch({ type: FETCH_TSF_ASSIGNMENTS_REQUEST })

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/v2/event/${eventId}/user-group-tsf-assignment/`,
      {
        headers: {
          Authorization: `JWT ${localStorage.getItem("access")}`,
        },
      },
    )

    dispatch({
      type: FETCH_TSF_ASSIGNMENTS_SUCCESS,
      payload: response.data,
    })

    return { success: true, data: response.data }
  } catch (error) {
    dispatch({
      type: FETCH_TSF_ASSIGNMENTS_FAILURE,
      payload: error.message,
    })
    return { success: false, error: error.message }
  }
}

export const fetchTSFRanking = (eventId) => async (dispatch) => {
  dispatch({ type: FETCH_TSF_RANKING_REQUEST })

  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/v1/event/${eventId}/tsf-ranking/`, {
      headers: {
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    })

    dispatch({
      type: FETCH_TSF_RANKING_SUCCESS,
      payload: response.data,
    })

    return { success: true, data: response.data }
  } catch (error) {
    dispatch({
      type: FETCH_TSF_RANKING_FAILURE,
      payload: error.message,
    })
    return { success: false, error: error.message }
  }
}


export const updateTSFScoreboard = (eventId, scoreboardId, fishCaught) => async (dispatch) => {
  dispatch({ type: UPDATE_TSF_SCOREBOARD_REQUEST })

  try {
    const response = await axios.patch(
      `${process.env.REACT_APP_API_URL}/v2/event/${eventId}/update-tsf-scoreboard/`,
      {
        scoreboard_id: scoreboardId,
        fish_caught: fishCaught,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${localStorage.getItem("access")}`,
        },
      },
    )

    dispatch({
      type: UPDATE_TSF_SCOREBOARD_SUCCESS,
      payload: response.data,
    })

    return { success: true, message: response.data.detail || "TSF Scoreboard updated successfully" }
  } catch (error) {
    const errorMessage = error.response?.data?.detail || error.message
    dispatch({
      type: UPDATE_TSF_SCOREBOARD_FAILURE,
      payload: errorMessage,
    })
    return { success: false, error: errorMessage }
  }
}

export const fetchAllGroupAssignments = (eventId) => async (dispatch) => {
  dispatch({ type: "FETCH_ALL_GROUP_ASSIGNMENTS_REQUEST" })

  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/v2/event/${eventId}/all-group-assignments/`, {
      headers: {
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    })

    dispatch({
      type: "FETCH_ALL_GROUP_ASSIGNMENTS_SUCCESS",
      payload: response.data,
    })

    return { success: true, data: response.data }
  } catch (error) {
    dispatch({
      type: "FETCH_ALL_GROUP_ASSIGNMENTS_FAILURE",
      payload: error.message,
    })
    return { success: false, error: error.message }
  }
}


export const fetchTSFGroupRanking = (eventId) => async (dispatch) => {
  dispatch({ type: "FETCH_TSF_GROUP_RANKING_REQUEST" })

  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/v1/event/${eventId}/tsf-group-ranking/`, {
      headers: {
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    })

    dispatch({
      type: "FETCH_TSF_GROUP_RANKING_SUCCESS",
      payload: response.data,
    })

    return { success: true, data: response.data }
  } catch (error) {
    dispatch({
      type: "FETCH_TSF_GROUP_RANKING_FAILURE",
      payload: error.message,
    })
    return { success: false, error: error.message }
  }
}

export const fetchTSFAllRankings = (eventId) => async (dispatch) => {
  dispatch({ type: "FETCH_TSF_ALL_RANKINGS_REQUEST" })

  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/v1/event/${eventId}/tsf-all-rankings/`, {
      headers: {
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    })

    dispatch({
      type: "FETCH_TSF_ALL_RANKINGS_SUCCESS",
      payload: response.data,
    })

    return { success: true, data: response.data }
  } catch (error) {
    dispatch({
      type: "FETCH_TSF_ALL_RANKINGS_FAILURE",
      payload: error.message,
    })
    return { success: false, error: error.message }
  }
}

export const fetchTSFFinalRankings = (eventId) => async (dispatch) => {
  dispatch({ type: "FETCH_TSF_FINAL_RANKINGS_REQUEST" })

  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/v1/event/${eventId}/tsf-final-rankings/`, {
      headers: {
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    })

    dispatch({
      type: "FETCH_TSF_FINAL_RANKINGS_SUCCESS",
      payload: response.data,
    })

    return { success: true, data: response.data }
  } catch (error) {
    dispatch({
      type: "FETCH_TSF_FINAL_RANKINGS_FAILURE",
      payload: error.message,
    })
    return { success: false, error: error.message }
  }
}

export const fetchTSFStatistics = (eventId) => async (dispatch) => {
  dispatch({ type: "FETCH_TSF_STATISTICS_REQUEST" });

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/v1/event/${eventId}/tsf-statistics/`,
      {
        headers: {
          Authorization: `JWT ${localStorage.getItem("access")}`,
        },
      }
    );

    dispatch({
      type: "FETCH_TSF_STATISTICS_SUCCESS",
      payload: response.data,
    });

    return { success: true, data: response.data };
  } catch (error) {
    dispatch({
      type: "FETCH_TSF_STATISTICS_FAILURE",
      payload: error.message,
    });
    return { success: false, error: error.message };
  }
};
