import { useState, useEffect } from "react"
import { Dialog, IconButton, Box, Typography, Button } from "@mui/material"
import { styled } from "@mui/material/styles"
import CloseIcon from "@mui/icons-material/Close"
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew"
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"
import EditCalendarOutlined from "@mui/icons-material/EditCalendarOutlined"

const FullScreenDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    margin: 0,
    width: "100%",
    height: "100%",
    maxHeight: "100%",
    maxWidth: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.9)",
  },
}))

const NavigationButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  transform: "translateY(-50%)",
  color: "white",
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  "&:hover": {
    backgroundColor: "rgba(0, 0, 0, 0.7)",
  },
}))

const CloseButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  top: theme.spacing(2),
  right: theme.spacing(2),
  color: "white",
}))

const PhotoGallery = ({ open, onClose, photos, initialIndex = 0, translation, onContestatie, event }) => {
  const [currentIndex, setCurrentIndex] = useState(initialIndex)

  useEffect(() => {
    setCurrentIndex(initialIndex)
  }, [initialIndex])

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : photos.length - 1))
  }

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex < photos.length - 1 ? prevIndex + 1 : 0))
  }

  const currentPhoto = photos[currentIndex]

  return (
    <FullScreenDialog open={open} onClose={onClose}>
      <Box
        sx={{
          height: "100%",
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          src={currentPhoto?.photo_url || "/placeholder.svg"}
          alt={`Photo ${currentIndex + 1}`}
          style={{
            maxWidth: "90%",
            maxHeight: "70vh",
            objectFit: "contain",
          }}
        />
        <Box sx={{ mt: 2, textAlign: "center", color: "white" }}>
          <Typography variant="subtitle1">
            <b>{translation.fish}</b>: {currentPhoto?.fish_name}
          </Typography>
          <Typography variant="subtitle1">
            <b>{translation.cls_length}</b>: {currentPhoto?.length} cm
          </Typography>
          <Typography variant="subtitle1">
            <b>{translation.cls_points}</b>: {currentPhoto?.points}
          </Typography>
          <Typography variant="subtitle1">
            <b>{translation.cls_time}</b>: {currentPhoto?.time}
          </Typography>
          {currentPhoto?.entity_initials && (
            <Typography variant="subtitle1">
              <b>{translation.cls_entity}</b>: {currentPhoto?.entity_initials}
            </Typography>
          )}
          <Button
            variant="outlined"
            color="galben"
            sx={{ mt: 2 }}
            onClick={() => onContestatie(currentPhoto)}
            disabled={event?.enrollment_status !== "approved"}
            startIcon={<EditCalendarOutlined />}
          >
            {translation.contestatie}
          </Button>
        </Box>
        <NavigationButton onClick={handlePrev} sx={{ left: (theme) => theme.spacing(2) }}>
          <ArrowBackIosNewIcon />
        </NavigationButton>
        <NavigationButton onClick={handleNext} sx={{ right: (theme) => theme.spacing(2) }}>
          <ArrowForwardIosIcon />
        </NavigationButton>
        <CloseButton onClick={onClose}>
          <CloseIcon />
        </CloseButton>
      </Box>
    </FullScreenDialog>
  )
}

export default PhotoGallery

